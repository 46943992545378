import _objectSpread from "/root/.jenkins/workspace/prod-dapp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'loading',
  props: {
    registerStatus: Boolean,
    lockingStatus: Boolean,
    type: String,
    isTld: Boolean
  },
  data: function data() {
    return {};
  },
  watch: {},
  computed: _objectSpread({
    isMobile: function isMobile() {
      return this.$store.state.isMobile;
    }
  }, mapState(['chainid', 'account', 'accessToken'])),
  mounted: function mounted() {},
  methods: {}
};