import "core-js/modules/es.string.link.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/image/userinfo/close.png';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5c3e40a6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "notice"
};
var _hoisted_2 = {
  "class": "notice-title"
};
var _hoisted_3 = ["src", "onClick"];
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_carousel_item = _resolveComponent("el-carousel-item");
  var _component_el_carousel = _resolveComponent("el-carousel");
  var _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createBlock(_component_van_popup, {
    teleport: "body",
    show: _ctx.isShowNew,
    "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.isShowNew = $event;
    }),
    round: "",
    onClickOverlay: _ctx.handleClose
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
        "class": "close-icon",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = function () {
          return _ctx.handleClose && _ctx.handleClose.apply(_ctx, arguments);
        })
      }), _createVNode(_component_el_carousel, {
        "class": "my-carousel",
        interval: "5000",
        "indicator-position": _ctx.noticeList.length > 1 ? '—' : 'none',
        arrow: _ctx.$store.state.isMobile && _ctx.noticeList.length > 1 ? 'always' : 'never'
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.noticeList, function (item, index) {
            return _openBlock(), _createBlock(_component_el_carousel_item, {
              key: index
            }, {
              "default": _withCtx(function () {
                return [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$store.state.language == 'ch' ? item.titleCh : item.titleEn), 1), _createElementVNode("img", {
                  "class": "notice-img",
                  src: item.coverImages,
                  style: _normalizeStyle(item.link ? 'cursor: pointer' : ''),
                  onClick: function onClick($event) {
                    return _ctx.$toUrl(item.link);
                  }
                }, null, 12, _hoisted_3), _ctx.$store.state.language == 'ch' ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  "class": "notice-desc",
                  innerHTML: item.contentCh
                }, null, 8, _hoisted_4)) : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  "class": "notice-desc",
                  innerHTML: item.contentEn
                }, null, 8, _hoisted_5))];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      }, 8, ["indicator-position", "arrow"])])];
    }),
    _: 1
  }, 8, ["show", "onClickOverlay"]);
}