import {
    localStorage
} from "@/store/storage";

export function defaultAvatar() {
    let a = localStorage.getNumber('headerKey')
    if (!a) {
        a = Math.floor(Math.random() * (5 - 1)) + 1;
        localStorage.setNumber('headerKey', a)
    }
    return require('@/assets/image/commom/icon_avatar' + a + '.png')
}