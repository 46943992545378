// import Vue from 'vue';
import {
    createApp
} from 'vue'
import router from './router/index'
import store from './store/index'
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import './flexible.js';
import i18n from './language/i18n'
// import '@/utils/rem.js';
import 'vant/lib/index.css'

import {
    toUrl,
    getAbbreviateAddress,
    getAbbreviateName,
    getAbbreviateLongName,
    showTip,
    setPupopStatus,
    setBodyStyle,
    localFormat,
    isUnSupport,
    isSupportChainName,
    getTldName,
    getTldDot,
    tldToLower,
    getSubTldName,
    tokenValue,
    testAddress,
    isSupportChainIcon
} from './utils/utils'
import {
    showContractErrorMessage
} from './utils/contractMessageDeal'
import {
    getTransferStatus
} from './utils/transferManager'
import {
    initWallet,
    getSUID
} from './utils/connect'
import {
    expirationTime
} from './utils/utils'
// import VueGtag from 'vue-gtag'
import {
    Popup,
    CountDown,
    Toast,
    Popover,
    DropdownMenu,
    DropdownItem,
    Overlay,
    Swipe,
    SwipeItem,
    Sticky,
    Uploader,
    TextEllipsis
} from 'vant';
import VueCropper from 'vue-cropper'
import 'vue-cropper/dist/index.css'
import 'vant/lib/index.css';

import {
    ElNotification
} from 'element-plus'
import 'default-passive-events'
// import {Chains, createWeb3Auth} from '@kolirt/vue-web3-auth'
// import VConsole from 'vconsole'
// const vConsole = new VConsole()

if (process.env.NODE_ENV === 'production') {
    if (window) {
        window.console.log = function () {};
    }
}

const app = createApp(App)
app.config.globalProperties.$initWallet = initWallet
app.config.globalProperties.$getSUID = getSUID
app.config.globalProperties.$expirationTime = expirationTime
app.config.globalProperties.$ElNotification = ElNotification
app.config.globalProperties.$toUrl = toUrl
app.config.globalProperties.$showTip = showTip
app.config.globalProperties.$setPupopStatus = setPupopStatus
app.config.globalProperties.$showContractErrorMessage = showContractErrorMessage
app.config.globalProperties.$getAbbreviateAddress = getAbbreviateAddress
app.config.globalProperties.$getAbbreviateName = getAbbreviateName
app.config.globalProperties.$getAbbreviateLongName = getAbbreviateLongName
app.config.globalProperties.$getTransferStatus = getTransferStatus
app.config.globalProperties.$setBodyStyle = setBodyStyle
app.config.globalProperties.$localFormat = localFormat
app.config.globalProperties.$isUnSupport = isUnSupport
app.config.globalProperties.$isSupportChainName = isSupportChainName
app.config.globalProperties.$getTldName = getTldName
app.config.globalProperties.$getTldDot = getTldDot
app.config.globalProperties.$tldToLower = tldToLower
app.config.globalProperties.$getSubTldName = getSubTldName
app.config.globalProperties.$tokenValue = tokenValue
app.config.globalProperties.$testAddress = testAddress
app.config.globalProperties.$isSupportChainIcon = isSupportChainIcon

// //console.log(Chains,'Chains')
// const chains = process.env.VUE_APP_RUNTIME_ENV === 'production'
// ?
// [Chains.arbitrum,Chains.bsc,Chains.mainnet,Chains.polygon,Chains.optimism,Chains.zkSync]
// :
// [Chains.arbitrumGoerli,Chains.bscTestnet,Chains.goerli,Chains.polygonMumbai,Chains.optimismGoerli,Chains.zkSyncTestnet]
app.use(store)
    .use(router)
    .use(VueCropper)
    .use(CountDown)
    .use(Popup)
    .use(Swipe)
    .use(SwipeItem)
    .use(Sticky)
    .use(Toast)
    .use(Popover)
    .use(Uploader)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(TextEllipsis)
    .use(Overlay)
    .use(i18n)
    // .use(VueGtag, {
    //     config: { id: "'G-1363B90N3Y" }
    // }, router)
    .mount('#app')

router.beforeEach((to, from, next) => {

    if (to.path == '/setPrimaryName' || to.path == '/renew' || to.path == '/tldEdit' || to.path == '/404') {
        store.commit("setFootVisible", false)
    } else {
        store.commit("setFootVisible", true)
    }

    if (to.matched.length === 0) {
        next('/')
        return false
    }
    next()
})


router.afterEach((to, from) => {
    window.gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_ID, {
        page_title: to.name,
        page_path: to.path,
        page_location: location.href
    })
})

export default app