import en from './en'
import ch from './ch'
import jp from './jp'
import kr from './kr'

export default {
  en,
  ch,
  jp,
  kr
}