import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8ab0d66e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "emailItem"
};
var _hoisted_2 = {
  "class": "bands-tips"
};
var _hoisted_3 = {
  "class": "input-box code"
};
var _hoisted_4 = {
  "class": "input-box"
};
var _hoisted_5 = {
  "class": "input-box code"
};
var _hoisted_6 = {
  "class": "dialog-footer-card"
};
var _hoisted_7 = {
  key: 0,
  "class": "dialog-footer"
};
var _hoisted_8 = {
  key: 1,
  "class": "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_el_form = _resolveComponent("el-form");
  var _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.$t('email.currentMailbox')) + " ", 1), _createElementVNode("b", null, _toDisplayString($data.userInfo.email), 1)]), !$data.isNext ? (_openBlock(), _createBlock(_component_el_form, {
    key: 0,
    model: $data.oldFrom,
    ref: "oldFrom",
    rules: $data.oldRules
  }, {
    "default": _withCtx(function () {
      return [!$data.isNext ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0,
        label: "",
        prop: "emailCode"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
            modelValue: $data.oldFrom.emailCode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.oldFrom.emailCode = $event;
            }),
            placeholder: _ctx.$t('email.editTips')
          }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("span", {
            onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
              return $options.countDown && $options.countDown.apply($options, arguments);
            }, ["stop"])),
            "class": "code-box"
          }, _toDisplayString($data.codeText), 1)])];
        }),
        _: 1
      })) : _createCommentVNode("", true)];
    }),
    _: 1
  }, 8, ["model", "rules"])) : _createCommentVNode("", true), $data.isNext ? (_openBlock(), _createBlock(_component_el_form, {
    key: 1,
    model: $data.from,
    ref: "ruleFormRef",
    rules: $data.rules
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: "",
        prop: "emailAddress"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_input, {
            modelValue: $data.from.emailAddress,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $data.from.emailAddress = $event;
            }),
            placeholder: _ctx.$t('email.email_new_address')
          }, null, 8, ["modelValue", "placeholder"])])];
        }),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "",
        prop: "emailNewCode"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_input, {
            modelValue: $data.from.emailNewCode,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $data.from.emailNewCode = $event;
            }),
            placeholder: _ctx.$t('email.code_add')
          }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("span", {
            onClick: _cache[4] || (_cache[4] = _withModifiers(function () {
              return $options.countDown && $options.countDown.apply($options, arguments);
            }, ["stop"])),
            "class": "code-box"
          }, _toDisplayString($data.codeText), 1)])];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["model", "rules"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [!$data.isNext ? (_openBlock(), _createElementBlock("span", _hoisted_7, [_createVNode(_component_el_button, {
    type: "primary",
    color: "#000",
    onClick: $options.handleSubmit
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('email.next')), 1)];
    }),
    _: 1
  }, 8, ["onClick"])])) : (_openBlock(), _createElementBlock("span", _hoisted_8, [_createVNode(_component_el_button, {
    type: "primary",
    color: "#000",
    disabled: $data.emailLoading,
    onClick: $options.handleLastStep
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('email.last_step')), 1)];
    }),
    _: 1
  }, 8, ["disabled", "onClick"]), _createVNode(_component_el_button, {
    type: "primary",
    color: "#000",
    loading: $data.emailLoading,
    onClick: $options.handleBing
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('publish.confirm')), 1)];
    }),
    _: 1
  }, 8, ["loading", "onClick"])]))])]);
}