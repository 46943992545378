export default {
    message: {
        input_placeholder: "Please enter",
        email_placeholder: "Please enter email address",
        title_placeholder: "Enter title",
        content_placeholder: "Enter content",
        email_err: "Please enter correct email address",
        select_err: 'Please select',
        input_err: 'Please enter',
        address_placeholder: "Please enter address",
        address_error: 'Please enter the correct address',
        balance_error: 'Insufficient balance for the transaction fees.',
        owner_error: 'You do not own this domain name',
        unsupport_chain: 'Unsupported chain. Switch to a compatible one?',
        no_account: 'The wallet does not have an account',
        sys_error: 'System error',
        recaptcha_error: 'Google Recaptcha error, try again later',
        reject_error: 'User rejected',
        net_error: 'Network error',
        switch_err: 'Your current wallet does not support network switching. Please upgrade your wallet APP.',
        add_chain_err: 'Switching failed, please try adding the network manually. ',
        err_1001: 'You do not own this TLD',
        err_4000: 'The parameter is incorrect',
        err_4100: 'Domain name cannot be registered',
        err_4101: 'Invalid content, please re-enter.',
        err_4102: 'The entered domain name exceeds the length limit, please re-enter',
        err_4103: 'unregistered domain name',
        err_4104: 'domain name does not exist',
        err_4105: 'This domain name is already registered',
        err_4106: 'The domain name is already registered in another chain',
        err_4107: 'Network error, please refresh try again.',
        err_4108: 'The domain name has not been released yet, please re-enter',
        err_4109: 'The entered domain name does not support registration at the moment, please re-enter',
        err_4110: 'The entered domain name does not support registration at the moment, please re-enter',
        err_4111: 'Your address violates community regulations, and you do not have permission to register a domain name',
        err_4112: 'The domain name is in the auction period',
        err_4113: 'The domain name is not in the auction period',
        err_4114: 'unsupported TLD',
        err_4115: 'The entered domain name does not support registration at the moment, please re-enter',
        err_4116: 'The first registration needs to be based on a full-year cycle',
        err_4117: 'Exceeded the maximum number of registered domain names in batches',
        err_4118: 'The use of invitation codes and vouchers at the same time is not allowed',
        err_4119: 'Voucher does not exist',
        err_4120: 'invalid voucher',
        err_4121: 'Voucher already used',
        err_4122: 'The domain name has been purchased out and does not require renewal',
        err_4123: 'The domain name is being migrated and the operation has been rejected',
        err_4124: 'The currently selected voucher has been locked and can be used in five minutes',
        err_4125: 'The domain name TLD is not open yet',
        err_4126: 'The domain is currently being registered by someone else. The lock for 5 minutes period. Please try again later.',
        err_4127: 'This domain name is not currently supported for registration on the current chain.',
        err_4128: 'This domain name is not currently supported for transfer to the selected target chain. ',
        err_4201: 'The address is not registered in this system',
        err_4202: 'Email verification code verification failed',
        err_4203: 'Email verification code has expired',
        err_4204: 'illegal operation',
        err_4205: 'Invitation code does not exist',
        err_4206: 'The rebate configuration is not configured',
        err_4207: 'System configuration error: Email related configuration not found',
        err_4301: 'Event does not exist',
        err_5000: 'unknown mistake',
    },

    header: {
        project_name: 'OpenName',
        name_fi: 'NameFi',
        register: 'Register',
        query: 'Query',
        my_domain: 'My Names',
        pricing: 'Pricing',
        invite: 'Invite',
        activities: 'Events',
        collect: 'Favorite',
        other: 'Other',
        connect: "Connect",
        not_logged_in: 'Please link the wallet first',
        show_all: 'Show all results >',
        search_placeholder: 'Search domain or address',
    },

    connectWallet: {
        connect_title: 'Connect your wallet',
        connect_desc: 'You can register and use your domain name across multiple public blockchains.',
        select_network: 'Select Network',
        select_wallet: 'Connected Wallet',
        connect_to_ethereum: 'Connect to Ethereum',
        connect_to_arbitrum: 'Connect to Arbitrum',
        connect_to_optimism: 'Connect to OP Mainnet',
        connect_to_polygon: 'Connect to Polygon',
        connect_to_bnb: 'Connect to BNB Chain',
        connect_to_zksync: 'Connect to zkSync Era',
        connect_to_base: 'Connect to Base Mainnet',
        connect_to_goerli: 'Connect to Goerli',
        connect_to_arbitrum_goerli: 'Connect to Arbitrum Goerli',
        connect_to_optimism_goerli: 'Connect to Optimism Goerli',
        connect_to_mumbai: 'Connect to Mumbai',
        connect_to_bnb_test: 'Connect to BNB Test',
        connect_to_zksync_test: 'Connect to zkSync Era Test',
        connect_to_base_test: 'Connect to Base Testnet',
        connect_wallet: 'Connected wallet',
    },

    footer: {
        project_name: 'Project Name',
        privacy_policy: 'Privacy Policy',
        user_agreement: 'Terms of Use',
        copy_right: 'OpenName'
    },

    publish: {
        cancleLembrete: 'Cancel reminder',
        cancle: 'Cancel',
        confirm: 'Confirm',
        close: 'Close',
        submit: 'Submit',
        delete: 'Delete',
        set: 'Set',
        expiration_time: 'Expires in',
        remove_favorite: 'Remove Favorite',
        coll_success: 'Successful Collected',
        copy_success: 'Copied!',
        copy_fail: 'Replication failure',
        copy_address: 'Copy',
        download_success: 'Successful Downloaded',
        network_error: 'This network is not supported',
        prompt: 'Prompt',
        successful: 'Success!',
        failed: 'Failed!',
        save: 'Save',
        copy_link: 'Copy Link',
        unsupported_chain: 'Unsupported chain. Switch to a compatible one?',
        switch_chain: 'Switch chain',
        wallect_insufficient: 'Your wallet balance is insufficient.',
        creation: 'Creation',
        year: '/year',
        buyout: '/lifetime',
        show_more: ' Show More',
        show_less: ' Show Less',
    },

    query: {
        query_title: 'Own Your ',
        query_title1: 'Favorite',
        query_title2: ' Web3 ',
        query_title3: 'Domains',
        query_title4: ' and ',
        query_title5: 'TLDs',
        query_placeholder: 'Name or address, e.g.: i.love.you',
        select_cancle: 'Deselect TLD',
        query: 'Query',
        opened_suffix: 'Open TLD',
        more_opening_suffix: 'More TLDs will be open soon',
        query_price: 'Query the TLD price list >',
        current_progress: 'Current opening progress',
        sub_progress1: '1. Currently, only pure Arabic numerals, English letters, special symbols "-", emoji are supported.',
        sub_progress2: '2. Special symbol "-" does not support to be used in the beginning, can not be registered separately and continuous use;',
        sub_progress3: '3. When registering a domain name, use a TLD, for example,.abc. Please use“.” to distinguish layers.',
        see_more: 'See more Character Set rules &gt >',
        query_error: 'The entered domain name is invalid. Check whether the entered domain name does not contain TLD (for example.eth) or contains unsupported characters.',
        registrable: 'Registrable',
        has_registered: 'Registered',
        available: 'Available',
        unavailable: 'Unavailable',
        grace_period: 'Grace period',
        btn_register: 'Register',
        btn_view: 'View',
        search_result: 'Search Result',
        search_result1: 'Result',
        registrable_only: 'Registrable only',
        hot_suffix: 'Hot TLD',
        new_suffix: 'New TLD',
        free_suffix: 'Free TLD',
        discount_suffix: ' Off',
        report: 'Report',
        favorite: 'Favorite',
        reporting_type: 'Please choose the report type',
        report_reason1: 'Racial discrimination',
        report_reason2: 'Involving adult and violent content',
        report_reason3: 'Involving minors',
        report_reason4: 'Other',
        report_supplementary: 'Supplementary statement',
        report_email: 'Email address（Optional）',
        report_success: 'Successful Reported',
        submit: 'Agree',
        service_terms: 'Terms of Use',
        terms_desc: 'Please read the platform\'s',
        terms_desc1: '\u00A0Terms of Use\u00A0',
        terms_desc2: 'carefully before you start using it.',
        agree_terms: 'I have read and agree to use',
        funny_name: 'Fun Names',
        recently_registered: 'Recently registered',
        premium: 'premium',
        year: '/year',
        buyout: '/lifetime',
        white_list_tips1: ' to ',
        white_list_tips2: ' You can register ',
        white_list_tips3: ' with any paid TLD and waive the first-year registration fee',
        white_list_tips4: ' from ',
        white_list_tips5: ' name',
        white_list_tips6: ' names',
        white_list_part_tips2: ' You can register ',
        white_list_part_tips3: ' with the following paid TLD and waive the first-year registration fee ',
        white_list_assign_tips2: 'These names have been reserved for you to register from',
        white_list_title: 'Whitelist Giveaway',
        white_list_search: 'Click to search',
        except_tip: ' Except',
        is_un_support1: 'This TLD is only supported for registration on ',
        is_un_support2: '.',
        domain: 'Domains',
        domain_tld: 'TLDs',
        domain_tld_tips: "A TLD(top-level domain) is the highest level of a domain name, which follows the final dot of a domain name. For example, in the domain name ‘thank.you’, ‘.you’ is the TLD.",
        domain_tld_tips1: 'Register a TLD, promote it, and earn registration fees.',
        domain_lab_tips: 'Year: The registration fee is paid annually.',
        domain_lab_tips1: 'Lifetime: One-time payment with no renewal fees.Hold for life.',
        domain_tld_pl: 'TLD or address, e.g.: .abc',
        domain_white_tld: 'The following TLDs have been reserved for you and are limited to your registration:',
        view_all: 'View all >',
        tld_view_all: "Didn't find the TLDs you desire? ",
        tld_view_all1: " 🔍 Try searching.",
        domain_registration: '🥳 Domain ticker:',
        domain_g: '1m',
        domain_xsq: 'h',
        domain_fzq: 'm',
        domain_tq: 'd',

    },

    priceList: {
        search_placeholder: 'Search TLD',
        price_table: 'Pricing',
        suffix: 'TLD',
        sort: 'Class',
        level_1_price: 'First-level Domain',
        level_1_price_mobile: 'First-level',
        total_registrations: 'Total Registrations',
        registrations: 'Registrations',
        multistage_price: 'Multi-Level Domain',
        multistage_price_mobile: 'Multi-Level',
        instructions: 'Instructions',
        free: 'Free',
        pre_year: 'Per year: $',
        buyout: '/lifetime',
        characters_price: ' characters: $',
        tips_4_above: '4 and 4+ characters: $',
        characters: ' characters',
        year: '/year',
        header_tld: 'TLD',
        header_price: 'Price',
        header_divide: 'Share ratio',
        header_explain: 'Instructions',
        defult: 'Custom TLDs',
        share_ratio_tips: 'OpenName users can register their own TLDs and earn a share of the domain registration fees.'
    },

    myDomain: {
        set_primary_name: 'Set Primary Name',
        no_domain_set: 'You have not yet owned a domain name',
        wallet_address: 'Wallet address',
        bind_domain: 'Binding domain (reverse record)',
        bind_desc: 'We allow you to set a domain name across links, so that your account name will be displayed in the same place as your address in your app. For example, when you log into the DApp with your wallet address, your account name will be displayed as your user name. Note that you can only select the domain name that has been forwarded to this address. Note that you can only select the domain name that has been forwarded to this address.',
        select_domain_placeholder: 'Select the domain name that you have resolved to the changed address',
        none_domain: 'You have not yet owned a domain name, or your domain name address record has not been set to that address. You can only select domain names that have already been resolved forward to this address.',
        delete_tip: 'The current domain name has been resolved. Are you sure to delete this record?',
        my_domain: 'My Names',
        batch_renewal: 'Extend',
        expiration_reminder: 'Remind',
        grace_period_until: 'Grace period until：',
        renewal: 'Extend',
        cross_chain_transfer: 'Bridge',
        bridging: 'Bridging >',
        tips: 'The current domain name becomes a free domain name, and you only need to pay Gas for renewal during this period. You can own the domain name for free forever. Do you want to renew immediately?',
        transfer_desc: 'You are transferring ownership or management rights to others, please proceed with caution.',
        renewal_cycle: 'Extension Period',
        renewal_time: 'Renewal time',
        transfer_placeholder: 'Select available vouchers',
        success: 'Successful Renewal',
        setting_success: 'Successful Set',
        setting_error: 'Parsing Failed',
        remove_success: 'Successful Removed',
        remove_fail: 'Failed Removed',
        bought_out_held: 'Lifetime',
        bought_out_held1: 'Lifetime',
        bought_out_held2: '',
        grace_period_to: 'Grace period to',
        about_to_expire: 'About to expire',
        free_suffix: 'Free TLD, hold for life',
        free_suffix1: 'Free TLD',
        free_suffix2: 'Hold for life',
        bought_out: 'Bought out',
        week_4: '4 weeks',
        week_3: '3 weeks',
        week_2: '2 weeks',
        owner: 'Owner',
        manager: 'Manager',
        all: 'All',
        permission: 'Permission',
        search_placeholder: 'Search domain',
        select_all: 'Select All',
        domainAll: 'View all domains',
        err_title: 'Prompt',
        err_tip1: 'We regret to inform you that your domain name ',
        err_tip2: '',
        err_tip3: "Terms Of Use",
        err_tip4: ' ',
        err_tip6: ' has been deleted by the system due to a violation of the',
        err_tip5: 'regarding sensitive terms, and it cannot be registered again by anyone. As compensation, the system has issued a voucher to you. If you have any questions, please contact our customer service team.',
        err_bottom: 'View vouchers > ',
        registering: 'Registering',
        his_name: 'Their Names',
        no_transfer: 'No bridge supported during the grace period',
        forbid_transfer: 'Prohibited cross-chain transfers.',
        domain_loca_chain: "Domain Location Chain",
        tld_loca_chain: "Currently, this TLD is on ",
        domain_screen: "Filter",
        domain_chain: "Chain",
        domain_jurisdiction: "Type",
        domain_reset: "Reset"
    },

    setPrimaryName: {
        set_primary_name: 'Set primary name',
        set_desc: 'Set a primary name to serve as your unified identity or username across different Dapps. Once set, you can change it anytime.',
        current: "Current",
        confirm_Settings: 'Confirm Setting',
        release_setting: 'Delete primary name',
        release_tip: 'You are about to delete the primary name settings. Are you sure?',
        set_tip1: '1. Point the domain name to your address.',
        set_tip2: '2. Complete the setup of the primary name.',
    },

    manager: {
        edit: 'Edit Profile',
        set_primary: 'Set as Primary Name',
        set_primary_desc: 'Set a primary name to serve as your unified identity or username across different Dapps. Once set, you can change it anytime.',
        empty_desc: 'No word yet',
        forever: 'Forever',
        name_info: 'Permission',
        parent_name: 'Parent domain',
        owner: 'Owner',
        manager: 'Manager',
        transfer: 'Transfer',
        modify: 'Modify',
        expiration_time: 'Expiration',
        address: 'Address',
        social_media: 'Social Media',
        website: 'Website',
        other: 'Other',
        resolver: 'Resolver',
        modify_manager: 'Modify Manager',
        modify_desc: 'You are assigning management rights to someone else (you still have ownership rights). Exercise caution.',
        new_manager: 'New Manager:',
        transfer_registrant: 'Transfer',
        transfer_desc: 'You are changing the owner rights of the domain name. After the change, you will permanently lose control of the account. Exercise caution when performing this operation.',
        transfer_tld_desc: 'You are changing the owner rights of the TLD. After the change, you will permanently lose control of the account. Exercise caution when performing this operation.',
        domain_name: 'Domain',
        current_address: 'Current address',
        receiver: 'Receiver:',
        edit_add: '+ Add',
        edit_address_desc: 'You can add your receiving addresses for each chain to your domain data. When your friend transfers funds to you, they only need to enter your domain account in the recipient input box.',
        edit_parser_desc: '* The parser stores all of your records, such as addresses, text records, etc. Before making any changes, you\'d better understand how the parser works.',
        edit_success: 'Successful Edited',
        edit_fail: 'Failed Edited',
        default_resolver: 'Default resolver',
        input_parser_placeholder: 'Enter the code to get a discount',
        transfer_success: 'Successful Transferred',
        transfer_fail: 'Failed Transferred',
        modify_success: 'Successful Modified',
        modify_fail: 'Failed Modified',
        transfer_self_err: 'Do not transfer to yourself',
        modify_self_err: 'Do not modify to yourself',
        date: 'Date',
        help_renewal: 'Help Extend',
    },

    transfer: {
        transfer_domain_name: 'Bridge Domain',
        transfer_domain_tld: 'Bridge TLD',
        domain: 'Domain',
        current_chain: 'Current Chain',
        from: 'From',
        bridge_to: 'Bridge To',
        transfer_tip1: 'Cross-chain transfers require Gas payment (based on real-time Gas prices on chain) and cannot be canceled once initiated.',
        transfer_tip2: 'You can leave the page during the transfer process and check the transfer status on the "My Names" page.',
        transfer_tip2_tld: 'You can leave the page during the transfer process and check the transfer status on the "My TLD" page.',
        chain_type: 'Chain type',
        chain_placeholder: 'Select the chain',
        address: 'Address',
        address_placeholder: 'Enter the address',
        start_transfer: 'Start Bridge',
        bridging: 'Bridging',
        finish_tip: '🎉 Your domain name has been transferred successfully, please switch chains and wallets to view the domain name.',
        step_title: 'Three steps',
        step_1: 'Step 1',
        step_1_desc: 'Destroy the domain name on current chain.',
        step_1_desc_tld: 'Destroy the TLD on current chain.',
        step_1_finish: 'Go to the blockchain browser to view details. >',
        step_2: 'Step 2',
        step_2_desc: 'Send cross-chain information through LayerZero.',
        step_2_tip: 'The transfer process may take a few minutes.',
        step_3: 'Step 3',
        step_3_desc: 'Mint the domain name on the new chain of your choice.',
        step_3_desc_tld: 'Mint the TLD on the new chain of your choice.',
        step_3_tip: 'If the casting process cannot be completed for a long time, ',
        step_3_tip1: 'please contact customer service.',
        step_finish: 'Query the bridging details on LayerZero >',
        step_wait: 'Pending',
        step_completed: 'Completed',
        transfer_fail: 'Failed Transferred',
        bridge_success: 'Success',
        bridge_success_desc: 'Your domain name has been bridged successfully, please switch chain and account to view the domain.',
        bridge_success_tld: 'Your TLD has been bridged successfully, please switch chain and account to view the TLD.',
        powered_by: 'Powered by LayerZero'
    },

    signin: {
        enquiry: 'Enquiry',
        registration: 'Registration',
        register: 'Register',
        year: 'Year',
        m_year: 'year',
        m_years: 'years',
        tips: 'Please enter the invitation code',
        method: 'Registration Period',
        number_year: 'Number of years',
        expense: 'Registration Fee',
        invitation_code: 'Invitation',
        coupon: 'Voucher',
        coupon_placeholder: 'Select available vouchers',
        total_cost: 'Estimated Total',
        cost: 'Estimated',
        original_price: 'Original price',
        buy_out: 'Lifetime',
        free: 'Free',
        premium: 'Premium',
        premium_main: 'Premium Reminder',
        premium_main_price: 'Enter reminder price',
        premium_tips: 'Premium arrives at this price time (local time zone)',
        nowPremium: 'Current premium',
        introduce_title: 'Premium Curve',
        introduce_content_tip: 'To provide fair registration opportunities for just-expired names, premium starts at $100 million and gradually decreases to $0 within 21 days.',
        introduce_item_left: 'The current purchase price is ',
        introduce_item_right: ' days to go (21 days in total)',
        remind: 'Remind ',
        free_tips: 'This domain name is permanently free to register and you only have to pay gas when you register.',
        number: 'Quantity',
        not_sufficient_funds: 'Your available balance is insufficient to register',
        is_register: 'The domain name is being registered',
        hold_for_life: 'Hold For Life',
        target_date: 'To this price',
        target_date_me: 'Remind me at this price',
        code_error: 'Invalid',
        already_set: 'Already Set',
        register1: '1. Wallet signature',
        register2: '2. Lock domain names',
        register3: '3. Make payment to register',
        register4: '2. Lock TLD',
        confirm3: 'Make payment to extend',
        first_year_free: 'First year free',
        select_coupon: 'Select Voucher',
        tips_rate: 'When you successfully register and start operating the TLDs, a certain proportion of the domain name registration revenue will be distributed to the platform.',
        select_ayment: 'Payment',
    },

    email: {
        emailError: 'Email format error',
        email_tips: 'Bind Email',
        email_code_ver: 'Enter code for bound email',
        get_code: 'Send Code',
        band_tips: 'Please bind email to receive alerts.',
        email_address: 'Please enter email address',
        email_new_address: 'Please enter a valid new email address',
        code_add: 'Please enter verification code',
        ver_code_err: 'Verification code error',
        pop_tips: 'We will send reminder emails (once a week) to your email address 30 days before your domain name expires.',
        re_code: 'Resend',
        emailEdit: 'Modify bound email',
        currentMailbox: 'Currently bound to email address:',
        editTips: 'Enter code for bound email',
        next: 'Next',
        last_step: 'Back',
        binding: 'Confirm Binding',
        un_binding: 'Unbound',
        reminder_email: 'Reminder Email',
        success: 'Successful Bound',
        send_success: 'Successful Sent',
        email_error: 'Email cannot be repeated',
        email_system_err: 'The email was sent unsuccessfully, please try again later.'
    },

    ShoppingCart: {
        title: 'Bulk Registration',
        close: 'Empty',
        year: 'Year',
        m_year: 'year',
        m_years: 'years',
        method: 'period',
        buy_out: 'Lifetime',
        number_year: 'Number of years',
        invitation_code: 'Invitation',
        code_tips: 'Please enter the invitation code',
        coupon: 'Voucher',
        coupon_placeholder: 'Multiple vouchers can be chosen',
        number: 'Quantity',
        total_cost: 'Estimated Total',
        original_price: 'Original price',
        register: 'Register',
        tips: 'Enter the invitation code to get discounted registration fee.',
        empty: 'No content',
        empty1: 'You do not yet own a domain.',
        empty2: 'You do not yet own a TLD.',
        searh_empty: 'No search results',
        empty1_desc1: 'Go and ',
        empty1_register: '\u00A0Register\u00A0',
        empty1_desc2: ' one now.',
        hold_for_life: 'Hold For Life',
        estimated_gas: 'Gas',
        estimated_gas_fee: 'Estimated Gas',
        discount: '',
        discount1: ' off',
        registered: 'Registered',
        unavailable: 'Unavailable',
        unsupported_chain: 'Unsupported chain',
        card_limit: 'Maximum 15 domains can be added.',
        un_support_payments1: ' This domain does not support',
        un_support_payments2: ' payments and is limited to ',

    },

    favorite: {
        favorites: 'My Favorites',
        empty_desc: 'You don\'t have any favorite domain names yet. Go search and find your favorites now.',
        empty_desc1: 'You don\'t have any favorite TLDs yet. Go search and find your favorites now.',
        query: 'Search Name',
        query1: 'Search TLD',
        registration: 'Registration',
        registration_premium: 'Registration is available by paying a premium',
        unregistrable: 'Unregistrable',
        grace_period: 'Grace period until',
        view: 'View',
    },

    coupon: {
        coupon_list: 'Vouchers',
        coupon: 'Voucher',
        release_time: 'Distribution',
        employ: 'Used',
        unused: 'Available',
        expired: 'Expired',
        empty: 'No vouchers',
        expiration: "Expiration",
        available_suffixes: "Available TLDs",
        permanently_valid: " Permanently Valid",
        universal_voucher: "Universal Voucher",
        tips_role: "Usage rules: You can use a voucher to deduct the corresponding amount of registration fee when registering a domain name."
    },
    inviteFriends: {
        title: 'Invite Friends and Get Rewards',
        tips1: 'Refer your friends to register domains or TLDs through the following link, when the registration is successful, they will',
        tips2: '  off',
        tips3: ',  and you can  ',
        tips4: 'earn up to ',
        tips6: '  get ',
        tips5: ' of the registration fee as a referral reward.',
        invite_friends: 'Invite Now',
        invitation_record: 'Invitation Records',
        time: 'Registration time',
        address: 'Address of invitee',
        chain: 'Registration chain',
        reward: 'Reward',
        no_data: 'No records yet',
        pup_tips: 'Create your cross-chain domain name',
        bonus_tips: 'Invite friends and get bonus :',
        apply_for: 'Apply for higher rewards >',
        look_rule: 'View specific rules >',
        apply_success: 'Submitted for review',
        data_overview: 'Summary',
        data_ranking: 'Ranking',
        data_rank: 'Ranking',
        data_address: 'Address',
        data_invite_num: 'Invited Registrations',
        data_address_num: 'Invited Addresses',
        data_award_num: 'Reward Earned',
        date_7: '7D',
        date_30: '30D',
        date_all: 'All',
        rebate_tips: 'Rebate ratio and inv',
        role: 'Your role',
        rebate_ratio: 'Commission rate',
        number_invited: 'Invited registrations',
        invited_friend: "Invited Friends",
        your_invitation: "Your Invitations",
        invitee_address: "Address of invitee",
        invited_number: "Total Registered",
        invited_money: "Total Registration Fees",
        invited_award: "Reward you earn",
        invited_detail: "Details",
        invited_register_detail: "Registration Details",
        invited_register_domain: "Name",
        invited_register_chain: "Registered chain",
        invited_register_fee: "Registration Fee",
        invited_register_award: "Reward",
        invited_register_time: "Registration Time",
        individual_user1: 'Individual user',
        individual_user2: 'KOL',
        individual_user3: 'Distributor',
        individual_user4: 'Partner',
        token_reward: 'Your Rewards',
        token_tips: 'Rewards have been automatically transferred to the address on the corresponding blockchain.',
        rebate_ratio_tips: '25% rebate for application as a KOL/ Distributor/partner',
        invitation_link: 'Your invitation link',
        invitation_code: 'Your invitation code',
        registration_fee: 'Registration fee',
        reward_earn: 'Reward you earn',
        invite_account_unLogin: 'Connected wallet to get my invitation',
        invite_friends_discount: "Friend's discount",
        domain: 'domain',
        domains: 'domains',
        screen_shot: 'Take a screenshot to save the invitation poster',
        tips_role: 'The default ranking weight for lists is based on the rewards earned. To view other rankings, you can click the filter button to see the results for other indicators.',
        no_rewards: 'You have no rewards.',
        invite_1: 'You have an application record',
        invite_2: 'You are already a KOL status.',
        invite_3: 'Sorry, You do not meet the identity requirements yet',
        invite_4: 'Initiate application',
        invite_5: 'Applicant status',
        invite_6: 'Your introduction',
        invite_7: 'Contact information',
        invite_8: 'Community link',
        invite_9: 'Under review',
        invite_10: 'We will contact you within 24 hours, please be patient',
        invite_11: 'Audit result',
        invite_12: 'Undo',
        invite_13: 'Approved, congratulations on your activity KOL status',
        invite_14: 'Back',
        invite_15: 'I am very sorry that your application was not approved',
        invite_16: 'Your information does not meet the requirements of the application, so you cannot pass, very sorry, please re-enter the correct information'
    },

    userInfo: {
        my_account: 'My Account',
        my_domain: 'My Names',
        my_tld: 'My TLDs',
        my_collection: 'Favorites',
        my_coupon: 'Vouchers',
        my_email: 'Email',
        switch_wallets: 'Switch Wallet',
        exit_wallet: 'Disconnect',
        operation_record: 'Records',
        revise: 'Modify',
    },

    applyToJoin: {
        apply_to_join: 'Apply for higher rewards',
        join_tip1: "👋 Hi, welcome to the Rebate Scheme application page! ",
        join_tip2: "If you agree with us and have high interest in domain name product promotion, we are happy to invite you to join the platform's invitation and call program: Invite friends to register a domain name, you can enjoy up to 25% transaction fee rebate ~  ",
        join_tip3: 'In addition, our invitation program will expand with the development of the business. Stay tuned. 😊',
        apply: ' In which capacity will you apply  ',
        text1: 'KOL (social media KOL, opinion leader in crypto industry, etc.)',
        text2: 'Distributor (individual/organization with traffic or the ability to acquire traffic)',
        text3: 'Partners (NFT project side, public chain project side, institutions, etc.)',
        your_introduction: 'Your Bio',
        please_enter: 'Please enter',
        social_media: 'Social Media ',
        link: 'Your community/official website link',
        contact: 'Contact information (Email/Telegram/Discord) ',
        email: 'Email',
        wallet: 'Wallet address (modifiable) ',
        wallet_tip: 'Contact information (Telegram/Discord/Email/Twitter) ',
        submit: 'Submit an application',
        role: 'Role',
        reward_ratio: 'Reward ratio',
        kol: 'KOL',
        registrar: 'Distributor',
        partner: 'Partner',
        enter: 'Required field, please enter the content.',
        tip1: 'I registered a favorite domain name on OpenName, Come and give it a try!',
        tip2: 'Create your cross-chain domain name'
    },

    renew: {
        name_renewal_fee: 'Extend Names',
        expiration_time: 'Expires in',
        renewal_fee: 'Renewal Fee',
        cancel: 'Cancel',
        renewal: 'Extend',
        renewal_cycle: 'Extension Period',
        hold_for_life: 'Hold For Life',
        reconfirm: 'Confirmation of Extension',
        selected: 'Selected',
        selected_tld: 'Selected TLD',
    },

    tldRenew: {
        name_renewal_fee: 'Extend TLDs',
    },

    timeName: {
        year: 'Year',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        buy_out: 'Lifetime',
        m_year: 'year',
        m_month: 'month',
        m_week: 'week',
        m_day: 'day',
        m_years: 'years',
        m_months: 'months',
        m_weeks: 'weeks',
        m_days: 'days',
        free: 'Hold for life',
        years: 'Years',
        months: 'Months',
        weeks: 'Weeks',
        days: 'Days',
    },

    operationRecord: {
        title: 'Records',
        search_tips: 'Search domain',
        table_1: 'Type',
        table_2: 'Name',
        table_3: 'Address',
        table_4: 'Period',
        table_5: 'Actual cost',
        table_6: 'Gas',
        table_7: 'Time',
        type_1: 'Register',
        type_2: 'Renew',
        type_3: 'Set Primary Name',
        type_4: 'Bridge',
        type_5: 'Modify Manager',
        type_6: 'Transfer Owner',
        type_7: 'Receive Name',
        type_8: 'Cross-chain transfer',
        type_9: 'Transfer owner',
        type_from: 'From',
        type_to: 'To',
    },

    activities: {
        activities: 'Events',
        underWay: 'Live',
        upcoming: 'Coming soon',
        ended: 'Finished',
        activity_time: 'Activity time',
        rewards: 'Rewards',
        invite_now: 'Invite Now',
        invite_desc: '😃I\'ve created the ultimate Omnichain Domain Name through “OpenName” and now they offer lifelong domains starting at $5🥳. Don\'t miss out!!!! 👀Come and Explore:\n ',
        rule: 'Rule',
        activity_chain: 'Chains',
        rank: 'Ranking',
        earn_rank: 'Earning ranking',
        invite_address_rank: 'Invited address',
        invite_register_rank: 'Invited registrations',
        register_rank: 'Domains registration',
        option_rank: 'Rank',
        option_address: 'Address',
        option_bonus: 'Reward Earned',
        option_invite_address: 'Invited address',
        option_invite_register: 'Invited registrations',
        option_domain: 'Domains',
    },

    mailboxBinding: {
        tip1: '1.Receive Expiry Reminders by Binding Email',
        tip2: '2.Follow our social media',
        tip3: 'Set Primary Name',
        tip4: 'Keep Registration',
        tip5: 'Follow our social media',
        tip6: 'Bind Email'
    },

    nameFi: {
        name_fi_title: 'Register Names To\nEarn ',
        coins: 'Coins ',
        name_fi_title2: 'and',
        points: ' Points',
        first_namefi: 'The First NameFi of The World',
        coin_desc: 'Coins: Can be swapped into USDT',
        point_desc: 'Points: Probably Nothing',
        join_tele: 'Join Telegram',
        coming_soon: '✨ Coming Soon',
        intro: 'NameFi Introduction',
        intro_desc0: 'NameFi is a new domain name value paradigm, allowing domain names to continuously generate value.',
        intro_desc1: 'By ',
        intro_desc1_bold: 'Staking Names',
        intro_desc2: ' or ',
        intro_desc2_bold: ' Completing Tasks',
        intro_desc3: ', you can earn Coins and Points. Coins can be swapped into USDT from the reward pool at any time,reward pool is 40% of the platform\'s net registration fee revenue. Points represents your contributions on OpenName.',
        intro_desc4: 'Register your domain names now and get ready to participate in staking!',
        register: 'Register Names',
        earn_desc1: 'Stake domain names, staking rewards include Coins and Points.',
        earn_desc2: 'During the staking period, you can still transfer or bridge your domain names, but your staking earnings will also undergo corresponding changes.',
        earn_desc3: 'Different domain TLDs, lengths, and registration periods have different rewarding weights.',
        weight_table: 'Staking Weights Table',
        suffix: 'Suffix',
        character1: '1 character',
        character2: '2 character',
        character3: '3 character',
        character4: '4 character or more',
        suffix_weight: 'TLD Weight',
        more: 'More',
        fold_up: 'Fold up',
        task_earn1: 'All users can earn Coins and Points by completing easy tasks.',
        task_earn2: 'The amount of Coins and Points is determined by the quantity of completed tasks.',
        task_earn3: 'The task list and detailed rules will be announced at the beginning of the event, stay tuned.',
        rule_remark: '* The content is for reference only, please refer to the official rules provided by OpenName.\n* OpenName has the right to modify and interpret the rules.',
    },

    tldDetail: {
        tld_profile: 'Edit Profile',
        copy_link: 'Copy Link',
        tld_share: 'Share',
        share_tld_desc: 'I registered a favorite domain name on OpenName, Come and give it a try! 🎉',
        share_tld_desc2: 'Have your favorite web3 domain',
        report: 'Report',
        owner: 'Owner',
        manager: 'Manager',
        expiration_time: 'Expiration',
        renewal: 'Extend',
        more: 'More',
        name_holder: 'Owners',
        name_registration: 'Domains',
        registration: 'Register',
        domain_pricing: 'Domain Pricing',
        character_length: 'Character length',
        first_level_domain: 'First-level Domain',
        multi_level_domain: 'Multi-level Domain',
        first_level_desc: 'Domains consists of two or more sets of characters separated by a dot. A domain name that contains two sets of characters is a First-level domain.For example, love.you is a First-level domain.',
        multi_level_desc: 'Domains consists of two or more sets of characters separated by a dot. A domain name that contains two sets of characters is a First-level domain.For example i.love.you is a Multi-level domain.',
        character1: '1 character',
        character2: '2 character',
        character3: '3 character',
        character4: '4 character+',
        any_length: 'Any length',
        information: 'Profile',
        social_media: 'Social Media',
        other: 'Other',
        register_record: 'Registration Records',
        name: 'Name',
        address: 'Address',
        registration_cycle: 'Registration Period',
        registration_fee: 'Registration Fee',
        registration_time: 'Registration Time',
    },

    tldEdit: {
        modify_pic: 'Modify profile picture',
        add_photo: 'Add photo',
        introduction: 'Bio',
        introduction_placeholder: 'Please enter',
        draft: 'Draft',
        release: 'Release',
    },

    tldData: {
        address_num: 'Owners',
        name_num: 'Domains',
        your_income: 'Your Income',
        cumulative_income: 'Total Income',
        platform_ratio: 'Platform share ratio',
        your_share: 'Your Sharing Ratio',
        domain_records: 'Registration Records',
        extend_record: 'Renewal Records',
        registration: 'Registration',
        extension: 'Renewal',
        extension_period: 'Extension Period',
        extension_fee: 'Extension Fee',
        share_address: 'Sharing Address',
        share_income: 'Sharing Income',
        extend_time: 'Extension Time',
        address_tip: 'The address refers to the renewal address for the domain.',
        agency: 'Sharing',
        agency_title: 'Sharing Records',
        register_address: 'Registered Address',
        agency_address: 'Marketer Address',
        agency_fee: 'Registration Fee',
        agency_lcome: 'Marketer Income',
        you_lcome: 'Your Income',
        agency_time: 'Time'
    },

    tldEntrance: {
        tld_owners: 'By',
        tld_on: 'On',
        tld_domain: 'Domains',
        tld_holders: 'Owners',
        all_chain: 'Omnichain'
    },

    amendTld: {
        setting_price: 'List for Reqistration',
        setting_desc: 'If you need any help with the TLD setup process,  ',
        setting_desc1: 'please contact us.',
        price_modle: 'Pricing',
        one_domain: 'First-level Domain',
        two_domain: 'Multi-level Domain',
        one_ch: 'character',
        two_ch: 'characters',
        buy_out: 'lifetime',
        event_ling: 'Any length',
        discount: 'Discount',
        allow_chail: 'Select Chains for Registration',
        allow_cross_chail: 'Select Chains for Bridging',
        pl_1: 'Please enter, 100 means no discount',
        pl_2: 'Please select',
        all_chain: 'All chains',
        tip_2: 'After you select a public chain, users will only be able to transfer domain names across chains on the corresponding public chain. If not selected, it will default to all public chains supported by the platform. ',
        tip_1: 'You can choose which Chain users can register domains under this TLD. You can make single or multiple selections, where "all" represents support for all Chains.',
        tip_3: 'After you select a price template, you can quickly list your TLD. If you want to make more settings, you can go to "My TLDs" to make more settings.',
        grounding: 'Confirm',
        grounding_tld: 'List TLD',
        custom: 'Custom',
        sold_out: 'Confirm removal',
        sold_out_tips: 'After being removed from the shelves, your TLD will no longer be available for user registration. Please proceed with caution. ',
        sold_out_1: "I'll think about it",
        sold_out_confirm: 'confirm',
        year: 'Annual subscription',
        liftTime: 'Buyout, hold for lifetime',
        m_year: '/year',
        m_liftTime: '/lifetime',
        no_limit: 'No limit',
        manage_secondary: 'Registrar/Marketer',
        manage_secondary_tips: 'Set up that revenue sharing scheme to registrar/marketer.',
        address: 'Address',
        rate: 'Rate',
        agency_address_error: 'Invalid address',
        agency_address_error1: 'Address cannot be empty',
        agency_address_error2: 'Duplicate address exists',
        agency_rate_error: 'Agent profit sharing ratio is wrong',
    },

    myTld: {
        successful: 'Submit Successfully',
        apply: 'Apply',
        my_tld: 'My TLDs',
        search_tld: 'Search TLDs',
        unOpen_tld: 'Registration Closed',
        open_tld: 'Accepting Registration',
        tld_data: 'Profile',
        tld_open: 'List for Registration',
        tld_share: 'Link',
        tld_date: 'Data',
        tld_price: 'Pricing',
        tld_register: 'Registering',
        tld_cross: 'cross chain',
        tld_owner: 'Owner',
        tld_manager: 'Manager',
        tld_tips: 'The TLDs not open for registration cannot be renewed. It is advised to open registration at the earliest convenience. ',
        tld_transfer: 'Transfer Owner',
        tld_change: 'Modify Manager',
        tld_crossing: 'Bridge',
        tld_close: 'Close Registration',
        batch_renewal: 'TLD Extend',
        tld_close_verify: 'Close Registration',
        tld_close_confirm: 'Confirm Closure',
        tld_close_tips: 'After closing registration, users will not be able to register the domain name of this TLDs. Please proceed with caution! ',
        rate: 'Sharing',
        rate_tips: 'When other users register a domain under this TLD, you will receive ',
        rate_tips_2: ' of the Registration Fees.',
        statusTextL: 'State',
        no_description: 'No description yet',
        applyPopup: 'Apply for TLD Registration',
        pop_tips: "After applying, we'll reach out to you by email. Stay tuned.",
        apply_text: 'Reason',
        apply_text_error: 'Please enter the Reason',
        l_tip1: 'the annual price must be greater than or equal to',
        l_tip2: 'the lifetime price must be greater than or equal to',
        l_tip3: 'annual price',
        l_tip4: 'lifetime price',
        l_tip5: 'the annual price must be less than or equal to',
        l_tip6: 'the lifetime price must be less than or equal to',
        price_empty: 'TLD price cannot be empty',
        not_allowed: 'Bridge is not allowed',
        group_tip: 'Congratulations on having the best TLD, please join the TLD Owners Exclusive service group',
        btn1: 'Further Registration',
        btn2: 'List for Registration',
    }
}