import {
    API
} from '../api'
import store from '@/store/index.js'
const path = process.env.VUE_APP_BASE_API

export const QUERY_API_URL = {
    SUPPORTED_TLDS: `${path}/v1/domain/supported/suffixes`,
    SEARCH: `${path}/v1/domain/search`,
    REPORT: `${path}/v1/user/report`,
    REGISTERED: `${path}/v1/domain/check/registered/`,
    FUNNYLIST: `${path}/v1/domain/funnyList`,
    WHITELIST: `${path}/v1/domain/whitelist`,
    VRATE: `${path}/v1/public/vrate`,
    TLD_SEARCH: `${path}/v1/domain/tld/search`,
    TLD_SUPPORTED: `${path}/v1/domain/supported/tlds`,
    TLD_WHITELIST: `${path}/v1/domain/tld/whitelist`,
    TLD_TOTAL: `${path}/v1/domain/tld/total`,
    CFG_TOKENS: `${path}/v1/domain/cfgTokens`,
    GET_LATEST: `${path}/v1/domain/latest`,
    TLD_DEFAULT: `${path}/v1/domain/tldPriceInfo/default`,
}

export default {
    async supprotedTlds(conf) {
        let account = conf.account ? `/${conf.account}` : '/'
        return await API.get(QUERY_API_URL.SUPPORTED_TLDS + account, '', '', false)
    },

    async getSupprotedTlds(conf) {
        let account = ''
        return await API.get(QUERY_API_URL.TLD_SUPPORTED + account, '', '', false)
    },

    async search(params, conf) {
        return await API.post(QUERY_API_URL.SEARCH, params, conf, false)
    },

    async report(params, conf) {
        return await API.post(QUERY_API_URL.REPORT, params, conf)
    },
    async tldSearch(params, conf) {
        return await API.post(QUERY_API_URL.TLD_SEARCH, params, conf, false)
    },

    async registered(domain, conf) {
        return await API.get(QUERY_API_URL.REGISTERED + domain, '', conf, false)
    },
    async getFunnyList(params, conf) {
        return await API.get(QUERY_API_URL.FUNNYLIST, params, conf, false)
    },
    async getWhitelist(conf) {
        let account = conf.account ? `/${conf.account}` : '/'
        if (!conf.account) {
            return {}
        }
        return await API.get(QUERY_API_URL.WHITELIST + account, '', '', false)
    },

    async getTldWhitelist(conf) {
        let account = conf.account ? `/${conf.account}` : '/'
        if (!conf.account) {
            return {}
        }
        return await API.get(QUERY_API_URL.TLD_WHITELIST + account, '', '', false)
    },

    async getTldTotal(domain, conf) {
        return await API.get(QUERY_API_URL.TLD_TOTAL + '/' + domain, '', '', false)
    },

    async getCfgToken(domain, conf) {
        return await API.get(QUERY_API_URL.CFG_TOKENS, '', '', false)
    },

    async getLatest(num, conf) {
        return await API.get(QUERY_API_URL.GET_LATEST + '/' + num, '', '', false)
    },

    async getTldDefultPrice(num, conf) {
        return await API.get(QUERY_API_URL.TLD_DEFAULT, '', '', false)
    },

}