import {
    API
} from '../api'
import store from '@/store/index.js'

const path = process.env.VUE_APP_BASE_API

export const DOMAIN_API_URL = {
    CHECK_DOMAIN_SUPPORTED: `${path}/v1/domain/check/supported/`,
    CHECK_DOMAIN_REGISTERD: `${path}/v1/domain/check/registered/`,
    GOOGLE_SIGN: `${path}/v1/domain/sign`,
    DETAIL: `${path}/v1/domain/detail`,
    DOMAIN_LIST: `${path}/v1/domain/list`,
    REVERSE_LIST: `${path}/v1/domain/reverseList`,
    MIGRATE_INFO: `${path}/v1/domain/migrateInfo`,
    DST_CHAINIDS: `${path}/v1/domain/migrate/dstChainIds`,
    SIGN_FOR_MIGRATE: `${path}/v1/domain/signForMigrate`,
    MIGRATE_RATE: `${path}/v1/domain/migrate/rate`,
    BURNED_LIST: `${path}/v1/domain/burnedList`,
    SET_READ_FLAG: `${path}/v1/domain/burnedList/setReadFlag`,
    PRICE_LIST: `${path}/v1/domain/suffix/priceList`,
    DOMAINS: `${path}/v1/domain/domains`,
    REGISTERNUM: `${path}/v1/domain/suffix/registerNum`,
}

export default {
    async getCheckDomainRegister(domainName, conf) {
        return await API.get(DOMAIN_API_URL.CHECK_DOMAIN_REGISTERD + domainName, {}, conf)
    },

    async getCheckDomainSupported(domainName, conf) {
        return await API.get(DOMAIN_API_URL.CHECK_DOMAIN_SUPPORTED + domainName, {}, conf)
    },

    async sign(params, conf) {
        return await API.post(DOMAIN_API_URL.GOOGLE_SIGN, params, conf)
    },

    async detail(params, conf) {
        return await API.post(DOMAIN_API_URL.DETAIL, params, conf, false)
    },

    async getDomainList(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(DOMAIN_API_URL.DOMAIN_LIST, params, conf, false)
    },

    async getIsRegistesList(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(DOMAIN_API_URL.DOMAINS, params, conf, false)
    },

    async getReverseList(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(DOMAIN_API_URL.REVERSE_LIST, params, conf, false)
    },

    async getMigrateInfo(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(DOMAIN_API_URL.MIGRATE_INFO, params, conf, false)
    },

    async getDesChainIds(params, conf) {
        return await API.post(DOMAIN_API_URL.DST_CHAINIDS, params, conf, false)
    },

    async signForMigrate(params, conf) {
        return await API.post(DOMAIN_API_URL.SIGN_FOR_MIGRATE, params, conf, true)
    },

    async getMigrateRate(params, conf) {
        return await API.get(DOMAIN_API_URL.MIGRATE_RATE, params, conf, false)
    },

    async burnedList(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(DOMAIN_API_URL.BURNED_LIST, params, conf, false)
    },

    async setReadFlag(params, conf) {
        return await API.post(DOMAIN_API_URL.SET_READ_FLAG, params, conf)
    },

    async getPriceList(params, conf) {
        return await API.post(DOMAIN_API_URL.PRICE_LIST, params, conf, false)
    },

    async getRegisterNum(params, conf) {
        return await API.get(DOMAIN_API_URL.REGISTERNUM, {}, conf, false)
    },
}