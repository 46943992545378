import {
    API
} from '../api'
import store from '@/store/index.js'

const path = process.env.VUE_APP_BASE_API

export const FAVORITE_API_URL = {
    IS_FAVORITE: `${path}/v1/user/isFavorite`,
    FAVORITE: `${path}/v1/user/favorite`,
    FAVORITE_LIST: `${path}/v1/user/favorite/list`,
    FAVORITE_COUNT: `${path}/v1/user/favorite/count`,
}


export default {
    async isFavorite(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(FAVORITE_API_URL.IS_FAVORITE, params, conf, false)
    },

    async addFavorite(params, conf) {
        return await API.post(FAVORITE_API_URL.FAVORITE, params, conf, true)
    },

    async deleteFavorite(params, conf) {
        // if(!params.walletAddress){
        //     params.walletAddress = store.state.account
        // }
        return await API.delete(FAVORITE_API_URL.FAVORITE, params, conf)
    },

    async getFavoriteList(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(FAVORITE_API_URL.FAVORITE_LIST, params, conf, false)
    },

    async getFavoriteCount(params, conf) {
        let account = store.state.account ? `/${store.state.account}` : ''
        return await API.get(FAVORITE_API_URL.FAVORITE_COUNT + account, '', conf, false)
    },

}