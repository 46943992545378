import _objectSpread from "/root/.jenkins/workspace/prod-dapp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'mailboxBinding',
  data: function data() {
    return {
      second: 3,
      drawerShow: true,
      userInfo: {}
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(['showBindingBox'])), {}, {
    isMobile: function isMobile() {
      return this.$store.state.isMobile;
    }
  }),
  beforeCreate: function beforeCreate() {},
  mounted: function mounted() {
    var emailList = localStorage.getItem('emailList');
    if (emailList) {
      this.userInfo = JSON.parse(emailList);
    } else {
      this.userInfo = {
        email: '',
        myDomainsReminder: false
      };
    }
  },
  methods: {
    settingEmail: function settingEmail(e) {
      this.$emit('settingEmail', e, 1);
    },
    handleClosesBox: function handleClosesBox(e) {
      if (e == 2) {
        this.$toUrl('/');
      } else if (e == 1) {
        this.$toUrl('/setPrimaryName');
      } else if (e == 3) {
        if (this.userInfo.email && this.userInfo.email != 'null' && this.userInfo.email != null) {
          this.settingEmail(3);
        } else {
          this.settingEmail(2);
        }
      }
      this.$store.commit('setShowBindingBox', false);
    }
  }
};