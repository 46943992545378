import {
    API
} from '../api'
import store from '@/store/index.js'

const path = process.env.VUE_APP_BASE_API

export const SIGIN_API_URL = {
    LOGIN: `${path}/v1/auth/login`,
    COUPONS: `${path}/v1/user/coupons/page`,
    PRICEINFO: `${path}/v1/domain/priceInfo`,
    PRICEINFO_TLD: `${path}/v1/domain/tld/priceInfo`,
    SIGN_FOR_AUTION: `${path}/v1/domain/signForAuction`,
    SIGN_FOR_RENEWAL: `${path}/v1/domain/signForRenewal`,
    SIGN_TLD_FOR_RENEWAL: `${path}/v1/domain/signTldForRenewal`,
    SIGN_TLD: `${path}/v1/domain/signTld`,
    SIGN_INVITE_INFO: `${path}/v1/user/invite/info`,
    IS_TOS_AGREE: `${path}/v1/user/tos/isAgree`,
    TOS_AGREE: `${path}/v1/user/tos/agree`,
    IP_ADDRESS: `https://api.ipify.org`,
    IP_INFO: `https://qifu-api.baidubce.com/ip/local/geo/v1/district`,
}


export default {
    async login(params, conf) {
        return await API.loginPost(SIGIN_API_URL.LOGIN, params, conf)
    },

    async getCouponsList(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(SIGIN_API_URL.COUPONS, params, conf, false)
    },

    async getPriceInfo(params, conf) {
        if (!params.walletAddress && store.state.account) {
            params.walletAddress = store.state.account
        }
        return await API.post(SIGIN_API_URL.PRICEINFO, params, conf, false)
    },

    async getPriceInfoTld(params, conf) {
        if (!params.walletAddress && store.state.account) {
            params.walletAddress = store.state.account
        }
        return await API.post(SIGIN_API_URL.PRICEINFO_TLD, params, conf, false)
    },


    async signForAuction(params, conf) {
        return await API.post(SIGIN_API_URL.SIGN_FOR_AUTION, params, conf, true)
    },

    async signForRenewal(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(SIGIN_API_URL.SIGN_FOR_RENEWAL, params, conf, false)
    },

    async signTldForRenewal(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(SIGIN_API_URL.SIGN_TLD_FOR_RENEWAL, params, conf, false)
    },


    async signTld(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(SIGIN_API_URL.SIGN_TLD, params, conf)
    },



    async signInviteInfo(params, conf) {
        let account = store.state.account ? `/${store.state.account}` : ''
        if (!account) {
            return
        }
        return await API.get(SIGIN_API_URL.SIGN_INVITE_INFO + account, '', conf, false)
    },

    async getTosIsAgree(params, conf) {
        params.walletAddress = store.state.account
        return await API.post(SIGIN_API_URL.IS_TOS_AGREE, params, conf, false)
    },

    async tosAgree(params, conf) {
        return await API.post(SIGIN_API_URL.TOS_AGREE, params, conf, true)
    },

    async getIPAddress(params, conf) {
        return await API.get(SIGIN_API_URL.IP_ADDRESS, '', conf, false)
    },

    async getIPInfo(params, conf) {
        return await API.get(SIGIN_API_URL.IP_INFO, '', conf, false)
    },
}