import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-79989506"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "terms"
};
var _hoisted_2 = {
  "class": "terms-title"
};
var _hoisted_3 = {
  "class": "terms-desc"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  "class": "agree-terms"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");
  var _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_popup, {
    teleport: "body",
    show: $data.isShowTerms,
    "onUpdate:show": _cache[2] || (_cache[2] = function ($event) {
      return $data.isShowTerms = $event;
    }),
    round: "",
    onClickOverlay: $options.handleClose
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("query.service_terms")), 1), _createElementVNode("div", _hoisted_3, [_createTextVNode(_toDisplayString(_ctx.$t("query.terms_desc")) + " ", 1), _createElementVNode("span", {
        style: {
          "color": "#26a062",
          "cursor": "pointer"
        },
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$toUrl('/docs?docsName=TermsOfUse', 1);
        })
      }, _toDisplayString(_ctx.$t("query.terms_desc1")), 1), _createTextVNode(" " + _toDisplayString(_ctx.$t("query.terms_desc2")), 1)]), _createElementVNode("div", {
        "class": "flex-start",
        onClick: _cache[1] || (_cache[1] = function () {
          return $options.handleCheck && $options.handleCheck.apply($options, arguments);
        })
      }, [_createElementVNode("img", {
        "class": "check",
        src: $data.isAgree ? require('@/assets/image/inviteFriends/icon_choice.png') : require('@/assets/image/inviteFriends/icon_un_choice.png')
      }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("query.agree_terms")), 1)]), _createVNode(_component_el_button, {
        "class": "confirm",
        color: "#000000",
        disabled: !$data.isAgree,
        loading: $data.loading,
        onClick: $options.handleCommit
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("query.submit")), 1)];
        }),
        _: 1
      }, 8, ["disabled", "loading", "onClick"])])];
    }),
    _: 1
  }, 8, ["show", "onClickOverlay"])]);
}