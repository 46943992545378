import _objectSpread from "/root/.jenkins/workspace/prod-dapp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'callbackPupop',
  props: {},
  data: function data() {
    return {
      second: 3
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(['drawerShow', 'web3Success'])), {}, {
    drawerShow: function drawerShow() {
      var _this = this;
      if (this.$store.state.drawerShow) {
        this.second = 3;
        var time = setInterval(function () {
          _this.second--;
          if (_this.second == 0) {
            clearInterval(time);
            _this.$store.commit('setDrawerShow', false);
          }
        }, 1000);
      }
      return this.$store.state.drawerShow;
    },
    isMobile: function isMobile() {
      return this.$store.state.isMobile;
    }
  }),
  beforeCreate: function beforeCreate() {},
  mounted: function mounted() {},
  methods: {
    // store.commit('setDrawerShow',is_pupop)
    handleClosesBox: function handleClosesBox() {
      this.$store.commit('setDrawerShow', false);
    }
  }
};