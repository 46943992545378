import Domain from "@/api/modules/domain";
import {
  localStorage,
  setTimeLocalStorage
} from "@/store/storage";
import Signin from "@/api/modules/signin";
import {
  getTransferStatus
} from "@/utils/transferManager";
import {
  AUTION
} from "@/config/parameters.js"
import floatOPS from '@/utils/floatOPS'
import {
  floatingNumber,
  setPupopStatus,
  registerParams
} from '@/utils/utils'
import {
  getUsdToWeis,
  chainList,
  getChainGasPrice
} from "@/utils/connect";
import {
  getBalance,
  walletConnectDisAble
} from "@/utils/connect"
import moment from "moment";
import BigNumber from 'bignumber.js';
import {
  getChainList
} from "@/utils/chainInfo";
import MyTld from "@/api/modules/myTld"

export const mixin = {

  data() {
    return {
      priceList: [],
      invitationCode: "",
      registerLoading: false,
      is_shopCart: false,
      is_renew: false,
      is_renew_popup: false,
      is_rigister: false,
      gasPrice: 0,
      sessInvitationCode: '',
      inviteStatus: 1,
      inviteeDiscount: 1,
      inviteStatus: false,
      usdPrice: 0,
      gasTime: null,
      domainListArr: [],
      recaptchaVisible: false,
      googleToken: '',
      usdtPariceNum: 0,
      lockingStatus: false,
      paymentStatus: false,
      showInvite: true,
      balancewallect: '',
      dollarwallect: 0,
      usePriceExpen: '',
      is_tld: false,
      currencyId: '',
      currencySelect: {},
      registerPriceNum: 0,
      getBalanceOf: '',
      tokenNumber: '',
      usePriceExpenGas: '',
      gasPriceInit: '',
      gasPrimary: '',
      priceInitValue: 0
    };
  },
  watch: {
    chainid: {
      handler(to, from) {
        if (to || from) {
          if (this.account) {
            this.getBalanceOrg()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    choiceList(list, choice) {
      return function (list, choice) {
        let num = 0
        if (list) {
          list.forEach((item) => {
            if (item.registerPeriod != 1) {
              num = num + 1
            }
          })
          if (num > 0) {

          } else {
            return [{
                name: this.$t("timeName.year"),
                m_name: this.$t("timeName.m_year"),
                m_names: this.$t("timeName.m_years"),
                time: AUTION().VUE_APP_DURATION,
                value: "Y",
                type: 1,
                m_key: "year"
              },
              {
                name: this.$t("timeName.month"),
                m_name: this.$t("timeName.m_month"),
                m_names: this.$t("timeName.m_months"),
                time: 86400 * 30,
                value: "M",
                type: 2,
                m_key: "month"
              },
              {
                name: this.$t("timeName.week"),
                m_name: this.$t("timeName.m_week"),
                m_names: this.$t("timeName.m_weeks"),
                time: 86400 * 7,
                value: "W",
                type: 3,
                m_key: "week"
              },
              {
                name: this.$t("timeName.day"),
                m_name: this.$t("timeName.m_day"),
                m_names: this.$t("timeName.m_days"),
                time: 86400,
                value: "D",
                type: 4,
                m_key: "day"
              },
            ]
          }
        }

        return [{
            name: this.$t("timeName.year"),
            m_name: this.$t("timeName.m_year"),
            m_names: this.$t("timeName.m_years"),
            time: AUTION().VUE_APP_DURATION,
            value: "Y",
            type: 1,
            m_key: "year"
          },
          {
            name: this.$t("timeName.month"),
            m_name: this.$t("timeName.m_month"),
            m_names: this.$t("timeName.m_months"),
            time: 86400 * 30,
            value: "M",
            type: 2,
            m_key: "month"
          },
          {
            name: this.$t("timeName.week"),
            m_name: this.$t("timeName.m_week"),
            m_names: this.$t("timeName.m_weeks"),
            time: 86400 * 7,
            value: "W",
            type: 3,
            m_key: "week"
          },
          {
            name: this.$t("timeName.day"),
            m_name: this.$t("timeName.m_day"),
            m_names: this.$t("timeName.m_days"),
            time: 86400,
            value: "D",
            type: 4,
            m_key: "day"
          },
          {
            name: this.$t("timeName.buy_out"),
            m_name: '',
            value: "out",
            type: 5,
            m_key: "buy_out",
            time: 0,
          },
        ]
      }
    },
    disabledBtn(balancewallect, usePriceExpen) {
      return function (balancewallect, usePriceExpen) {
        if (this.currencyId == this.chainid || !this.currencyId) {
          if (balancewallect != '' && usePriceExpen != '') {
            if (Number(balancewallect) == 0) {
              return true
            }
            if (Number(usePriceExpen) >= Number(balancewallect)) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          if (this.getBalanceOf != '' && this.tokenNumber != '') {
            if (Number(balancewallect) == 0) {
              return true
            }
            if (Number(this.getBalanceOf) < Number(this.tokenNumber) || Number(this.gasPrimary) >= Number(balancewallect)) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        }

      }
    },
  },
  mounted() {},

  methods: {
    async getGasPrice(num, e) {
      try {
        if (num == 0) {
          this.gasPrice = 0
          this.usdPriceAdd()
          return true
        }
        let res = await getChainGasPrice(num, e)
        this.gasPriceInit = `${res}`
        this.usdPriceAdd()
        let gas = await getUsdToWeis(res)
        this.gasPrimary = gas
        this.usePriceExpenGas = `≈ ${gas}${this.getsSmbol()}`
        this.gasPrice = `${ this.gasPriceInit} ${this.usePriceExpenGas}`
        if (gas == 0) {
          this.usePriceExpenGas = ''
        }
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    getSupportName(item) {
      try {
        let json = getChainList()[this.chainid].nativeCurrency.symbol
        if (item.registerPayMethod) {
          if (item.registerPayMethod.length > 0) {
            item.registerPayMethod.forEach((items) => {
              if (items.chainId == this.chainid) {
                items.tokens.forEach((itm) => {
                  json = `${json}/${this.$store.state.getCfgTokenList[itm].tokenName}`
                })
              }
            })
          }
        }
        return json
      } catch (error) {

        console.log(error)
        return {}
      }
    },
    getBalanceOrg() {
      getBalance().then(res => {
        this.balancewallect = res.balance
      }).catch(err => {

      })
    },
    disabledSel(item, suffixes, e) {
      if (e) {
        if (item.type == 1 || item.allowSuffixes == '*') {
          return false
        } else {
          return true
        }
      }
      if (item.type == 2 && item.allowSuffixes != '*') {
        let arr = []
        if (item.allowSuffixes.indexOf(',') != -1) {
          arr = item.allowSuffixes.split(',')
        } else {
          arr = [item.allowSuffixes]
        }
        if (arr.indexOf(suffixes) == -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    setPrices(item) {
      if (!item.num || item.num == '') {
        item.num = 1
      }
      if (item.price != undefined && item.price != null && item.price != '') {
        let price = floatOPS.num(this.getChoiceVal(item), 0, 3);
        if (item.choice == "out") {
          return floatOPS.num(price);
        } else {
          if (item.choice == 'D' || item.choice == 'W') {
            return floatOPS.num(this.calculate(price, item.num, 3, 'm'), 0, 3)
          } else {
            return floatOPS.num(this.calculate(price, item.num, 3, 'm'), 0, 3)
          }

        }
      } else {
        return 0
      }

    },
    getChoiceVal(item) {
      const choice = item.choice;
      switch (choice) {
        case "Y":
          return item.price;
        case "M":
          return this.calculate(item.price, 12, 8, 'd');
        case "W":
          return parseFloat(this.calculate(this.calculate(item.price, 365, 3, 'd'), 7, 8, 'm'));
        case "D":
          return parseFloat(this.calculate(item.price, 365, 8, 'd'));
        case "out":
          return item.buyOut;
        default:
          return item.price;
      }
    },
    calculate(a, b, bit, fun) {
      let num = floatOPS[fun](a, b, bit)
      return num
    },
    getInvitationCode() {
      let sessInvitationCode = sessionStorage.getItem("invitationCode");
      if (sessionStorage.getItem("invitationCode")) {
        this.sessInvitationCode = sessInvitationCode;
        this.invitationCode = sessInvitationCode;

      }
    },

    async signInviteInfo() {
      try {
        if (this.inviteStatus) {
          return
        }
        this.inviteStatus = true
        let res = await Signin.signInviteInfo();
        this.inviteStatus = false
        // this.invitationCode
        if (res.inviteeDiscount) {
          if (this.is_shopCart) {
            this.couponList = []
          } else {
            this.couponList = ''
          }
          this.sessInvitationCode = res.inviteCode;
          this.invitationCode = res.inviteCode;
          this.inviteeDiscount = Number(res.inviteeDiscount);
          this.inviteStatus = 2;
          this.setPrice();
        } else {
          this.inviteStatus = 3;
          this.inviteeDiscount = 1
          this.nowActive = 2
          this.showInvite = false
        }
      } catch (error) {
        this.inviteStatus = false
        if (error.data.code == 4205) {
          this.inviteeDiscount = 1
          this.inviteStatus = 3;
          this.setPrice();
        }
      }
    },
    async getCoupons(e, status) {
      try {
        let json = {
          pageNo: 1,
          pageSize: 1000,
          status: status ? status : 1
        };
        let res = await Signin.getCouponsList(json);
        res.records.forEach((item, i) => {
          res.records[i].value = parseFloat(item.value);
        });
        this.priceList = res.records.map((item, i) => {
          return {
            id: item.id,
            value: Number(item.value).toFixed(2),
            label: item.name ? item.name + ' $' + item.value : this.$t('coupon.universal_voucher') + ' $' + item.value,
            name: item.name,
            gainAt: item.gainAt,
            type: item.type,
            startDate: item.startDate,
            endDate: item.endDate,
            allowSuffixes: item.allowSuffixes,
            showPupup: 1
          }
        });
        if (e) {
          this.$store.commit('setLoading', false)
        }
      } catch (error) {
        //console.log(error)
      }
    },
    async handleRegister(option) {
      let {
        arr,
        activeName,
        is_card,
        is_object,
        is_renew,
        is_renew_popup,
        is_rigister,
        isTld,
        price
      } = option
      this.domainListArr = arr
      this.registerPriceNum = price
      if (is_card) {
        this.is_shopCart = is_card

      }
      if (isTld) {
        this.is_tld = isTld
      }
      if (is_renew) {
        this.is_renew = is_renew
      }
      if (is_renew_popup) {
        this.is_renew_popup = is_renew_popup
      }
      if (is_rigister) {
        this.is_rigister = is_rigister
      }
      const self = this;
      try {
        if (this.currencyId == this.chainid || !this.currencyId) {
          this.registerLoading = true;
          this.lockingStatus = true
          self.sign(arr, activeName, is_object);
        } else {
          this.registerLoading = true;
          this.lockingStatus = true
          let res = await self.getCurrency()
          if (res) {
            self.sign(arr, activeName, is_object);
          } else {
            this.registerLoading = false;
            this.lockingStatus = false
          }
        }


      } catch (e) {
        this.registerLoading = false;
        this.lockingStatus = false
      }
    },
    getCount(num, pows) {
      num = new BigNumber(num)
      let big = new BigNumber(10).pow(pows)
      let bigNum = Number(new BigNumber(num.div(big)).toString())
      bigNum = parseFloat((parseInt(bigNum * 100000000) / 100000000).toFixed(8))
      return bigNum
    },
    async getCurrency() {
      try {
        let allowance, approval, outToken, registerVal, inToken
        if (this.currencyId == this.chainid || !this.currencyId) {} else {
          if (process.env.NODE_ENV != 'development') {
            let token_name = this.currencySelect.tokenName.toUpperCase()
            outToken = {
              chainId: this.chainid,
              address: this.currencySelect.contractAddr,
              decimals: this.currencySelect.tokenPrecision,
              symbol: this.currencySelect.tokenName,
              name: this.currencySelect.tokenName,
              feeAmount: parseFloat((parseInt(Number(this.currencySelect.feeRate) * 100000000) / 100).toFixed(2))
            }
            inToken = {
              chainId: this.chainid,
              address: this.currencySelect.tokenInAddr,
              decimals: this.currencySelect.tokenInPrecision,
              symbol: this.currencySelect.tokenIn,
              name: this.currencySelect.tokenIn,
            }
            if (this.chainid == '8453' && token_name == 'DEGEN') {
              registerVal = await MyTld.priceDegen()
            } else if (this.chainid == '8453' && token_name == 'BRETT') {
              registerVal = await MyTld.priceBrett()
            } else {
              registerVal = await this.$getSUID().quoteToken(inToken, outToken)
            }
            registerVal = this.getCount(registerVal, this.currencySelect.tokenPrecision)
            registerVal = parseFloat((parseInt(Number(this.registerPriceNum) * Number(registerVal) * 10000) / 10000).toFixed(4))
          } else {
            registerVal = this.registerPriceNum * 2
          }
          allowance = await this.$getSUID().currency(this.currencySelect.contractAddr, this.chainid, this.currencySelect.tokenPrecision).allowance(this.account)

          if (allowance < registerVal) {
            approval = await this.$getSUID().currency(this.currencySelect.contractAddr, this.chainid, this.currencySelect.tokenPrecision).approval(this.currencySelect.approvalTokenNum)

            if (approval.hash) {
              let dat = await getTransferStatus(approval.hash);
              return dat.status == 1 ? true : false
            }
          } else {
            return true
          }

        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async sign(arr, activeName, is_object) {


      try {
        let object = await registerParams(this, arr, this.invitationCode, activeName, is_object, this.googleToken, this.is_tld, this.is_renew, this.currencySelect)
        this.googleToken = ''
        let FunAction = () => {};

        if (this.is_renew) {
          if (this.is_tld) {
            FunAction = Signin.signTldForRenewal(object)
          } else {
            FunAction = Signin.signForRenewal(object)
          }

        } else {
          if (this.is_tld) {
            FunAction = Signin.signTld(object);
          } else if (this.isDutchAuction) {
            FunAction = Signin.signForAuction(object);
          } else {
            FunAction = Domain.sign(object);
          }
        }
        let res = await FunAction
        this.lockingStatus = false

        this.register(res);
      } catch (error) {
        this.googleToken = ''
        if (error.data && error.data.code == 4007) {
          this.setGoogleCaptcha()
        }
        this.registerLoading = false;
        this.lockingStatus = false
      }
    },

    setGoogleCaptcha() {
      this.recaptchaVisible = true;
      let that = this
      this.$nextTick(() => {

        window.grecaptcha.render("recaptcha", {
          sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          callback: function (token) {
            // 验证通过
            that.googleToken = token
            that.handleRegisterBox()
            that.recaptchaVisible = false;
          },
          "error-callback": (err) => {
            that.recaptchaVisible = false;
            that.googleToken = ''
            // that.handleRegisterBox()
            that.$showTip(that.$t(`message.recaptcha_error`), 'error', )
          },
          "expired-callback": () => {

          }
        })

        // let di = document.getElementById('recaptcha').children[0]
        // di.style.width = '8.3rem'
        // di.style.background = '#000'
        // di.style.overflow = 'hidden';
        // di.style.borderRadius = '0.4rem'
        // di.style.border = '1px solid #d3d3d3'

      })
    },

    async getUseNum(num) {
      try {
        if (this.currencyId == this.chainid || !this.currencyId) {
          let res = await getUsdToWeis(num)
          this.usePriceExpen = res
          this.usdPrice = `≈ ${res}${this.getsSmbol()}`
          if (res == 0) {
            this.usdPrice = ''
          }

        } else {
          this.priceInitValue = num
          this.getBalanceOf = await this.$getSUID().currency(this.currencySelect.contractAddr, this.chainid, this.currencySelect.tokenPrecision).balanceOf(this.account)

          let inToken = {
            chainId: this.chainid,
            address: this.currencySelect.contractAddr,
            decimals: this.currencySelect.tokenPrecision,
            symbol: this.currencySelect.tokenName,
            name: this.currencySelect.tokenName,
          }
          let registerVal, outToken
          if (process.env.NODE_ENV != 'development') {
            let token_name = this.currencySelect.tokenName.toUpperCase()
            outToken = {
              chainId: this.chainid,
              address: this.currencySelect.contractAddr,
              decimals: this.currencySelect.tokenPrecision,
              symbol: this.currencySelect.tokenName,
              name: this.currencySelect.tokenName,
              feeAmount: parseFloat((parseInt(Number(this.currencySelect.feeRate) * 100000000) / 100).toFixed(2))
            }
            inToken = {
              chainId: this.chainid,
              address: this.currencySelect.tokenInAddr,
              decimals: this.currencySelect.tokenInPrecision,
              symbol: this.currencySelect.tokenIn,
              name: this.currencySelect.tokenIn,
            }

            if (this.chainid == '8453' && token_name == 'DEGEN') {
              registerVal = await MyTld.priceDegen()
            } else if (this.chainid == '8453' && token_name == 'BRETT') {
              registerVal = await MyTld.priceBrett()
            } else {
              registerVal = await this.$getSUID().quoteToken(inToken, outToken)
            }
            registerVal = this.getCount(registerVal, this.currencySelect.tokenPrecision)
            registerVal = parseFloat((parseInt(Number(num) * Number(registerVal) * 10000) / 10000).toFixed(4))
          } else {
            registerVal = parseFloat((parseInt(Number(num * 2) * 1000000) / 1000000).toFixed(7))
          }
          this.tokenNumber = registerVal
          this.usdPrice = `≈ ${registerVal}${this.currencySelect.tokenName}`


        }
      } catch (error) {
        console.log(error)
      }


    },

    getsSmbol() {
      try {
        if (this.$store.state.chainid) {
          return chainList[this.$store.state.chainid].nativeCurrency.name
        } else {
          return ''
        }
      } catch (error) {
        return ''
      }
    },
    setRegisterParmes(domainListArr) {
      let expired = domainListArr.periodType == 5 || domainListArr.periodType == '0' ? 0 : ''
      if (domainListArr.periodType != 5 && domainListArr.periodType != '0') {
        let nowTime = (new Date()).valueOf()
        let format = this.$localFormat()
        expired = moment(nowTime + Number(domainListArr.num) * 31536000000).format(format)
      }
      let json = {
        name: domainListArr.name,
        is_free: domainListArr.periodType == 0,
        ownerAddress: this.account,
        managerAddress: this.account,
        domainStatus: 1,
        expired: expired,
        chainId: this.$store.state.chainid,
        status: 1
      }
      return json
    },

    async register(res) {
      let FunAction = () => {};
      if (this.currencyId == this.chainid || !this.currencyId) {
        if (this.is_renew) {

          FunAction = this.$getSUID().renew(res.signature, res.contents, res.feeDetails, res.payValue)
        } else {
          FunAction = this.$getSUID().register(res.signature, res.contents, res.commissions, res.payValue)
        }
      } else {
        if (this.is_renew) {

          FunAction = this.$getSUID().renewWithToken(res.signature, res.contents, res.feeDetails, res.payValue)
        } else {
          FunAction = this.$getSUID().registerWithToken(res.signature, res.contents, res.commissions, res.payValue)
        }
      }

      this.paymentStatus = true
      FunAction.then(async (resed) => {

          try {
            let dat = await getTransferStatus(resed.hash);
            this.paymentStatus = false
            if (this.is_tld) {
              let array = localStorage.getItem('tld_registrationCenter')
              let arr = {}
              arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = []
              if (this.is_rigister) {
                if (dat.status) {
                  if (this.$store.state.account && this.$store.state.chainid) {
                    if (array) {
                      array = JSON.parse(array)
                      arr = array
                      if (!arr[`${this.$store.state.account}_${this.$store.state.chainid}`]) {
                        arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = []
                      }

                      if (array[`${this.$store.state.account}_${this.$store.state.chainid}`]) {
                        arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = [...array[`${this.$store.state.account}_${this.$store.state.chainid}`]]
                      }

                    }
                    let json = this.setRegisterParmes(this.domainListArr)
                    arr[`${this.$store.state.account}_${this.$store.state.chainid}`].push(json)
                    localStorage.setItem('tld_registrationCenter', JSON.stringify(arr))
                  }
                  this.setRegisterStatus()
                  this.$router.push({
                    path: "/myTld"
                  });
                }
                this.$emit('handleClosesBox')
              }
            } else {
              let array = localStorage.getItem('registrationCenter')
              let arr = {}
              arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = []

              if (this.is_shopCart) {
                if (dat.status) {
                  if (this.$store.state.account && this.$store.state.chainid) {
                    if (array) {
                      array = JSON.parse(array)
                      arr = array
                      if (!arr[`${this.$store.state.account}_${this.$store.state.chainid}`]) {
                        arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = []
                      }
                      if (array[`${this.$store.state.account}_${this.$store.state.chainid}`]) {
                        arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = [...array[`${this.$store.state.account}_${this.$store.state.chainid}`]]
                      }

                    }
                    let arr1 = []
                    for (let i in this.domainListArr) {
                      let json = this.setRegisterParmes(this.domainListArr[i])
                      arr1.push(json)
                    }

                    arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = [...arr[`${this.$store.state.account}_${this.$store.state.chainid}`], ...arr1]
                    localStorage.setItem('registrationCenter', JSON.stringify(arr))
                    localStorage.setItem('showSuccessPopup', true)
                  }
                  localStorage.removeItem('shoppingCartList')
                  this.$store.commit("setShoppingCartNumber");
                }



                this.handleClosesBox()
              }
              if (this.is_rigister) {
                if (dat.status) {
                  if (this.$store.state.account && this.$store.state.chainid) {
                    if (array) {
                      array = JSON.parse(array)
                      arr = array
                      if (!arr[`${this.$store.state.account}_${this.$store.state.chainid}`]) {
                        arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = []
                      }

                      if (array[`${this.$store.state.account}_${this.$store.state.chainid}`]) {
                        arr[`${this.$store.state.account}_${this.$store.state.chainid}`] = [...array[`${this.$store.state.account}_${this.$store.state.chainid}`]]
                      }

                    }
                    let json = this.setRegisterParmes(this.domainListArr[0])
                    arr[`${this.$store.state.account}_${this.$store.state.chainid}`].push(json)
                    localStorage.setItem('registrationCenter', JSON.stringify(arr))
                    localStorage.setItem('showSuccessPopup', true)
                  }
                  this.setRegisterStatus()
                  this.$emit('successInfo', this.domain)
                }


                this.$emit('handleClosesBox')
              }
            }
            if (!this.is_renew) {
              if (dat.status == 1) {} else {
                setTimeout(() => {
                  setPupopStatus(true, false)
                }, 500)
              }

            } else {
              if (this.is_renew_popup) {
                this.$emit('parentTnit')
                this.$emit('handHideCard')

              } else {
                this.$emit('reInfo')
                this.handleClosesBox()

              }
              if (dat.status) {
                setTimeout(() => {
                  setPupopStatus(true, true)
                }, 500)
                this.$emit("handleClosesBox");
              } else {
                setTimeout(() => {
                  setPupopStatus(true, false)
                }, 500)
              }
            }

            this.registerLoading = false;
            return dat
          } catch (error) {
            //console.log(error)
            this.paymentStatus = false
            return ''
          }
        })
        .catch((err) => {
          //console.log(err,'err')
          this.$showContractErrorMessage(err, this.$t(`message.sys_error`), 'register')
          this.registerLoading = false;
          this.paymentStatus = false
        });
    },
  },
};