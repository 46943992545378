import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e405c9c6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "empty-desc"
};
var _hoisted_2 = {
  key: 1,
  "class": "empty-desc flex-center"
};
var _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");
  var _component_Connect = _resolveComponent("Connect");
  var _component_el_empty = _resolveComponent("el-empty");
  return _openBlock(), _createBlock(_component_el_empty, {
    style: {
      "position": "relative",
      "z-index": "100"
    },
    image: $props.image
  }, {
    description: _withCtx(function () {
      return [_ctx.account ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.emptyStr), 1)) : _createCommentVNode("", true), $props.toRegister ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString(_ctx.$t("ShoppingCart.empty1_desc1")), 1), _createElementVNode("div", {
        "class": "empty-register",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleQuery && $options.handleQuery.apply($options, arguments);
        })
      }, _toDisplayString(_ctx.$t("ShoppingCart.empty1_register")), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t("ShoppingCart.empty1_desc2")), 1)])) : _createCommentVNode("", true), _ctx.account && $props.haveBtn ? (_openBlock(), _createBlock(_component_el_button, {
        key: 2,
        color: "#000",
        "class": "empty-query",
        onClick: $options.handleQuery
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("img", {
            "class": "empty-search",
            src: require('@/assets/image/commom/empty_search.png')
          }, null, 8, _hoisted_3), _createTextVNode(" " + _toDisplayString($props.tabStatus == 1 ? _ctx.$t("favorite.query") : _ctx.$t("favorite.query1")), 1)];
        }),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true)];
    }),
    "default": _withCtx(function () {
      return [!_ctx.account ? (_openBlock(), _createBlock(_component_Connect, {
        key: 0
      })) : _createCommentVNode("", true)];
    }),
    _: 1
  }, 8, ["image"]);
}