import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0c83eb58"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "connect"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = ["src"];
var _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.$store.state.account || _ctx.$store.state.account == 'null' ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    "class": _normalizeClass(["connect-btn unconnect", $options.isMobile && !$props.isHeader ? 'mr0' : '']),
    type: "primary",
    onClick: $options.handleConnect
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("img", {
        "class": _normalizeClass(["wallet-img", $options.isMobile && !$props.isHeader ? 'mr10' : '']),
        src: require('@/assets/image/commom/icon_daohan.png')
      }, null, 10, _hoisted_2), _createTextVNode(" " + _toDisplayString($options.isMobile && $props.isHeader ? '' : _ctx.$t("header.connect")), 1)];
    }),
    _: 1
  }, 8, ["class", "onClick"])) : !$options.isMobile ? (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    "class": "connect-btn",
    type: "primary",
    onClick: $options.handleConnect
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("img", {
        "class": "head-img",
        src: $options.headerImg
      }, null, 8, _hoisted_3), _createTextVNode(" " + _toDisplayString($options.primaryName ? _ctx.$getAbbreviateLongName($options.primaryName, 15) : $options.account), 1)];
    }),
    _: 1
  }, 8, ["onClick"])) : $options.account ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    "class": "head-img",
    src: $options.headerImg,
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handleConnect && $options.handleConnect.apply($options, arguments);
    })
  }, null, 8, _hoisted_4)) : _createCommentVNode("", true)]);
}