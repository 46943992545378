import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a7fccbb8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "content"
};
var _hoisted_2 = {
  "class": "content-pop"
};
var _hoisted_3 = {
  "class": "path"
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  "class": "flex-start"
};
var _hoisted_6 = ["src"];
var _hoisted_7 = {
  "class": "flex-start"
};
var _hoisted_8 = ["src"];
var _hoisted_9 = {
  "class": "path-item flex-start"
};
var _hoisted_10 = ["src"];
var _hoisted_11 = {
  "class": "social flex-center"
};
var _hoisted_12 = ["src", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_popup = _resolveComponent("van-popup");
  var _component_van_picker = _resolveComponent("van-picker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: $data.show,
    "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
      return $data.show = $event;
    }),
    onTouchend: _cache[2] || (_cache[2] = function ($event) {
      return $data.show = false;
    }),
    zIndex: 500,
    position: "top"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.routerList, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          "class": "path-item fsb",
          key: item.name,
          onClick: function onClick($event) {
            return $options.handleClick(item);
          }
        }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
          "class": "icon-path",
          src: item.icon
        }, null, 8, _hoisted_6), _createTextVNode(" " + _toDisplayString(item.name), 1)])], 8, _hoisted_4);
      }), 128))]), _createElementVNode("div", null, [_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleSwitch && $options.handleSwitch.apply($options, arguments);
        }),
        "class": "path-item fsb"
      }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
        "class": "icon-path",
        src: require('@/assets/image/header/header_lan.png')
      }, null, 8, _hoisted_8), _createTextVNode(" " + _toDisplayString($options.language), 1)])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("img", {
        "class": "icon-path",
        src: require('@/assets/image/header/header_gas.png')
      }, null, 8, _hoisted_10), _createTextVNode(" " + _toDisplayString($props.gasPrice) + " Gwei ", 1)])]), _createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.socailList, function (item, index) {
        return _openBlock(), _createElementBlock("img", {
          "class": _normalizeClass(["social-icon", $data.socailList.length - 1 == index ? 'top-icon' : '']),
          style: _normalizeStyle(index != $data.socailList.length - 1 ? 'margin-right: 2.4rem' : ''),
          key: index,
          src: item.icon,
          onClick: function onClick($event) {
            return _ctx.$toUrl(item.url);
          }
        }, null, 14, _hoisted_12);
      }), 128))])])];
    }),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showPicker,
    "onUpdate:show": _cache[4] || (_cache[4] = function ($event) {
      return $data.showPicker = $event;
    }),
    round: "",
    position: "bottom"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_van_picker, {
        columns: $data.languageList,
        onCancel: _cache[3] || (_cache[3] = function ($event) {
          return $data.showPicker = false;
        }),
        onConfirm: $options.onConfirm,
        "cancel-button-text": _ctx.$t('publish.cancle'),
        "confirm-button-text": _ctx.$t('publish.confirm'),
        "columns-field-names": {
          text: 'label',
          value: 'value'
        }
      }, null, 8, ["columns", "onConfirm", "cancel-button-text", "confirm-button-text"])];
    }),
    _: 1
  }, 8, ["show"])]);
}