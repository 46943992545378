import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5d957c05"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "right-box"
};
var _hoisted_2 = {
  "class": "right-box"
};
var _hoisted_3 = {
  "class": "right-box"
};
var _hoisted_4 = {
  "class": "right-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _$props$currencySelec;
  var _component_el_skeleton_item = _resolveComponent("el-skeleton-item");
  var _component_el_skeleton = _resolveComponent("el-skeleton");
  return (_$props$currencySelec = $props.currencySelect) !== null && _$props$currencySelec !== void 0 && _$props$currencySelec.chainId ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": _normalizeClass(["price-box", $props.isDutchAuction ? 'mobile' : ''])
  }, [_createVNode(_component_el_skeleton, {
    "class": "ske-box ske-box1",
    loading: $options.isLoading($props.priceOg, 1),
    animated: ""
  }, {
    template: _withCtx(function () {
      return [_createVNode(_component_el_skeleton_item, {
        variant: "text",
        "class": "text"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("span", _hoisted_1, "$" + _toDisplayString($props.priceOg), 1)];
        }),
        _: 1
      })];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("span", _hoisted_2, "$" + _toDisplayString($props.priceOg), 1)];
    }),
    _: 1
  }, 8, ["loading"]), _createVNode(_component_el_skeleton, {
    "class": "ske-box",
    loading: $options.isLoading($props.usdPrice),
    animated: ""
  }, {
    template: _withCtx(function () {
      return [_createVNode(_component_el_skeleton_item, {
        variant: "text",
        "class": "text"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("span", null, _toDisplayString($props.usdPrice), 1)];
        }),
        _: 1
      })];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("span", null, _toDisplayString($props.usdPrice), 1)];
    }),
    _: 1
  }, 8, ["loading"])], 2)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    "class": _normalizeClass(["price-box", $props.isDutchAuction ? 'mobile' : ''])
  }, [_createVNode(_component_el_skeleton, {
    "class": "ske-box ske-box1",
    loading: $options.isLoading($props.price),
    animated: ""
  }, {
    template: _withCtx(function () {
      return [_createVNode(_component_el_skeleton_item, {
        variant: "text",
        "class": "text"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("span", _hoisted_3, "$" + _toDisplayString($props.price), 1)];
        }),
        _: 1
      })];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("span", _hoisted_4, "$" + _toDisplayString($props.price), 1)];
    }),
    _: 1
  }, 8, ["loading"]), _createVNode(_component_el_skeleton, {
    "class": "ske-box",
    loading: $options.isLoading($props.usdPrice),
    animated: ""
  }, {
    template: _withCtx(function () {
      return [_createVNode(_component_el_skeleton_item, {
        variant: "text",
        "class": "text"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("span", null, _toDisplayString($props.usdPrice), 1)];
        }),
        _: 1
      })];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("span", null, _toDisplayString($props.usdPrice), 1)];
    }),
    _: 1
  }, 8, ["loading"])], 2));
}