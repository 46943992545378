import Web3 from 'web3'
import store from '../store/index.js'
// eslint-disable-next-line no-unused-vars
import {
    EthereumProvider
} from "@walletconnect/ethereum-provider";
import {
    ethers
} from 'ethers'
import {
    default as ON
} from 'on-plus-js/dist/index'
import Signin from "@/api/modules/signin"
import {
    toRaw
} from 'vue'
import {
    localStorage,
    setTimeLocalStorage,
    setWithExpiry,
    getWithExpiry
} from "@/store/storage";
import {
    GetDevice,
    showTip,
    setWalletType,
    setWindowEthereum,
    cfxToWei,
    getUsdtToWei
} from "./utils.js"

import router from '@/router/index'
import BigNumber from 'bignumber.js';
import i18n from '@/language/i18n'
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import {
    getRpcUrlList,
    getChainList,
    switchChainIdList,
    getChainIds,
    getChainImages
} from "@/utils/chainInfo"

const ConfluxChain = [71, 1030]
const MntChain = [5000, 5003]
const FuseChain = [122, 123]
const DegenChain = [666666666]
const xLayerChain = [195, 196]
const beraChain = [80085]

const getUrl = async () => {
    let res = await getRpcUrlList()
    rpcUrlList = res
}
export let rpcUrlList = ''
const getChain = async () => {
    let res = await getChainList()
    chainList = res
}
export let chainList = ''
getUrl()
getChain()
const APP_LOGO_URL = require('@/assets/logo.svg')


var ethcallProvider = null
let initWalletFlag = false
let web3GetIdPromise = null
let metamaskTime = sessionStorage.getItem('metamaskTime') ? sessionStorage.getItem('metamaskTime') : null
let okxTime = sessionStorage.getItem('okxTime') ? sessionStorage.getItem('okxTime') : null
let isWallet = sessionStorage.getItem('isWallet') ? sessionStorage.getItem('isWallet') : null


export function getAccount() {
    return store.state.account
}

export function getChainid() {
    return store.state.chainid
}

export function getSUID() {
    if (store.state.suid) {
        return toRaw(store.state.suid)
    }

    let webProvider = null
    if (store.state.provider) {
        webProvider = new ethers.providers.Web3Provider(store.state.provider)
    }
    let suid = new ON({
        env: process.env.VUE_APP_BASE_NET,
        providerOrSigner: webProvider,
        rpcUrls: rpcUrlList
    })
    store.commit('setSuid', suid)
    return suid
}

let weiIndex = 0
/**
 * 获取Gwei并存缓存
 */
export async function getWei(e, chainid) {

    try {
        let res = ''
        if (ConfluxChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await cfxToWei()
        } else if (MntChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('priceMnt')
        } else if (FuseChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('fuseToWei')
        } else if (DegenChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            console.log(1, chainid, store.state.chainid)
            res = await getUsdtToWei('priceDegen')
        } else if (xLayerChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('priceXlayer')
        } else if (beraChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('priceBera')
        } else {
            console.log('测试，', chainid, store.state.chainid)
            res = await getSUID().getUsdToWei(chainid ? chainid : store.state.chainid)
            console.log(res)
        }

        if (res) {
            let wei = res.toString()
            setWithExpiry(`wei_chainid_${chainid?chainid:store.state.chainid}`, wei, 30)
            weiIndex = 0
            if (e == 1) {
                return res
            }
        } else {
            setTimeout(async () => {

                if (weiIndex < 5) {
                    let res = await getWei()
                    weiIndex = Number(weiIndex) + 1
                    console.log('获取Gwei缓存  重试', weiIndex)
                }
            }, 5000)
        }
    } catch (error) {
        console.log(error)
        setTimeout(async () => {

            if (weiIndex < 5) {
                let res = await getWei()
                weiIndex = Number(weiIndex) + 1
                console.log('获取Gwei缓存  重试error', weiIndex)
            } else {
                weiIndex = 0
            }
        }, 5000)
    }
}

/**
 * 获取Gwei报错重试
 */
let weiIndex1 = 0
export async function getWeiAsyanc(e, chainid) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = ''
            if (ConfluxChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
                res = await cfxToWei()
            } else if (MntChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
                res = await getUsdtToWei('priceMnt')
            } else if (FuseChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
                res = await getUsdtToWei('fuseToWei')
            } else if (DegenChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
                res = await getUsdtToWei('')
            } else if (xLayerChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
                res = await getUsdtToWei('priceXlayer')
            } else if (beraChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
                res = await getUsdtToWei('priceBera')
            } else {
                res = await getSUID().getUsdToWei(chainid ? chainid : store.state.chainid)
            }
            if (res) {
                let wei = res.toString()
                await setWithExpiry(`wei_chainid_${chainid?chainid:store.state.chainid}`, wei, 30)
                weiIndex1 = 0
                if (e == 1) {
                    resolve(res)
                }
            } else {
                setTimeout(async () => {
                    if (weiIndex1 < 5) {
                        await getWeiAsyanc(e, chainid)
                        weiIndex1 = Number(weiIndex1) + 1
                        console.log('获取Gwei缓存  重试weiIndex1', weiIndex1)
                    }
                }, 5000)
            }
        } catch (error) {
            setTimeout(async () => {
                if (weiIndex1 < 5) {
                    await getWeiAsyanc(e, chainid)
                    weiIndex1 = Number(weiIndex1) + 1
                    console.log('获取Gwei缓存  重试weiIndex1 error', weiIndex1)
                } else {
                    weiIndex1 = 0
                    reject()
                }
            }, 5000)
        }
    })

}

/**
 * 获取当前的Gwei
 */
export async function getWeiNow(chainid) {
    try {
        let res = getWithExpiry(`wei_chainid_${chainid?chainid:store.state.chainid}`)
        if (res) {
            return new BigNumber(res)
        } else {
            /** 
             * 获取Gwei报错用缓存，过期重新获取
             */
            let wei = await getWeiAsyanc(1, chainid ? chainid : store.state.chainid)
            return wei
        }
    } catch (error) {
        let res = ''
        if (ConfluxChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await cfxToWei()
        } else if (MntChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('priceMnt')
        } else if (FuseChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('fuseToWei')
        } else if (DegenChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('priceDegen')
        } else if (xLayerChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('priceXlayer')
        } else if (beraChain.indexOf(chainid ? chainid : store.state.chainid) != -1) {
            res = await getUsdtToWei('priceBera')
        } else {
            res = await getSUID().getUsdToWei(chainid ? chainid : store.state.chainid)
        }
        if (res) {
            return res
        } else {
            return 0
        }

    }
}

/**
 * 检查是否安装了MetaMask
 */
export function checkMetamask() {
    if (typeof window.ethereum !== 'undefined' || (typeof window.web3 !== 'undefined')) {
        return true
    } else {
        return false
    }
}

/**
 * 检查是否安装了欧易钱包
 */
export function checkOkxwallet() {

    if (typeof window.okxwallet !== 'undefined' || typeof window.okexchain !== 'undefined') {
        return true
    } else {
        return false
    }
}

/**
 * 检查是否安装了Nabox钱包
 */
export function checkNaboxxwallet() {

    if (typeof window.NaboxWallet !== 'undefined') {
        return true
    } else {
        return false
    }
}


// 连接metamask/okWallet
export async function okxwalletConnect(provider, type, switchChainId = null, e) {

    return new Promise(async (resolve, reject) => {
        provider.request({
            method: 'eth_requestAccounts'
        }).then(async (accounts) => {
            if (accounts.length == 0) {
                showTip(i18n.global.t(`message.no_account`), 'error')
                return
            }
            store.commit('setProvider', provider)
            store.commit('setProviderInit', provider)

            accounts = await new ethers.providers.Web3Provider(provider).listAccounts()
            if (provider.isTokenPocket && store.state.isMobile &&
                store.state.account && accounts[0] !== store.state.account) {
                logOut()
            }
            let webProvider = new ethers.providers.Web3Provider(provider)
            let suid = new ON({
                env: process.env.VUE_APP_BASE_NET,
                providerOrSigner: webProvider,
                rpcUrls: rpcUrlList
            })

            store.commit('setSuid', suid)
            let net = await webProvider.getNetwork()


            if (!store.state.provider) {
                store.commit('setProvider', provider)
                store.commit('setProviderInit', provider)
            }

            try {

                if (switchChainId) {
                    let res = await switchChain(switchChainId)
                    if (!res) {
                        // sessionStorage.removeItem('account');
                        // localStorage.removeItem('userinfo')
                        // localStorage.removeItem('tosId')
                        // store.commit('setChainid', '')
                        // store.commit('setAccount', '')
                        // store.commit('setAccessToken', '')
                        // store.commit('setPrimaryName', '')
                        resolve(false)
                        return
                    }
                }
                store.commit('setChainid', net.chainId)

                // 监听账号切换
                provider.on('accountsChanged', handleAccountsChanged)
                provider.on('chainChanged', handleChainChanged)
                provider.on('connect', handleConnectChanged)
                provider.on('disconnect', handleDisConnect)
                let connectPosition = store.state.connectPosition
                if (accounts.length === 0) {
                    store.commit('setAccount', '')
                    store.commit('setChainid', '')
                } else {
                    store.commit('setAccount', accounts[0])
                    if (connectPosition == 15 && store.state.provider) {
                        store.commit('setSignConnectCallBackHeader', false)
                        store.commit('setSignConnectCallBackHeader', true)
                    }

                }
                if ((connectPosition == 14 || connectPosition == 17 || connectPosition == 18 || connectPosition == 19) && store.state.provider) {
                    store.commit('setSignConnectCallBack', false)
                    store.commit('setSignConnectCallBack', true)
                }
                initWalletFlag = false
                resolve(true)
            } catch (error) {
                logOut()
                initWalletFlag = false
                reject(error)
            }

        }).catch(err => {
            console.log('connect.err==', err.message)
            if (err.message) {
                reject(err.message)
            } else {
                reject(err)
            }

        })
    })
}

export const signLogin = async () => {

    return new Promise(async (resolve, reject) => {
        try {
            let system = GetDevice()
            let message = await signer(store.state.account)

            let invitationCode = sessionStorage.getItem('invitationCode') || ''
            let channel_vip3 = sessionStorage.getItem('channel_vip3') || ''
            let json = {
                msg: store.state.account,
                signedMessage: message,
                chainId: store.state.chainid,
                ...system,
                inviteCode: invitationCode,
                walletType: store.state.walletTypeLogin
            }
            if (channel_vip3) {
                json.channel = 'vip3'
            }
            let token = await Signin.login(json)
            localStorage.setObject('userinfo', token.user)
            store.commit('setAccessToken', token.access_token)
            resolve(true)
        } catch (error) {
            console.log('signer=', error)

            reject(false)
        }
    })

}


export const getIpAddressInfo = async () => {

    return new Promise((resolve) => {
        Signin.getIPInfo().then(res => {
            if (res.code == 'Success') {
                let info = {
                    ip: res.ip,
                    ipDetail: JSON.stringify(res.data)
                }
                resolve(info)
            } else {
                Signin.getIPAddress().then(ip => {
                    let info = {
                        ip: ip,
                        ipDetail: ''
                    }
                    resolve(info)
                }).catch(error => {
                    let info = {
                        ip: '',
                        ipDetail: ''
                    }
                    resolve(info)
                })
            }

        }).catch(err => {
            Signin.getIPAddress().then(ip => {
                let info = {
                    ip: ip,
                    ipDetail: ''
                }
                resolve(info)
            }).catch(error => {
                let info = {
                    ip: '',
                    ipDetail: ''
                }
                resolve(info)
            })

        })
    })

}


export const logOut = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('account');
    localStorage.removeItem('userinfo')
    store.commit('setAccount', '')
    store.commit('setChainid', '')
    store.commit('setAccessToken', '')
    store.commit('setPrimaryName', '')
    store.commit('setLoading', false)
    if (localStorage.getItem('walletconnect')) {
        localStorage.removeItem('walletconnect')
    }
    localStorage.removeItem('emailList')
}

function handleChainChanged(chainId) {
    store.commit('setPrimaryName', '')
    store.commit('setChainid', parseInt(chainId, 16))
    store.commit('setProvider', null)
    if (!initWalletFlag) {
        initWalletFlag = true
        store.commit('setProvider', null)
        store.commit('setInitWalletFlag', false)
        initWallet()
    }

}

export async function getBalance() {
    return new Promise(async (resolve, reject) => {
        try {
            let provider = new ethers.providers.Web3Provider(store.state.provider)
            let account = await provider.listAccounts()
            let bal = await provider.getBalance(account[0])
            let balance = parseFloat(ethers.utils.formatEther(bal)).toFixed(4)

            let bigBalance = ethers.utils.parseEther(balance).toString()
            let res = await getWeiNow()
            bigBalance = new BigNumber(bigBalance)
            let usd = Number(new BigNumber(bigBalance.div(res)).toString())

            if (usd >= 0.1) {
                usd = usd.toFixed(2)
            } else if (usd < 0.01) {
                usd = usd.toPrecision(4)
            }
            resolve({
                balance,
                usd
            })
        } catch (err) {
            reject(err)
        }
    })
}

function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
        // 钱包已锁定或用户未连接任何帐户
        store.commit('setAccount', '')
        store.commit('setChainid', '')
        store.commit('setPrimaryName', '')
        sessionStorage.removeItem('isWallet')
        sessionStorage.removeItem('metamaskTime')
        sessionStorage.removeItem('okxTime')
    }
    // else if (accounts[0] !== currentAccount) {
    //     currentAccount = accounts[0]
    // }
    else {
        logOut()
    }
    if (!initWalletFlag) {
        initWalletFlag = true
        store.commit('setProvider', null)
        store.commit('setInitWalletFlag', false)
        initWallet()
    }
}

function handleConnectChanged(connectInfo) {}

function handleDisConnect(disconnect) {
    sessionStorage.removeItem('account');
    localStorage.removeItem('NaboxWallet')
}
export async function initWallet() {
    if (store.state.provider && store.state.account && store.state.chainid) {
        return Promise.resolve()
    } else {

        if (!store.state.initWalletFlag) {
            store.commit('setInitWalletFlag', true)
            web3GetIdPromise = new Promise(async (resolve, reject) => {

                let isOkxUnlocked = false,
                    isMetamaskUnlocked = false,
                    getIsMetamaskUnlocked = null,
                    getIsOkxUnlocked = null,
                    isNaboxlocked = false
                let isMetamask = checkMetamask()

                let isOkxWallet = checkOkxwallet()
                let isNaboxWallet = checkNaboxxwallet()
                setWindowEthereum()
                if (isMetamask && window.ethereum._metamask) {
                    try {
                        isMetamaskUnlocked = await window.ethereum._metamask.isUnlocked() //Metamask是否解锁

                        if (!sessionStorage.getItem('isMetamaskUnlocked')) {
                            sessionStorage.setItem('isMetamaskUnlocked', isMetamaskUnlocked)
                        }
                        getIsMetamaskUnlocked = eval(sessionStorage.getItem('isMetamaskUnlocked'))
                    } catch (error) {
                        console.log(error)
                    }
                }

                if (isOkxWallet && (window.okxwallet._metamask || window.okexchain._metamask)) {
                    try {

                        if (window.okxwallet) {
                            isOkxUnlocked = await window.okxwallet._metamask.isUnlocked() //欧易钱包是否解锁，true:已解锁
                        } else {
                            isOkxUnlocked = await window.okexchain._metamask.isUnlocked() //欧易钱包是否解锁，true:已解锁
                        }

                        if (!sessionStorage.getItem('isOkxUnlocked')) {
                            sessionStorage.setItem('isOkxUnlocked', isOkxUnlocked)
                        }
                        getIsOkxUnlocked = eval(sessionStorage.getItem('isOkxUnlocked'))
                    } catch (error) {
                        console.log(error)
                    }
                }
                if (isMetamaskUnlocked && isOkxUnlocked) {
                    if (metamaskTime && okxTime) {
                        if ((metamaskTime > okxTime && isOkxUnlocked == getIsOkxUnlocked) || (okxTime > metamaskTime && isMetamaskUnlocked != getIsMetamaskUnlocked)) {
                            isWallet = 'metamask'
                        } else {
                            isWallet = 'okx'
                        }
                    } else if (metamaskTime && okxTime == null) {
                        if (getIsMetamaskUnlocked && isOkxUnlocked == getIsOkxUnlocked) {

                            isWallet = 'metamask'
                        } else {

                            isWallet = 'okx'
                        }

                    } else if (metamaskTime == null && okxTime) {
                        if (getIsOkxUnlocked && isMetamaskUnlocked == getIsMetamaskUnlocked) {

                            isWallet = 'okx'
                        } else {

                            isWallet = 'metamask'
                        }
                    } else {

                        isWallet = 'metamask'
                    }
                } else {
                    if (isOkxUnlocked) {
                        isWallet = 'okx'
                    } else {
                        isWallet = 'metamask'
                    }
                }

                if (isWallet == 'metamask') {
                    sessionStorage.setItem('isWallet', 'metamask')
                    metamaskTime = new Date().getTime()
                    sessionStorage.setItem('metamaskTime', metamaskTime)
                } else {
                    sessionStorage.setItem('isWallet', 'okx')
                    okxTime = new Date().getTime()
                    sessionStorage.setItem('okxTime', okxTime)
                }
                sessionStorage.setItem('isMetamaskUnlocked', isMetamaskUnlocked)
                sessionStorage.setItem('isOkxUnlocked', isOkxUnlocked)

                if (window.imToken || window.hbWallet) {
                    if (isWallet == null || (isWallet == 'metamask' && metamaskTime > okxTime)) {
                        okxwalletConnect(window.ethereum, 'metamask').then(() => {
                            resolve()
                        }, error => {
                            reject(error)
                        })
                    } else {
                        okxwalletConnect(window.okxwallet, 'okx').then(() => {
                            resolve()
                        }, error => {
                            reject(error)
                        })
                    }
                } else {
                    let walletType = localStorage.getItem('walletType')
                    if (localStorage.getItem('walletconnect')) {
                        walletConnect().then(() => {
                            initWalletFlag = false
                            resolve()
                        }, error => {
                            initWalletFlag = false
                            reject(error)
                        })
                    } else if (localStorage.getItem('walletConnectCoinbase')) {
                        walletConnectCoinbase().then(() => {
                            initWalletFlag = false
                            resolve()
                        }, error => {
                            initWalletFlag = false
                            reject(error)
                        })
                    } else if (localStorage.getItem('NaboxWallet')) {
                        okxwalletConnect(window.NaboxWallet, 'metamask').then(() => {
                            resolve()
                        }, error => {
                            reject(error)
                        })
                    } else {
                        if (isWallet == null || (isWallet == 'metamask' && metamaskTime > okxTime)) {
                            okxwalletConnect(window.ethereum, 'metamask').then(() => {
                                resolve()
                            }, error => {
                                reject(error)
                            })
                        } else if (isNaboxWallet && window.NaboxWallet) {
                            okxwalletConnect(window.NaboxWallet, 'metamask').then(() => {
                                localStorage.setItem('NaboxWallet', true)
                                resolve()
                            }, error => {
                                reject(error)
                            })
                        } else {
                            okxwalletConnect(window.okxwallet, 'okx').then(() => {
                                resolve()
                            }, error => {
                                reject(error)
                            })
                        }
                    }
                }
                if (window.BinanceChain) {
                    // const { signature } = await window.BinanceChain.bnbSign(account, message)
                }
            })
        }
        return web3GetIdPromise
    }
}


let walletConnectProvider = undefined;

// 1. Define constants
const projectId = process.env.VUE_APP_WALLETCONNECT_ID;
if (!projectId) {
    throw new Error("You need to provide VITE_PROJECT_ID env variable");
}

/**
 * walletConnect连接
 */
export async function walletConnect(e, callback) {

    try {

        if (!walletConnectProvider) {
            let connectChians = e ? [parseInt(e)] : process.env.VUE_APP_RUNTIME_ENV === 'production' ? [1] : [5]
            walletConnectProvider = await EthereumProvider.init({
                projectId,
                showQrModal: true,
                qrModalOptions: {
                    themeMode: "light"
                },
                chains: connectChians,
                optionalChains: getChainIds(),
                rpcMap: rpcUrlList,
                methods: ["eth_sendTransaction",
                    "eth_signTransaction",
                    "eth_sign",
                    "personal_sign",
                    "eth_signTypedData",
                    "wallet_switchEthereumChain",
                ],
                events: ["chainChanged", "accountsChanged", ],
                metadata: {
                    name: "OpenName",
                    description: "OpenName",
                    url: 'https://app.open.name',
                    icons: ["https://app.open.name/favicon.svg"],
                },
            });
        }
        if (!walletConnectProvider.connected || !walletConnectProvider.isWalletConnect || !walletConnectProvider.session) {
            await walletConnectProvider.connect();
        }
        store.commit('setProvider', walletConnectProvider)
        store.commit('setProviderInit', walletConnectProvider)
        sessionStorage.setItem('metamaskConnect', '')
        localStorage.setItem('walletconnect', true)
        localStorage.setItem('walletType', 'WalletConnect')

        let webProvider = new ethers.providers.Web3Provider(walletConnectProvider)
        let suid = new ON({
            env: process.env.VUE_APP_BASE_NET,
            providerOrSigner: webProvider,
            rpcUrls: rpcUrlList
        })
        store.commit('setSuid', suid)
        let net = await webProvider.getNetwork()
        store.commit('setChainid', net.chainId)

        let accounts = await webProvider.listAccounts()
        store.commit('setAccount', accounts[0])
        if (e) {
            let res = await switchChain(e)
            if (!res) {
                sessionStorage.removeItem('account');
                localStorage.removeItem('userinfo')
                localStorage.removeItem('tosId')
                store.commit('setAccount', '')
                store.commit('setChainid', '')
                store.commit('setAccessToken', '')
                store.commit('setPrimaryName', '')
            }
        }
        setWalletType(2)
        walletConnectProvider.on("accountsChanged", (accounts) => {
            if (accounts.length === 0) {
                // 钱包已锁定或用户未连接任何帐户
                store.commit('setAccount', '')
                store.commit('setChainid', '')
                store.commit('setPrimaryName', '')
                sessionStorage.removeItem('isWallet')
                sessionStorage.removeItem('metamaskTime')
                sessionStorage.removeItem('okxTime')
            } else {
                store.commit('setAccount', accounts[0])
                localStorage.setItem('walletconnect', true)
            }
        })

        walletConnectProvider.on("chainChanged", (chainId) => {
            store.commit('setChainid', parseInt(chainId, 16))
        })

        walletConnectProvider.on("session_event", (event) => {

        })

        // 断开链接
        walletConnectProvider.on("disconnect", (code, reason) => {
            logOut()
            localStorage.clearWalletConnectStorage()
            walletConnectProvider = undefined
        })

        callback(suid, accounts[0])
    } catch (error) {
        if (error.message && error.message.indexOf('Cannot convert undefined or null to object') != -1) {
            localStorage.clearWalletConnectStorage()
            walletConnectProvider = undefined
            walletConnect(e, callback)
        } else if (error.message && error.message.indexOf('Connection request reset. Please try again.') != -1) {
            localStorage.clearWalletConnectStorage()
            walletConnectProvider = undefined
        }
    }
}

/**
 * coinbase连接
 */
export async function walletConnectCoinbase(e, callback) {

    try {
        const APP_NAME = 'Open Name'
        let ethereum = new CoinbaseWalletSDK({
            appName: APP_NAME,
            appLogoUrl: APP_LOGO_URL,
            darkMode: false
        })
        walletConnectProvider = ethereum.makeWeb3Provider(rpcUrlList[e], e)
        // if (!walletConnectProvider.connected) {
        await walletConnectProvider.request({
            method: 'eth_requestAccounts'
        })
        // }
        store.commit('setProvider', walletConnectProvider)
        store.commit('setProviderInit', walletConnectProvider)
        sessionStorage.setItem('metamaskConnect', '')
        localStorage.setItem('walletConnectCoinbase', true)
        let webProvider = new ethers.providers.Web3Provider(walletConnectProvider)
        let suid = new ON({
            env: process.env.VUE_APP_BASE_NET,
            providerOrSigner: webProvider,
            rpcUrls: rpcUrlList
        })
        store.commit('setSuid', suid)
        let net = await webProvider.getNetwork()
        store.commit('setChainid', net.chainId)

        let accounts = await webProvider.listAccounts()
        store.commit('setAccount', accounts[0])
        if (e) {
            let res_date = await switchChain(net.chainId)
            if (!res_date) {
                sessionStorage.removeItem('account');
                localStorage.removeItem('userinfo')
                localStorage.removeItem('tosId')
                store.commit('setAccount', '')
                store.commit('setChainid', '')
                store.commit('setAccessToken', '')
                store.commit('setPrimaryName', '')
            }
        }
        setWalletType(3)
        walletConnectProvider.on("accountsChanged", (accounts) => {
            if (accounts.length === 0) {
                // 钱包已锁定或用户未连接任何帐户
                store.commit('setAccount', '')
                store.commit('setChainid', '')
                store.commit('setPrimaryName', '')
                sessionStorage.removeItem('isWallet')
                sessionStorage.removeItem('metamaskTime')
                sessionStorage.removeItem('okxTime')
            } else {
                store.commit('setAccount', accounts[0])
                localStorage.setItem('walletConnectCoinbase', true)
            }
        })

        walletConnectProvider.on("chainChanged", (chainId) => {
            store.commit('setChainid', parseInt(chainId, 16))
        })

        walletConnectProvider.on("session_event", (event) => {

        })

        // 断开链接
        walletConnectProvider.on("disconnect", (code, reason) => {
            logOut()
            localStorage.removeItem('walletConnectCoinbase');
            walletConnectProvider = undefined
        })
        return {
            suid: suid,
            accounts: accounts[0]
        }
    } catch (error) {
        if (error.message && error.message.indexOf('Cannot convert undefined or null to object') != -1) {
            localStorage.removeItem('walletConnectCoinbase');
            walletConnectProvider = undefined
            walletConnectCoinbase(e, callback)
        } else if (error.message && error.message.indexOf('Connection request reset. Please try again.') != -1) {
            localStorage.removeItem('walletConnectCoinbase');
            walletConnectProvider = undefined
        }
    }
}

export function walletConnectDisAble() {
    if (walletConnectProvider) {
        walletConnectProvider.disconnect()
        walletConnectProvider = undefined
        localStorage.clearWalletConnectStorage()
        if (localStorage.getItem('walletconnect')) {
            localStorage.removeItem('walletconnect')
        }
        if (localStorage.getItem('NaboxWallet')) {
            localStorage.removeItem('NaboxWallet')
        }
        if (localStorage.getItem('walletconnect')) {
            localStorage.removeItem('walletconnect')
        }

    }
}

/**
 * 切换链
 */

export async function switchChain(chainId, e) {
    try {
        let res = await store.state.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{
                chainId: ethers.utils.hexValue(chainId) // 目标链ID
            }]
        })
        store.commit('setChainid', chainId)
        if (!initWalletFlag) {
            initWalletFlag = true
            store.commit('setProvider', null)
            store.commit('setInitWalletFlag', false)
            if (e) {
                await initWallet()
            } else {
                initWallet()
            }
        }
        // if(res == null){
        //     return false
        // }
        return true
    } catch (err) {
        console.log('switchChain.err====', err)
        store.commit('setProvider', store.state.providerInit)
        store.commit("setConnectPosition", 0);
        store.commit("setRegisterItem", '');
        store.commit("setItemNum", '');
        // let type = localStorage.getItem('wallectTypeConnect')
        if (err.code == -32000) {
            showTip(i18n.global.t(`message.switch_err`), 'error')
        } else if (err.code === 1003) { //
            showTip(err.message, 'error')
        } else if (err.code === 4902 || err.code === -32603) {
            store.state.provider.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: ethers.utils.hexValue(chainId), // 目标链ID
                    chainName: chainList[chainId].chainName,
                    rpcUrls: chainList[chainId].rpcUrls, // 节点
                    nativeCurrency: chainList[chainId].nativeCurrency,
                    blockExplorerUrls: chainList[chainId].blockExplorerUrls,
                }]
            }).then(res => {
                store.commit('setChainid', chainId)
                if (!initWalletFlag) {
                    initWalletFlag = true
                    store.commit('setProvider', null)
                    store.commit('setInitWalletFlag', false)
                    initWallet()
                }
                return true
            }).catch(error => {
                console.log('addEthereumChain.error:', error)
                showTip(i18n.global.t(`message.add_chain_err`), 'error')
                return false

            })
        } else if (err.code === 4001) {
            return false
        } else {
            //console.log('switchChain.err:====', err)
        }
        return false
    }


}

export async function getTransferStatus(txHash) {
    let webProvider = new ethers.providers.Web3Provider(store.state.provider)
    const receipt = await webProvider.getTransactionReceipt(hash);
    return receipt
}

export function isSupportChain() {
    let chainid = store.state.chainid
    let chainids = getChainIds()
    return chainids.indexOf(chainid) > -1;
}
export function chainIdList() {
    return switchChainIdList()
}
export function chainIdListFilter() {
    let json = store.state.getChainsList
    let arr = switchChainIdList()
    arr = arr.map((item) => {
        if (json && json[item.chainId]) {
            item.sort = json[item.chainId].sort
        }
        return item
    })
    arr = arr.filter((item) => {
        let status = true
        if (json[item.chainId]) {
            if (json[item.chainId].showFlag == 1) {

            } else {
                status = false
            }
        } else {
            status = false
        }
        return status
    })
    arr = arr.sort((a, b) => Number(b.sort) - Number(a.sort));
    return arr
}
export async function getUsdToWeis(num, chainid) {
    return new Promise(async (resolve, reject) => {

        if (!store.state.chainid) {
            resolve()
            return
        }
        try {
            let res
            if (chainid) {
                res = await getWeiNow(chainid)

            } else {
                res = await getWeiNow()
            }
            let x = new BigNumber(num)
            let big = new BigNumber(x.times(res).toString())
            let n = getBigNumber(big)
            resolve(n)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })

}

export async function getWeisToUsd(num) {
    num = JSON.parse(JSON.stringify(num))
    return new Promise(async (resolve, reject) => {

        if (!store.state.chainid) {
            resolve()
            return
        }
        try {
            let res = await getWeiNow()
            let x = new BigNumber(num)
            let x1 = new BigNumber(10).pow(18)
            x = new BigNumber(x.times(x1))
            let big = new BigNumber(x.div(res)).toString()
            if (Number(big) >= 0.1) {
                big = (Number(big)).toFixed(2)
            } else if (Number(big) < 0.01) {
                big = (Number(big)).toPrecision(4)
            }
            resolve(big)
        } catch (error) {
            //console.log(error)
            reject(error)
        }
    })

}

export function getBigNumber(num) {
    let big = new BigNumber(10).pow(-18)
    let x = num
    let number = Number(new BigNumber(x.times(big)).toString())
    if (number >= 10) {
        return (number).toFixed(2)
    } else {
        return (number).toPrecision(4)
    }

}

/**
 *
 * @returns 签名
 */
export async function signer(data) {
    const provider = new ethers.providers.Web3Provider(store.state.provider);
    const signer = provider.getSigner();
    return new Promise(async (resolve, reject) => {
        try {
            const signMessage = await signer.signMessage(data);
            resolve(signMessage);
        } catch (error) {
            if (error.message.indexOf('User rejected') != -1) {
                showTip(i18n.global.t(`message.reject_error`), 'error')
            }
            reject({
                code: -1,
                msg: 'network error'
            });
        }
    })
}

/**
 * 
 * @returns register Gas
 */
let ethEvm = [1, 10, 8453, 59144, 1101, 204, 43114, 250, 1030, 137, 11155111, 11155420, 84532, 59140, 80001, 1442, 5611, 43113, 4002, 7000, 71, 5000, 5003, 122, 123, 59901, 44787, 3441006, 169, 42220, 1687, 168587773, 81457, 919, 34443, 666666666, 7777777, 195, 196, 80085]
const gaslimit = () => {
    return {
        1: {
            1: 280301,
            2: 398983,
            3: 521966,
            4: 583800,
            5: 665680,
            10: 1382900,
            20: 2710700
        },
        42161: {
            1: 1341172,
            2: 1478912,
            3: 1621760,
            4: 1817800,
            5: 1938100,
            10: 2663000,
            20: 5858600
        },
        56: {
            1: 243769,
            2: 375594,
            3: 510361,
            4: 643800,
            5: 779900,
            10: 1453800,
            20: 3198360
        },
        324: {
            1: 1903109,
            2: 2598339,
            3: 3329785,
            4: 3964028,
            5: 4783660,
            10: 8424100,
            20: 16948200,
        },
        1088: {
            1: 1903109,
            2: 2598339,
            3: 3329785,
            4: 3964028,
            5: 4783660,
            10: 8424100,
            20: 16948200,
        },
        534351: {
            1: 294316,
            2: 375326,
            3: 542814,
            4: 671858,
            5: 779596,
            10: 1382200,
            20: 2145000,
        },
        534352: {
            1: 294316,
            2: 375326,
            3: 542814,
            4: 671858,
            5: 779596,
            10: 1382200,
            20: 2145000,
        },
        97: {
            1: 243769,
            2: 375594,
            3: 510361,
            4: 643800,
            5: 779900,
            10: 1453800,
            20: 3198360
        },
        421614: {
            1: 1341172,
            2: 1478912,
            3: 1621760,
            4: 1817800,
            5: 1938100,
            10: 2663000,
            20: 5858600
        },
        280: {
            1: 1903109,
            2: 2598339,
            3: 3329785,
            4: 3964028,
            5: 4783660,
            10: 8424100,
            20: 16948200,
        },
    }
}

/**
 * 
 * @returns transfer Gas
 */
const transferGaslimit = () => {
    return {
        1: {
            1: 445064,
        },
        42161: {
            1: 1280026,
        },
        56: {
            1: 423861,
        },
        324: {
            1: 2507894,
        },
        1088: {
            1: 2507894,
        },
        534351: {
            1: 452826,
        },
        534352: {
            1: 452826,
        },
        97: {
            1: 423861,
        },
        421614: {
            1: 1280026,
        },
        280: {
            1: 2507894,
        },
    }
}

async function getGasNum(num, e) {
    let provider = new ethers.providers.Web3Provider(store.state.provider)
    let priceList = null
    if (ethEvm.indexOf(store.state.chainid) != -1) {
        priceList = gaslimit()[1]
    } else {
        priceList = gaslimit()[store.state.chainid]
    }
    let priceList1 = {
        1: 91000,
        2: 115000,
        3: 138000,
        4: 161000,
        5: 185000,
        10: 301000,
        20: 534000,
        30: 766000
    }


    let gasLimit = 0
    let list = priceList
    if (!e) {
        list = priceList1
    } else if (e == 'transfer') {
        let priceList2 = null
        if (ethEvm.indexOf(store.state.chainid) != -1) {
            priceList2 = transferGaslimit()[1]
        } else {
            priceList2 = transferGaslimit()[store.state.chainid]
        }
        list = priceList2
    }
    if (list[num]) {
        gasLimit = list[num]
    } else if (num > 5 && num < 10) {
        let n = (list[10] - list[5]) / 5
        let n1 = num - 5
        let num1 = n * n1
        gasLimit = list[5] + Number(num1)
    } else if (num > 10 && num < 20) {
        let n = (list[20] - list[10]) / 10
        let n1 = num - 10
        let num1 = n * n1
        gasLimit = list[10] + Number(num1)
    } else {
        let n = (list[30] - list[20]) / 20
        let n1 = num - 20
        let num1 = n * n1
        gasLimit = list[20] + Number(num1)
    }
    let chainid = store.state.chainid

    let baseFeePerGas = 0;
    let defaultPiorFee = new BigNumber(1500000000);
    let x = 0
    if (chainid == 324 || chainid == 280) {
        defaultPiorFee = new BigNumber(250000000);
    }
    if (chainid == 56 || chainid == 97 || chainid == 534352 || chainid == 534351) {
        let price = await provider.getGasPrice()
        defaultPiorFee = price;
    }
    if (chainid == 56 || chainid == 97 || chainid == 324 || chainid == 280 || chainid == 534352 || chainid == 534351 || chainid == 1088 || chainid == 59901) {
        defaultPiorFee = new BigNumber(defaultPiorFee.toString());
        x = defaultPiorFee
    } else if (chainid == 42161 || chainid == 421614) {
        let limit = await provider.getBlock()
        baseFeePerGas = (limit.baseFeePerGas).toString();
        x = new BigNumber(baseFeePerGas)
    } else if (chainid == 1442 || chainid == 1101 || chainid == 71 || chainid == 1030 || chainid == 5000 || chainid == 5003 || chainid == 122 || chainid == 123 || chainid == 8453 || chainid == 84532 || chainid == 195 || chainid == 196) {
        let price = await provider.getGasPrice()
        x = new BigNumber(price.toString())
    } else if (chainid == 59140 || chainid == 59144) {
        let limit = await provider.getBlock()
        defaultPiorFee = new BigNumber(100000000);
        baseFeePerGas = (limit.baseFeePerGas).toString();
        x = new BigNumber(baseFeePerGas).plus(defaultPiorFee)
    } else {
        let limit = await provider.getBlock()
        baseFeePerGas = (limit.baseFeePerGas).toString();
        x = new BigNumber(baseFeePerGas).plus(defaultPiorFee)
    }

    const basePiorfee = new BigNumber(x.times(gasLimit));
    return new BigNumber(basePiorfee)
}

export async function getChainGasPrice(num, e, value, chainid) {
    return new Promise(async (resolve, reject) => {
        try {
            if (store.state.provider) {

                let res = await getGasNum(num, e)
                let big = new BigNumber(10).pow(-18)
                let res1
                let x = new BigNumber(res.toString())
                if (e == 'transfer') {
                    res1 = await getWeiNow(chainid)
                    value = new BigNumber(value)
                    x = new BigNumber(x.plus(value))
                } else {
                    res1 = await getWeiNow()
                }
                let big1 = new BigNumber(x.div(res1).toString())

                if (Number(big1.toString()) < 0.01) {
                    big1 = Number(big1.toString()).toPrecision(1)
                } else {
                    big1 = Number(big1.toString()).toFixed(2)
                }
                if (!big1 || big1 == 'NaN') {
                    big1 = 0
                }
                resolve(big1)
            } else {
                resolve(0)
            }
        } catch (error) {
            console.log(error)
            reject(0)
        }
    })
}