export default {
    message: {
        input_placeholder: "입력해주세요",
        email_placeholder: "이메일 주소를 입력해주세요",
        title_placeholder: "Enter title",
        content_placeholder: "Enter content",
        email_err: "올바른 이메일 주소를 입력해주세요",
        select_err: '선택해주세요',
        input_err: '입력해주세요',
        address_placeholder: "주소를 입력해주세요",
        address_error: '올바른 주소를 입력해주세요',
        balance_error: '귀하의 잔액은 수수료를 지불하기에 부족합니다.',
        owner_error: '이 도메인 이름을 소유하고 있지 않습니다',
        unsupport_chain: '지원되지 않는 체인입니다. 호환 가능한 체인으로 전환하시겠습니까?',
        no_account: '월렛에 계정이 없습니다',
        sys_error: '시스템 오류',
        recaptcha_error: 'Google reCAPTCHA 오류, 나중에 다시 시도해주세요',
        reject_error: 'User rejected',
        net_error: '네트워크 오류',
        switch_err: '현재 지갑은 네트워크 전환을 지원하지 않습니다. 지갑 앱을 업그레이드해주세요.',
        add_chain_err: '전환 실패했습니다. 수동으로 네트워크를 추가해보세요. ',
        err_1001: 'You do not own this TLD',
        err_4000: '매개변수가 잘못되었습니다',
        err_4100: '도메인 이름을 등록할 수 없습니다',
        err_4101: '잘못된 내용입니다. 다시 입력해주세요.',
        err_4102: '입력한 도메인 이름이 길이 제한을 초과했습니다. 다시 입력해주세요',
        err_4103: '등록되지 않은 도메인 이름입니다',
        err_4104: '도메인 이름이 존재하지 않습니다',
        err_4105: '이 도메인 이름은 이미 등록되어 있습니다',
        err_4106: '이 도메인 이름은 이미 다른 체인에서 등록되어 있습니다',
        err_4107: '네트워크 오류입니다. 새로고침 후 다시 시도해주세요.',
        err_4108: '도메인 이름이 아직 해제되지 않았습니다. 다시 입력해주세요',
        err_4109: '현재 입력한 도메인 이름은 등록을 지원하지 않습니다. 다시 입력해주세요',
        err_4110: '현재 입력한 도메인 이름은 등록을 지원하지 않습니다. 다시 입력해주세요',
        err_4111: '귀하의 주소는 커뮤니티 규정을 위반하며 도메인 이름을 등록할 권한이 없습니다',
        err_4112: '도메인 이름이 경매 기간에 있습니다',
        err_4113: '도메인 이름이 경매 기간에 있지 않습니다',
        err_4114: '지원되지 않는 TLD입니다',
        err_4115: '현재 입력한 도메인 이름은 등록을 지원하지 않습니다. 다시 입력해주세요',
        err_4116: '첫 등록은 1년 주기로 이루어져야 합니다',
        err_4117: '일괄 등록된 도메인 이름의 최대 개수를 초과했습니다',
        err_4118: '초대 코드와 상품권을 동시에 사용할 수 없습니다',
        err_4119: '상품권이 존재하지 않습니다',
        err_4120: '잘못된 상품권입니다',
        err_4121: '상품권이 이미 사용되었습니다',
        err_4122: '도메인 이름이 이미 구매되어 갱신이 필요하지 않습니다',
        err_4123: '도메인 이름이 마이그레이션 중이며 작업이 거부되었습니다',
        err_4124: '현재 선택한 상품권은 잠겨 있으며 5분 후에 사용할 수 있습니다',
        err_4125: '도메인 이름 접미사가 아직 열리지 않았습니다',
        err_4126: '현재 다른 사람이 도메인을 등록하고 있습니다. 5분 동안 잠급니다. 나중에 다시 시도 해주십시오.',
        err_4127: '현재 체인에서는이 도메인 이름을 등록 할 수 없습니다.',
        err_4128: '이 도메인 이름은 현재 선택한 대상 체인으로 이전 할 수 없습니다.',
        err_4201: '이 주소는 이 시스템에 등록되지 않았습니다',
        err_4202: '이메일 인증 코드 검증에 실패했습니다',
        err_4203: '이메일 인증 코드가 만료되었습니다',
        err_4204: '잘못된 작업입니다',
        err_4205: '초대 코드가 존재하지 않습니다',
        err_4206: '환급 구성이 구성되지 않았습니다',
        err_4207: '시스템 구성 오류: 이메일 관련 구성을 찾을 수 없습니다',
        err_4301: '행사가 존재하지 않습니다',
        err_5000: '알 수 없는 오류',
    },

    header: {
        project_name: 'OpenName',
        name_fi: 'NameFi',
        register: '등록',
        query: '조회',
        my_domain: '내 도메인',
        pricing: '가격 책정',
        invite: '초대',
        activities: '활동',
        collect: '즐겨찾기',
        other: '기타',
        connect: "연결",
        not_logged_in: '먼저 월렛을 연결해주세요',
        show_all: '모든 결과 표시 >',
        search_placeholder: '도메인 또는 주소 검색',
    },

    connectWallet: {
        connect_title: '월렛 연결',
        connect_desc: '여러 공개 블록체인에서 도메인 이름을 등록하고 사용할 수 있습니다.',
        select_network: '네트워크 선택',
        select_wallet: '연결된 월렛',
        connect_to_ethereum: 'Ethereum에 연결',
        connect_to_arbitrum: 'Arbitrum에 연결',
        connect_to_optimism: 'OP Mainnet에 연결',
        connect_to_polygon: 'Polygon에 연결',
        connect_to_bnb: 'BNB 체인에 연결',
        connect_to_zksync: 'zkSync Era에 연결',
        connect_to_base: 'Base Mainnet에 연결',
        connect_to_goerli: 'Goerli에 연결',
        connect_to_arbitrum_goerli: 'Arbitrum Goerli에 연결',
        connect_to_optimism_goerli: 'Optimism Goerli에 연결',
        connect_to_mumbai: 'Mumbai에 연결',
        connect_to_bnb_test: 'BNB Test에 연결',
        connect_to_zksync_test: 'zkSync Era Test에 연결',
        connect_to_base_test: 'Base Testnet에 연결',
        connect_wallet: '연결된 월렛',
    },

    footer: {
        project_name: '프로젝트 이름',
        privacy_policy: '개인정보 처리방침',
        user_agreement: '이용약관',
        copy_right: 'OpenName'
    },

    publish: {
        cancleLembrete: '알림 취소',
        cancle: '취소',
        confirm: '확인',
        close: '닫기',
        submit: '제출',
        delete: '삭제',
        set: '설정',
        expiration_time: '만료까지',
        remove_favorite: '즐겨찾기 제거',
        coll_success: '수집 성공',
        copy_success: '복사 완료!',
        copy_fail: '복제 실패',
        copy_address: '복사',
        download_success: '다운로드 성공',
        network_error: '이 네트워크는 지원되지 않습니다',
        prompt: '알림',
        successful: '성공!',
        failed: '실패!',
        save: '저장',
        copy_link: '링크 복사',
        unsupported_chain: '지원되지 않는 체인입니다. 호환 가능한 체인으로 전환하시겠습니까?',
        switch_chain: '체인 전환',
        wallect_insufficient: '잔액이 부족합니다',
        creation: '생성',
        year: '/년',
        buyout: '/종생',
        show_more: '  덜 보이기',
        show_less: '더 보이기',
    },

    query: {
        query_title: '당신의 ',
        query_title1: '좋아하는',
        query_title2: ' Web3 ',
        query_title3: '도메인 이름',
        query_title4: '또는',
        query_title5: 'TLDs',
        query_placeholder: '이름 또는 주소, 예: i.love.you',
        select_cancle: '접미사 선택 취소',
        query: '검색',
        opened_suffix: '열린 접미사',
        more_opening_suffix: '추가 접미사가 곧 열릴 예정입니다',
        query_price: '접미사 가격 목록 조회 >',
        current_progress: '현재 개방 진행 상황',
        sub_progress1: '1. 순수한 아라비아 숫자, 영어 문자, 특수 기호 "-", 이모지만 지원됩니다.',
        sub_progress2: '2. 특수 기호 "-"는 시작 부분에서 사용할 수 없으며, 별도로 등록할 수 없으며 연속적으로 사용할 수 없습니다.',
        sub_progress3: '3. 도메인 이름을 등록할 때 TLD를 사용하십시오. 예를 들어,.abc입니다. 계층을 구분하기 위해“.”을 사용하십시오.',
        see_more: '더 많은 문자 집합 규칙 보기 &gt >',
        query_error: '입력한 도메인 이름이 잘못되었습니다. 입력한 도메인 이름에 TLD(예: .eth)가 포함되지 않거나 지원되지 않는 문자가 포함되어 있는지 확인하십시오.',
        registrable: '등록 가능',
        has_registered: '등록됨',
        available: '사용 가능',
        unavailable: '사용 불가능',
        grace_period: '유예 기간',
        btn_register: '등록',
        btn_view: '보기',
        search_result: '검색 결과',
        search_result1: '결과',
        registrable_only: '등록 가능한 도메인만 표시',
        hot_suffix: 'Hot suffix',
        new_suffix: 'New suffix',
        free_suffix: 'Free suffix',
        discount_suffix: ' Off',
        report: '신고',
        favorite: '즐겨찾기',
        reporting_type: '신고 유형을 선택하십시오',
        report_reason1: '인종 차별',
        report_reason2: '음란물 및 폭력적인 콘텐츠와 관련',
        report_reason3: '미성년자와 관련',
        report_reason4: '기타',
        report_supplementary: '보충 설명',
        report_email: '이메일 주소(선택 사항)',
        report_success: '신고 성공',
        submit: '동의하다',
        service_terms: '이용 약관',
        terms_desc: '사용을 시작하기 전에 플랫폼의 서비스',
        terms_desc1: '약관을',
        terms_desc2: '주의 깊게 읽어주세요.',
        agree_terms: '읽었으며 동의합니다',
        funny_name: '재미있는 이름',
        recently_registered: '최근 등록',
        premium: '프리미엄',
        year: '/년',
        buyout: '/종생',
        white_list_tips1: '부터',
        white_list_tips2: '까지, 모든 유료 접미사',
        white_list_tips3: '개를 등록할 수 있습니다.',
        white_list_tips4: '',
        white_list_tips5: '',
        white_list_tips6: '',
        white_list_tips7: '가 있는 도메인 이름에 대해, 첫 해에 무료로',
        white_list_part_tips2: '까지, 다음과 같은 유료 접미사가 있는 도메인 이름에 대해, 첫 해에 무료로',
        white_list_part_tips3: '개를 등록할 수 있습니다:',
        white_list_assign_tips2: '까지, 다음과 같은 도메인 이름이 등록을 위해 당신에게 독점적으로 예약되었습니다:',
        white_list_title: '화이트리스트 자격',
        white_list_search: '클릭하여 검색',
        except_tip: '이외에',
        is_un_support1: '이 접미사는',
        is_un_support2: '에서만 등록이 지원됩니다.',
        domain: '도메인 이름',
        domain_tld: 'TLDs',
        domain_tld_tips: `TLD (최상위 도메인)은 도메인 이름의 최상위 수준으로서 도메인 이름의 마지막 점 뒤에 따라옵니다. 예를 들어 도메인 이름 'thank.you'에서 '.you'가 TLD입니다.`,
        domain_tld_tips1: 'TLD를 등록하고 홍보하며 등록 수수료의 얻으세요.',
        domain_lab_tips: '연도: 등록비는 매년 지불됩니다.',
        domain_lab_tips1: '평생: 갱신 수수료 없이 일회성 결제.평생 유지됩니다.',
        domain_tld_pl: 'TLD 또는 주소를 검색합니다(예: .abc).',
        domain_white_tld: '다음 TLD는 귀하를 위해 예약되었으며 귀하의 등록으로 제한됩니다.',
        view_all: '모두 보기',
        tld_view_all: "원하는 TLD를 찾지 못하셨나요?",
        tld_view_all1: "🔍 검색해 보세요.",
        domain_registration: '🥳 최근 등록:',
        domain_g: '1 분',
        domain_xsq: '한 시간 전에',
        domain_fzq: '몇분 전에',
        domain_tq: '며칠 전에',

    },

    priceList: {
        search_placeholder: 'TLD 검색',
        price_table: '가격 책정',
        suffix: '접미사',
        sort: '등급',
        level_1_price: '일급 도메인',
        level_1_price_mobile: '일급 도메인',
        total_registrations: '총 등록',
        registrations: '총 등록',
        multistage_price: '다중 레벨 도메인',
        multistage_price_mobile: '다중 레벨 도메인',
        instructions: '사용 설명',
        free: '무료',
        pre_year: '1년 가격: $',
        buyout: '/종생',
        characters_price: '자:  $',
        tips_4_above: '4자 이상:$',
        characters: ' 성격 ',
        year: '/년에',
        header_tld: 'TLD',
        header_price: '가격',
        header_divide: '지분율',
        header_explain: '설명하다',
        defule: 'Custom TLDs',
        share_ratio_tips: 'OpenName 사용자는 자신의 TLD를 등록하고 도메인 이름 등록 비용의 일부를 얻을 수 있습니다.'
    },

    myDomain: {
        set_primary_name: '기본 이름 설정',
        no_domain_set: '아직 도메인 이름을 소유하지 않았습니다',
        wallet_address: '지갑 주소',
        bind_domain: '도메인 바인딩 (역방향 레코드)',
        bind_desc: '링크 전체에서 도메인 이름을 설정할 수 있도록 허용하여 계정 이름이 앱에서 주소와 동일한 위치에 표시되도록 합니다. 예를 들어, 지갑 주소로 DApp에 로그인하면 계정 이름이 사용자 이름으로 표시됩니다. 이 주소로 전달된 도메인 이름만 선택할 수 있다는 점에 유의하십시오. 이 주소로 전달된 도메인 이름만 선택할 수 있다는 점에 유의하십시오.',
        select_domain_placeholder: '변경된 주소로 해결된 도메인 이름을 선택하세요',
        none_domain: '아직 도메인 이름을 소유하지 않았거나 도메인 이름 주소 레코드가 해당 주소로 설정되지 않았습니다. 이미 이 주소로 전달된 도메인 이름만 선택할 수 있습니다.',
        delete_tip: '현재 도메인 이름이 해결되었습니다. 이 레코드를 삭제하시겠습니까?',
        my_domain: '내 이름',
        batch_renewal: '연장',
        expiration_reminder: '알림',
        grace_period_until: '유예 기간 종료일：',
        renewal: '연장',
        cross_chain_transfer: '브릿지',
        bridging: '브릿징 >',
        tips: '현재 도메인 이름은 무료 도메인 이름이 되며, 이 기간 동안 갱신을 위해 가스만 지불하면 됩니다. 영구적으로 도메인 이름을 소유할 수 있습니다. 바로 갱신하시겠습니까?',
        transfer_desc: '소유권이나 관리 권한을 다른 사람에게 이전하는 중이므로 주의하여 진행하십시오.',
        renewal_cycle: '연장 기간',
        renewal_time: '갱신 시간',
        transfer_placeholder: '사용 가능한 상품권 선택',
        success: '갱신 성공',
        setting_success: '설정 성공',
        setting_error: '구문 분석 실패',
        remove_success: '삭제 성공',
        remove_fail: '삭제 실패',
        bought_out_held: '종생',
        bought_out_held1: '종생',
        bought_out_held2: '',
        grace_period_to: '유예 기간 종료',
        about_to_expire: '만료 예정',
        free_suffix: '무료 접미사, 영구 보유',
        free_suffix1: '무료 접미사',
        free_suffix2: '영구 보유',
        bought_out: '매입 완료',
        week_4: '4주',
        week_3: '3주',
        week_2: '2주',
        owner: '소유자',
        manager: '매니저',
        all: '전체',
        permission: '권한',
        search_placeholder: '도메인 검색',
        select_all: '모두 선택',
        domainAll: '모든 도메인 보기',
        err_title: '알림',
        err_tip1: '죄송하지만, 도메인 이름 ',
        err_tip2: '',
        err_tip3: "이용약관",
        err_tip4: ' ',
        err_tip6: '은 시스템에서 민감한 용어에 대한 위반으로 삭제되었으며, 더 이상 아무도 등록할 수 없습니다. 보상으로 시스템에서 상품권을 발급했습니다. 궁금한 사항이 있으시면 고객 서비스팀에 문의하십시오.',
        err_tip5: '민감한 용어에 대해서는 다른 사람이 다시 등록할 수 없습니다. 보상으로 시스템에서 상품권을 발급해 드렸습니다. 궁금한 사항이 있으시면 고객 서비스팀에 문의해 주세요.',
        err_bottom: '상품권 보기 > ',
        registering: '등록 중',
        his_name: '그들의 이름',
        no_transfer: '유예 기간 동안에는 크로스 체인 전송이 지원되지 않습니다。',
        forbid_transfer: '크로스체인 전송이 금지되어 있습니다.',
        domain_loca_chain: "도메인 위치 체인",
        tld_loca_chain: "TLD가 위치한 체인：",
        domain_screen: "필터",
        domain_chain: "체인",
        domain_jurisdiction: "유형",
        domain_reset: "초기화"
    },

    setPrimaryName: {
        set_primary_name: '기본 이름 설정',
        set_desc: '다른 Dapp 간에 통합 식별자 또는 사용자 이름으로 사용할 기본 이름을 설정합니다. 설정한 후 언제든지 변경할 수 있습니다.',
        current: "현재",
        confirm_Settings: '설정 확인',
        release_setting: '기본 이름 설정 해제',
        release_tip: '기본 이름 설정을 해제하려고 합니다. 계속하시겠습니까?',
        set_tip1: '1. 도메인 이름을 주소로 지정하세요.',
        set_tip2: '2. 기본 이름 설정을 완료하세요.',
    },

    manager: {
        edit: '프로필 편집',
        set_primary: '기본 이름으로 설정',
        set_primary_desc: '다른 Dapp 간에 통합 식별자 또는 사용자 이름으로 사용할 기본 이름을 설정합니다. 설정한 후 언제든지 변경할 수 있습니다.',
        empty_desc: '아직 내용이 없습니다',
        forever: '영구',
        name_info: '권한',
        parent_name: '상위 도메인',
        owner: '소유자',
        manager: '매니저',
        transfer: '이전',
        modify: '수정',
        expiration_time: '만료',
        address: '주소',
        social_media: '소셜 미디어',
        website: '웹사이트',
        other: '기타',
        resolver: '리졸버',
        modify_manager: '매니저 수정',
        modify_desc: '관리 권한을 다른 사람에게 할당하고 있습니다(소유 권한은 유지됩니다). 주의하여 진행하십시오.',
        new_manager: '새 매니저:',
        transfer_registrant: '이전',
        transfer_desc: '도메인 이름의 소유자 권한을 변경하고 있습니다. 변경 후에는 계정을 영구적으로 제어할 수 없게 됩니다. 이 작업을 수행할 때 주의하십시오.',
        transfer_tld_desc: 'TLD 소유자 정보를 변경하고 있습니다. 변경 후에는 해당 TLD의 영구적인 통제 권한을 잃게 되며, 등록 수입이 새 소유자에게 이전됩니다. 조심하여 조작해주세요.',
        domain_name: '도메인',
        current_address: '현재 주소',
        receiver: '수신자:',
        edit_add: '+ 추가',
        edit_address_desc: '각 체인에 대한 수신 주소를 도메인 데이터에 추가할 수 있습니다. 친구가 자금을 보낼 때 수신자 입력란에 도메인 계정만 입력하면 됩니다.',
        edit_parser_desc: '* 파서는 주소, 텍스트 레코드 등과 같은 모든 레코드를 저장합니다. 변경하기 전에 파서가 어떻게 작동하는지 이해하는 것이 좋습니다.',
        edit_success: '편집 성공',
        edit_fail: '편집 실패',
        default_resolver: '기본 리졸버',
        input_parser_placeholder: '할인을 받으려면 코드를 입력하세요',
        transfer_success: '성공적으로 이체되었습니다',
        transfer_fail: '이체에 실패했습니다',
        modify_success: '성공적으로 수정되었습니다',
        modify_fail: '수정에 실패했습니다',
        transfer_self_err: '자기 자신에게 이체할 수 없습니다',
        modify_self_err: '자기 자신을 수정할 수 없습니다',
        date: '날짜',
        help_renewal: '도움말 연장',
    },

    transfer: {
        transfer_domain_name: '브릿지 도메인',
        transfer_domain_tld: '브릿지 TLD',
        domain: '도메인 이름',
        current_chain: '현재 체인',
        from: '원래의 체인 ',
        bridge_to: '브릿지 대상',
        transfer_tip1: '크로스 체인 전송은 가스 비용이 필요하며(체인의 실시간 가스 가격에 기반함) 한 번 시작되면 취소할 수 없습니다.',
        transfer_tip2: '전송 프로세스 중에 페이지를 나갈 수 있으며 "내 이름" 페이지에서 전송 상태를 확인할 수 있습니다.',
        transfer_tip2_tld: '전송 프로세스 중에 페이지를 나갈 수 있으며 "나의 TLD" 페이지에서 전송 상태를 확인할 수 있습니다.',
        chain_type: '체인 유형',
        chain_placeholder: '체인 선택',
        address: '주소',
        address_placeholder: '주소 입력',
        start_transfer: '브릿지 시작',
        bridging: '브릿징 중',
        finish_tip: '🎉 도메인 이름이 성공적으로 전송되었습니다. 체인과 지갑을 전환하여 도메인 이름을 확인하세요.',
        step_title: '세 단계',
        step_1: '1단계',
        step_1_desc: '현재 체인에서 도메인 이름 삭제',
        step_1_desc_tld: '현재 체인에서 TLD를 파괴하십시오.',
        step_1_finish: '블록체인 브라우저에서 세부 정보 확인하려면 >',
        step_2: '2단계',
        step_2_desc: 'LayerZero를 통해 크로스 체인 정보 전송',
        step_2_tip: '전송 프로세스는 몇 분 정도 소요될 수 있습니다.',
        step_3: '3단계',
        step_3_desc: '선택한 새로운 체인에서 도메인 이름 생성',
        step_3_desc_tld: '원하는 새로운 체인에 TLD를 발행하세요.',
        step_3_tip: '주문 프로세스가 오랜 시간 동안 완료되지 않으면 ',
        step_3_tip1: '고객 서비스에 문의하세요.',
        step_finish: '세부 정보는 LayerZero에서 확인하세요 >',
        step_wait: '대기 중',
        step_completed: '완료됨',
        transfer_fail: '전송 실패',
        bridge_success: '성공',
        bridge_success_desc: '도메인 이름이 성공적으로 브릿지되었습니다. 체인과 계정을 전환하여 도메인을 확인하세요.',
        bridge_success_tld: '당신의 TLD가 성공적으로 이전되었습니다. 보기 위해 체인 및 계정을 전환하세요.',
        powered_by: 'LayerZero 제공'
    },

    signin: {
        enquiry: '문의',
        registration: '등록',
        register: '등록',
        year: '년',
        m_year: '년',
        m_years: '년',
        tips: '초대 코드를 입력하세요',
        method: '등록 기간',
        number_year: '년 수',
        expense: '등록 수수료',
        invitation_code: '초대',
        coupon: '상품권',
        coupon_placeholder: '사용 가능한 상품권 선택',
        total_cost: '예상 총액',
        cost: '예상 비용',
        original_price: '원래 가격',
        buy_out: '종생',
        free: '무료',
        premium: '프리미엄',
        premium_main: '프리미엄 알림',
        premium_main_price: '알림 가격 입력',
        premium_tips: '프리미엄은 이 가격 시간에 도달합니다(현지 시간대)',
        nowPremium: '현재 프리미엄',
        introduce_title: '프리미엄 곡선',
        introduce_content_tip: '만료된 이름에 대한 공정한 등록 기회를 제공하기 위해 프리미엄은 1억 달러에서 21일 내에 0 달러로 점진적으로 감소합니다.',
        introduce_item_left: '현재 구매 가격은 ',
        introduce_item_right: ' 일 남음 (총 21일)',
        remind: '알림 ',
        free_tips: '이 도메인 이름은 영구적으로 무료로 등록되며 등록 시 가스 비용만 지불하면 됩니다.',
        number: '수량',
        not_sufficient_funds: '등록에 사용 가능한 잔액이 부족합니다',
        is_register: '도메인 이름 등록 중',
        hold_for_life: '영구 보유',
        target_date: '이 가격에',
        target_date_me: '이 가격 알림으로',
        code_error: '유효하지 않음',
        already_set: '이미 설정됨',
        register1: '1. 지갑 서명',
        register2: '2. 도메인 이름을 잠그십시오',
        register3: '3. 등록하려면 결제하세요',
        register4: '2. TLD 잠금',
        confirm3: '연장을 위한 결제',
        first_year_free: 'First year free',
        select_coupon: '바우처 선택',
        tips_rate: 'TLD를 성공적으로 등록하고 운영을 시작하면 도메인 이름 등록 수익의 일정 비율이 플랫폼에 분배됩니다.',
        select_ayment: '결제수단',
    },

    email: {
        emailError: '이메일 형식 오류',
        email_tips: '이메일 바인딩',
        email_code_ver: '바인딩된 이메일의 코드 입력',
        get_code: '코드 전송',
        band_tips: '알림을 받으려면 이메일을 바인딩하세요.',
        email_address: '이메일 주소를 입력하세요',
        email_new_address: '유효한 새 이메일 주소를 입력하세요',
        code_add: '인증 코드를 입력하세요',
        ver_code_err: '인증 코드 오류',
        pop_tips: '도메인 이름이 만료되기 30일 전에 주간 알림 이메일을 이메일 주소로 보내드립니다.',
        re_code: '재전송',
        emailEdit: '바인딩된 이메일 수정',
        currentMailbox: '현재 바인딩된 이메일 주소:',
        editTips: '바인딩된 이메일의 코드 입력',
        next: '다음',
        last_step: '이전',
        binding: '바인딩',
        un_binding: '바인딩 해제',
        reminder_email: '알림 이메일',
        success: '바인딩 성공',
        send_success: '전송 성공',
        email_error: '이메일은 중복될 수 없습니다',
        email_system_err: '이메일이 성공적으로 전송되지 않았습니다. 나중에 다시 시도해 주세요.'
    },

    ShoppingCart: {
        title: '대량 등록',
        close: '비우기',
        year: '년',
        m_year: '년',
        m_years: '년',
        method: '기간',
        buy_out: '종생',
        number_year: '년 수',
        invitation_code: '초대장',
        code_tips: '초대장 코드를 입력하세요',
        coupon: '상품권',
        coupon_placeholder: '여러 상품권을 선택할 수 있습니다',
        number: '수량',
        total_cost: '예상 총 비용',
        original_price: '원래 가격',
        register: '등록',
        tips: '초대장 코드를 입력하여 등록 비용을 할인받으세요',
        empty: '내용 없음',
        empty1: '아직 도메인 이름을 소유하고 있지 않습니다',
        empty2: '아직 TLD를 소유하지 않았습니다.',
        searh_empty: '검색 결과가 없습니다',
        empty1_desc1: '가 그리고',
        empty1_register: '\u00A0등록하다\u00A0',
        empty1_desc2: ' 지금 하나.',
        hold_for_life: '평생 소유',
        estimated_gas: '가스',
        estimated_gas_fee: '추정가스',
        discount: '',
        discount1: ' 할인',
        registered: '등록됨',
        unavailable: '사용 불가',
        unsupported_chain: '지원되지 않는 체인',
        card_limit: '최대 15개의 도메인을 추가할 수 있습니다',
        un_support_payments1: ' 이 도메인 이름은 지원되지 않습니다',
        un_support_payments2: '결제만 가능'
    },

    favorite: {
        favorites: '내 즐겨찾기',
        empty_desc: '아직 즐겨찾기한 도메인 이름이 없습니다. 지금 검색하여 즐겨찾기를 찾으세요',
        query: '이름 검색',
        empty_desc1: '아직 마음에 드는 TLD가 없습니다. 마음에 드는 TLD를 확인해보세요~',
        query1: 'TLD 쿼리',
        registration: '등록',
        registration_premium: '프리미엄을 지불하여 등록할 수 있습니다',
        unregistrable: '등록 불가',
        grace_period: '만료 기간',
        view: '보기',
    },

    coupon: {
        coupon_list: '상품권',
        coupon: '상품권',
        release_time: '배포',
        expired: '만료됨',
        employ: '사용됨',
        unused: '사용 가능',
        empty: '더 이상 상품권이 없습니다',
        expiration: "만료",
        permanently_valid: "영구적으로 유효함",
        available_suffixes: "사용 가능한 접미사",
        universal_voucher: "범용 바우처",
        tips_role: "사용 규칙: 도메인 이름을 등록할 때 상품권을 사용하여 해당 등록 비용을 차감할 수 있습니다"
    },

    inviteFriends: {
        title: '친구 초대 및 보상 받기',
        tips1: '다음 링크를 통해 친구를 초대하여 도메인 이름이나 TLD를 등록하세요. 등록이 성공하면 친구는',
        tips2: ' 할인',
        tips3: '을 받을 수 있고, 당신은 ',
        tips4: '등록 비용의 최대 ',
        tips5: '의 등록료를 받을 수 있습니다.',
        tips6: '얻다',
        invite_friends: '지금 초대하기',
        invitation_record: '초대 기록',
        time: '등록 시간',
        address: '초대받은 사람의 주소',
        chain: '등록 체인',
        reward: '보상',
        no_data: '기록 없음',
        pup_tips: '크로스 체인 도메인 이름 만들기',
        bonus_tips: '친구를 초대하고 보너스 받기:',
        apply_for: '더 높은 보상 신청하기 >',
        look_rule: '구체적인 규칙 보기 >',
        apply_success: '심사 신청 완료',
        data_overview: '초대 요약',
        data_ranking: '순위 목록',
        data_rank: '순위',
        data_address: '주소',
        data_invite_num: '초대 등록 횟수',
        data_address_num: '초대된 주소 수',
        data_award_num: '수상되었습니다',
        date_7: '7일',
        date_30: '30일',
        date_all: '모두',
        rebate_tips: '리베이트 비율 및 초대',
        role: '당신의 역할',
        rebate_ratio: '리베이트 비율',
        number_invited: '초대된 등록',
        individual_user1: '개인 사용자',
        individual_user2: 'KOL',
        individual_user3: '유통업자',
        individual_user4: '파트너',
        token_reward: '당신의 보상',
        token_tips: '보상은 해당 블록체인의 주소로 자동으로 이체되었습니다',
        rebate_ratio_tips: 'KOL/유통업자/파트너로 신청 시 25% 리베이트',
        invitation_link: '당신의 초대 링크',
        invitation_code: '당신의 초대 코드',
        registration_fee: '등록 비용',
        reward_earn: '당신이 얻은 보상',
        invite_account_unLogin: '내 초대장을 받으려면 지갑을 연결하세요',
        invite_friends_discount: '친구의 등록 할인',
        domain: '도메인',
        domains: '도메인',
        invited_friend: "초대된 친구 수",
        your_invitation: "당신의 초대",
        invitee_address: "초대 받은 사람 주소",
        invited_number: "총 등록된 도메인 수",
        invited_money: "총 등록 비용",
        invited_award: "당신의 초대 보상",
        invited_detail: "세부 내역",
        invited_register_detail: "등록 세부 내역",
        invited_register_domain: "도메인 이름",
        invited_register_chain: "등록된 체인",
        invited_register_fee: "등록 비용",
        invited_register_award: "보상",
        invited_register_time: "등록 시간",
        screen_shot: '초대 포스터를 저장하기 위해 스크린샷 찍기',
        tips_role: '목록의 기본 순위 가중치는 획득한 보상을 기반으로 합니다. 다른 순위를 보려면 필터 버튼을 클릭하여 다른 지표의 결과를 볼 수 있습니다.',
        no_rewards: '보상이 없습니다.',
        invite_1: '신청 기록이 있습니다',
        invite_2: '당신은 이미 KOL 상태입니다.',
        invite_3: '죄송합니다. 아직 신원 요구 사항을 충족하지 않았습니다.',
        invite_4: '신청 시작',
        invite_5: '지원자 상태',
        invite_6: '귀하의 소개',
        invite_7: '연락처 정보',
        invite_8: '커뮤니티 링크',
        invite_9: '검토중',
        invite_10: '24시간 이내에 연락드리겠습니다. 잠시만 기다려주세요.',
        invite_11: '감사 결과',
        invite_12: '실행 취소',
        invite_13: '승인되었습니다. 활동 KOL 상태를 축하합니다.',
        invite_14: '뒤로',
        invite_15: '귀하의 신청서가 승인되지 않아 매우 죄송합니다.',
        invite_16: '귀하의 정보가 신청 요구 사항을 충족하지 않아 통과할 수 없습니다. 죄송합니다. 올바른 정보를 다시 입력해 주십시오.'
    },

    userInfo: {
        my_account: '내 계정',
        my_domain: '내 도메인',
        my_tld: '내 TLD',
        my_collection: '즐겨찾기',
        my_coupon: '상품권',
        my_email: '이메일',
        switch_wallets: '지갑 전환',
        exit_wallet: '연결 해제',
        operation_record: '운영기록',
        revise: '수정',
    },

    applyToJoin: {
        apply_to_join: '더 높은 보상을 위해 신청하기',
        join_tip1: "👋 안녕하세요, 리베이트 스킴 신청 페이지에 오신 것을 환영합니다!",
        join_tip2: "만약 우리와 동의하고 도메인 이름 제품 홍보에 큰 관심이 있다면, 플랫폼의 초대 및 호출 프로그램에 참여하도록 초대합니다: 친구를 초대하여 도메인 이름을 등록하면 최대 25%의 거래 리베이트를 즐길 수 있습니다~",
        join_tip3: '또한, 우리의 초대 프로그램은 사업의 발전과 함께 확장될 것입니다. 기대해 주세요. 😊',
        apply: '어떤 역할로 신청하시겠습니까',
        text1: 'KOL (소셜 미디어 KOL, 암호화폐 업계의 의견 리더 등)',
        text2: '유통업자 (트래픽을 보유하거나 트래픽을 확보할 수 있는 개인/기관)',
        text3: '파트너 (NFT 프로젝트 측, 공공체인 프로젝트 측, 기관 등)',
        your_introduction: '자기 소개',
        please_enter: '입력해주세요',
        social_media: '소셜 미디어',
        link: '커뮤니티/공식 웹사이트 링크',
        contact: '연락처 정보 (이메일/텔레그램/디스코드)',
        email: '이메일 주소',
        wallet: '지갑 주소 (수정 가능)',
        wallet_tip: '연락처 정보 (텔레그램/디스코드/이메일/트위터)',
        submit: '신청서 제출',
        role: '역할',
        reward_ratio: '보상 비율',
        kol: 'KOL',
        registrar: '유통업자',
        partner: '파트너',
        enter: '필수 입력 사항입니다. 내용을 입력해주세요.',
        tip1: 'OpenName에서 좋아하는 도메인 이름을 등록했습니다. 와서 한 번 시도해보세요!',
        tip2: '크로스체인 도메인 이름을 만들어보세요'
    },

    renew: {
        name_renewal_fee: '이름 연장',
        expiration_time: '만료까지',
        renewal_fee: '연장 수수료',
        cancel: '취소',
        renewal: '연장',
        renewal_cycle: '연장 기간',
        hold_for_life: '평생 소유',
        reconfirm: '연장 확인',
        selected: '선택됨',
        selected_tld: '선택됨',
    },

    tldRenew: {
        name_renewal_fee: 'TLD 갱신',
    },

    timeName: {
        year: '년',
        month: '개월',
        week: '주',
        day: '일',
        buy_out: '종생',
        m_year: '년',
        m_month: '개월',
        m_week: '주',
        m_day: '일',
        m_years: '년',
        m_months: '개월',
        m_weeks: '주',
        m_days: '일',
        free: '영원히 무료',
        years: '년',
        months: '개월',
        weeks: '주',
        days: '일',
    },

    operationRecord: {
        title: '작전 기록',
        search_tips: '도메인 이름 검색',
        table_1: '유형',
        table_2: '이름',
        table_3: '주소',
        table_4: '기간',
        table_5: '실제 비용',
        table_6: '가스',
        table_7: '시간',
        type_1: '등록',
        type_2: '갱신',
        type_3: '기본 이름 설정',
        type_4: '크로스체인 전송',
        type_5: '관리자 수정',
        type_6: '소유자 이전',
        type_7: '도메인 이름 수신',
        type_8: '크로스체인 전송 수신',
        type_9: '소유자 이전',
        type_from: 'From',
        type_to: 'To',
    },

    activities: {
        activities: '활동',
        underWay: '진행 중',
        upcoming: '곧 시작',
        ended: '종료됨',
        activity_time: '행사 시간',
        rewards: '보상',
        invite_now: '초대하기',
        invite_desc: '😃 "OpenName"에서 무적의 옴니체인 도메인 이름을 생성했습니다. 이제 플랫폼에서 평생 도메인 등록이 $5부터 가능합니다 🥳. 놓치지 마세요! 👀 함께 탐험해보세요~\n',
        rule: '규칙 설명',
        activity_chain: '체인',
        rank: '순위',
        earn_rank: '수익',
        invite_address_rank: '초대 주소 수',
        invite_register_rank: ' 초대 등록 수',
        register_rank: '등록 수',
        option_rank: '순위',
        option_address: '주소',
        option_bonus: '보상 받은 것',
        option_invite_address: '초대 주소 수',
        option_invite_register: '초대 등록 수',
        option_domain: '한국어 도메인',
    },

    mailboxBinding: {
        tip1: '1. 이메일 바인딩',
        tip2: '2. 커뮤니티 미디어 팔로우',
        tip3: '주요 이름 설정',
        tip4: '등록 계속',
        tip5: '공식 계정 팔로우',
        tip6: '이메일 바인딩'
    },

    nameFi: {
        name_fi_title: 'Register Names To\nEarn ',
        coins: 'Coins ',
        name_fi_title2: 'and',
        points: ' Points',
        first_namefi: 'The First NameFi of The World',
        coin_desc: 'Coins: Can be Swapped into USDT',
        point_desc: 'Points: Probably nothing',
        join_tele: 'Join Telegram',
        coming_soon: '✨ Coming soon!',
        intro: 'NameFi Introduction',
        intro_desc0: 'NameFi is a new domain name value paradigm proposed and built by OpenName, allowing domain names to continuously generate  value for users.',
        intro_desc1: 'By ',
        intro_desc1_bold: 'Staking Names',
        intro_desc2: ' or ',
        intro_desc2_bold: ' Completing Tasks',
        intro_desc3: ', you can earn Coins and Points. Coins can be swapped into USDT from the reward pool at any time,reward pool is 40% of the platform\'s net registration fee revenue. Points represents your contributions on OpenName.',
        intro_desc4: 'Register your domain name now and get ready to participate in staking!',
        register: 'Register Names',
        earn_desc1: 'Stake domains, Staking rewards include Coins and Points.',
        earn_desc2: 'During the staking period, you can still transfer or bridge your domain names, but your staking earnings will also undergo corresponding changes.',
        earn_desc3: 'Different domain suffixes, lengths, and registration periods have different rewarding speeds.',
        weight_table: 'Staking weight table',
        suffix: 'Suffix',
        character1: '1-character',
        character2: '2-character',
        character3: '3-character',
        character4: '4 characters or more',
        suffix_weight: 'Suffix weight',
        more: 'More',
        fold_up: 'Fold up',
        task_earn1: 'All users can earn Coins and Points by completing easy tasks.',
        task_earn2: 'The amount of Coins and Points that the user receives is determined by the quantity of completed tasks.',
        task_earn3: 'The task list and detailed rules will be announced at the beginning of the event, stay tuned.',
        rule_remark: 'The content is for reference only. Please refer to the official rules provided by OpenName. OpenName has the right to modify and interpret the rules.',
    },

    tldDetail: {
        tld_profile: '프로필 편집',
        copy_link: '링크 복사',
        tld_share: '공유하다',
        share_tld_desc: 'I registered a favorite domain name on OpenName, Come and give it a try! 🎉',
        share_tld_desc2: 'Have your favorite web3 domain',
        report: '신고',
        owner: '소유자',
        manager: '매니저',
        expiration_time: '만료까지',
        renewal: '연장',
        more: '더 많은',
        name_holder: '소유자',
        name_registration: '일 등록',
        registration: '등록',
        domain_pricing: '도메인 가격',
        character_length: '문자 길이',
        first_level_domain: ' 일급 도메인',
        multi_level_domain: '다중 레벨 도메인',
        first_level_desc: 'Web3 도메인은 두 개 이상의 문자 그룹으로 이루어져 있으며, 각 그룹의 문자는 점으로 구분됩니다. 두 개의 문자 그룹을 포함한 도메인은 일급 도메인입니다. 예를 들어, love.you는 일급 도메인이며.',
        multi_level_desc: 'Web3 도메인은 두 개 이상의 문자 그룹으로 이루어져 있으며, 각 그룹의 문자는 점으로 구분됩니다. 두 개의 문자 그룹을 포함한 도메인은 일급 도메인입니다. 예를 들어,i.love.you는 다급 도메인입니다.',
        character1: '1자',
        character2: '2자',
        character3: '3자',
        character4: '4자 이상',
        any_length: '모든 길이',
        information: '기본 정보',
        social_media: '소셜 미디어',
        other: '다른',
        register_record: '등록 기록',
        name: '도메인 이름',
        address: '주소',
        registration_cycle: '등록 기간',
        registration_fee: '등록 수수료',
        registration_time: '등록 시간',
    },

    tldEdit: {
        modify_pic: 'Modify profile picture',
        add_photo: 'Add photo',
        introduction: '프로필',
        introduction_placeholder: '간단한 설명',
        draft: '초안 저장',
        release: '게시',
    },

    tldData: {
        address_num: '소유자',
        name_num: '일 등록',
        your_income: '당신의 수입',
        cumulative_income: '누적 수입',
        platform_ratio: '플랫폼 수수료 비율',
        your_share: '당신의 수수료 비율',
        domain_records: '등록 기록',
        extend_record: '도메인 갱신 기록',
        registration: '등록',
        extension: '연장',
        extension_period: '갱신 주기',
        extension_fee: '갱신 비용',
        share_address: '분배 주소',
        share_income: '분배 수입',
        extend_time: '갱신 시간',
        address_tip: '해당 주소는 현재 도메인 갱신 주소를 나타냅니다.',
        agency: '공유하다',
        agency_title: '기록 공유',
        register_address: '등록 된 주소',
        agency_address: '마케터 주소',
        agency_fee: '등록비',
        agency_lcome: '마케터를 위한 이익 공유',
        you_lcome: '당신의 소득',
        agency_time: '시간'
    },

    tldEntrance: {
        tld_owners: '소유자',
        tld_on: '~에',
        tld_domain: '도메인',
        tld_holders: '홀더',
        all_chain: '전체 체인'
    },

    amendTld: {
        settings_price: '도메인 이름 가격 설정',
        setting_desc: 'TLD 설정 프로세스에 도움이 필요한 경우 저희에게 ',
        setting_desc1: '연락해주세요.',
        price_modle: '가격 모델',
        one_domain: '1차 도메인 이름',
        two_domain: '다단계 도메인 이름',
        one_ch: '문자',
        two_ch: '문자',
        buy_out: '종생',
        event_ling: '길이 제한 없음',
        discount: '할인',
        allow_chail: '등록 체인 허용',
        allow_cross_chail: '대상 체인으로의 교차 허용',
        pl_1: '입력하세요. 100은 할인이 없음을 의미합니다.',
        pl_2: '선택하세요',
        all_chain: '모든 퍼블릭 체인',
        tip_2: '퍼블릭 체인을 선택하면 사용자는 해당 퍼블릭 체인의 체인을 통해서만 도메인 이름을 전송할 수 있습니다. 선택하지 않으면 플랫폼에서 지원하는 모든 퍼블릭 체인이 기본값으로 설정됩니다. ',
        tip_1: '등록 체인을 선택하면 사용자는 해당 퍼블릭 체인에만 도메인 이름을 등록할 수 있습니다. 선택하지 않으면 플랫폼에서 지원하는 모든 퍼블릭 체인이 기본값으로 설정됩니다. ',
        tip_3: '가격 템플릿을 선택한 후 TLD를 빠르게 나열할 수 있습니다. 더 많은 설정을 하고 싶다면 "내 TLD"로 이동하여 더 많은 설정을 할 수 있습니다.',
        grounding: '선반',
        grounding_tld: 'TLD 나열',
        custom: '사용자 정의',
        old_out: '제거 확인',
        old_out_tips: '서가에서 제거된 후에는 귀하의 TLD를 더 이상 사용자 등록에 사용할 수 없습니다. 주의해서 진행하시기 바랍니다. ',
        Sell_out_1: '생각해 보겠습니다',
        Sell_out_confirm: '확인',
        year: '연간 구독',
        liftTime: '일시불 구매, 평생 소유',
        m_year: '/년',
        m_liftTime: '/매입',
        no_limit: '불요',
        manage_secondary: 'Registrar/Marketer',
        manage_secondary_tips: 'Set up that revenue sharing scheme to registrar/marketer.',
        address: 'Address',
        rate: 'Rate',
        agency_address_error: '잘못된 주소',
        agency_address_error1: '주소는 비워둘 수 없습니다.',
        agency_address_error2: '중복된 주소가 존재합니다',
        agency_rate_error: '에이전트 이익 배분 비율이 잘못되었습니다.',
    },

    myTld: {
        setting_price: '오픈 등록',
        successful: '성공적으로 제출',
        apply: '적용하다',
        my_tld: '내 TLD',
        search_tld: 'TLD 검색',
        unOpen_tld: '열리지 않음',
        open_tld: '열었습니다',
        tld_data: 'TLD 데이터',
        tld_open: '등록 열기',
        tld_share: '등록 열기',
        tld_date: '데이터',
        tld_price: '가격',
        tld_register: '등록 중',
        tld_cross: '크로스 체인',
        tld_owner: '소유자',
        tld_manager: '관리자',
        tld_tips: '등록이 진행되지 않은 TLD는 갱신되지 않으므로 최대한 빨리 등록을 진행하는 것이 좋습니다. ',
        tld_transfer: '이체 보유자',
        tld_change: '관리자 수정',
        tld_crossing: '크로스체인 전송',
        tld_close: '등록 닫기',
        batch_renewal: 'TLD 갱신',
        tld_close_verify: '등록 종료 확인',
        tld_close_confirm: '확인하다',
        tld_close_tips: '등록을 종료한 후, 사용자는 이 TLD의 도메인 이름을 등록할 수 없습니다. 주의해서 진행해주세요! ',
        rate: '분할 비율',
        rate_tips: '다른 사용자가이 TLD 하에서 도메인을 등록하면 등록 수수료의 ',
        rate_tips_2: '를 받게 됩니다.',
        statusTextL: '상태',
        no_description: '아직 설명이 없습니다',
        applyPopup: 'TLD 등록 신청',
        pop_tips: '신청서를 제출하신 후, 이메일을 통해 연락드리겠습니다. 채널 고정 해주세요.',
        apply_text: '신청 이유',
        apply_text_error: '신청사유를 입력해주세요',
        l_tip1: '연간 가격은 다음보다 크거나 같아야 합니다.',
        l_tip2: '구매 가격은 다음보다 크거나 같아야 합니다.',
        l_tip3: '연간 가격',
        l_tip4: '구매 가격',
        l_tip5: '연간 가격은 다음 값보다 작거나 같아야 합니다.',
        l_tip6: '구매 가격은 다음 값보다 작거나 같아야 합니다.',
        price_empty: 'TLD 가격은 비워둘 수 없습니다.',
        not_allowed: '크로스체인 금지',
        group_tip: '최고의 TLD 획득을 축하합니다. TLD 소유자 독점 서비스 그룹에 가입하세요.',
        btn1: '추가 등록',
        btn2: '등록 목록',
    }
}