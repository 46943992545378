import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1a31e7a7"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "header-content fsb scid-header-content"
};
var _hoisted_2 = {
  "class": "header-left flex-start"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  style: {
    "padding-right": "0.32rem"
  }
};
var _hoisted_6 = {
  key: 0,
  "class": "name-item fsb"
};
var _hoisted_7 = {
  "class": "flex-start"
};
var _hoisted_8 = {
  style: {
    "color": "#26a062"
  }
};
var _hoisted_9 = {
  key: 0,
  "class": "suffix-type free"
};
var _hoisted_10 = {
  key: 1,
  "class": "suffix-type hot"
};
var _hoisted_11 = {
  key: 2,
  "class": "suffix-type new"
};
var _hoisted_12 = {
  key: 0
};
var _hoisted_13 = {
  key: 0,
  "class": "name-status"
};
var _hoisted_14 = {
  key: 1,
  "class": "name-status registed"
};
var _hoisted_15 = {
  key: 1
};
var _hoisted_16 = {
  key: 0,
  "class": "name-status registed"
};
var _hoisted_17 = {
  key: 1,
  "class": "name-status registed"
};
var _hoisted_18 = {
  key: 2,
  "class": "name-status registed"
};
var _hoisted_19 = {
  key: 3,
  "class": "name-status"
};
var _hoisted_20 = ["onClick"];
var _hoisted_21 = {
  "class": "flex-start"
};
var _hoisted_22 = {
  "class": "suffix-type address"
};
var _hoisted_23 = {
  "class": "header-right flex-start"
};
var _hoisted_24 = {
  key: 0,
  "class": "flex-center"
};
var _hoisted_25 = ["onClick"];
var _hoisted_26 = ["src"];
var _hoisted_27 = {
  key: 1,
  "class": "cart line"
};
var _hoisted_28 = {
  key: 2,
  "class": "gas-price fs"
};
var _hoisted_29 = ["src"];
var _hoisted_30 = ["src"];
var _hoisted_31 = {
  key: 0,
  "class": "count"
};
var _hoisted_32 = {
  key: 3,
  "class": "select-language fsb"
};
var _hoisted_33 = ["src"];
var _hoisted_34 = {
  "class": "select-label"
};
var _hoisted_35 = ["src"];
var _hoisted_36 = {
  key: 5,
  "class": "select-language fsb"
};
var _hoisted_37 = ["src"];
var _hoisted_38 = ["src"];
var _hoisted_39 = {
  "class": "select-chain-op flex-start"
};
var _hoisted_40 = ["src"];
var _hoisted_41 = {
  "class": "chain-name"
};
var _hoisted_42 = {
  key: 0,
  "class": "chain-status"
};
var _hoisted_43 = {
  key: 6,
  "class": "select-language fsb"
};
var _hoisted_44 = ["onClick"];
var _hoisted_45 = ["src"];
var _hoisted_46 = ["src"];
var _hoisted_47 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");
  var _component_el_select = _resolveComponent("el-select");
  var _component_van_overlay = _resolveComponent("van-overlay");
  var _component_van_popover = _resolveComponent("van-popover");
  var _component_Connect = _resolveComponent("Connect");
  var _component_user_info = _resolveComponent("user-info");
  var _component_connect_wallet = _resolveComponent("connect-wallet");
  var _component_service_term = _resolveComponent("service-term");
  var _component_shopping_cart = _resolveComponent("shopping-cart");
  var _component_email_item = _resolveComponent("email-item");
  var _component_callback_pupop = _resolveComponent("callback-pupop");
  var _component_prompt = _resolveComponent("prompt");
  var _component_unsupported_chain = _resolveComponent("unsupported-chain");
  var _component_header_more = _resolveComponent("header-more");
  var _component_register = _resolveComponent("register");
  var _component_what_is_new = _resolveComponent("what-is-new");
  var _component_mailbox_binding = _resolveComponent("mailbox-binding");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    onClick: _cache[8] || (_cache[8] = function () {
      return $options.handlehideMore && $options.handlehideMore.apply($options, arguments);
    }),
    "class": "header flex-center"
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    "class": "logo",
    src: require('@/assets/image/commom/logo.svg'),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$toUrl('/');
    })
  }, null, 8, _hoisted_3), !$options.isMobile && _ctx.$route.path != '/' ? (_openBlock(), _createBlock(_component_el_select, {
    key: 0,
    ref: "select-name",
    modelValue: $data.searchName,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $data.searchName = $event;
    }),
    "class": "search-input",
    "popper-class": "select-opt right-padding single-select-loadmore",
    clearable: "",
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    placeholder: " ",
    "remote-method": $options.handleSearchName,
    "no-data-text": $data.queryErrorMsg,
    onFocus: $options.handleSelectNameFocus,
    onKeyup: _cache[2] || (_cache[2] = _withKeys(function ($event) {
      return $options.handleMore(1);
    }, ["enter", "native"]))
  }, {
    prefix: _withCtx(function () {
      return [_createElementVNode("img", {
        style: {
          "width": "0.96rem",
          "height": "1.6rem",
          "opacity": "0.8"
        },
        src: require('@/assets/image/commom/search-box.svg'),
        alt: ""
      }, null, 8, _hoisted_4)];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.nameListItem, function (item) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: item.name,
          label: item.name,
          value: item.name,
          onClick: function onClick($event) {
            return $options.handleSelectName(item.name, item);
          },
          disabled: _ctx.disabledItem(item)
        }, {
          "default": _withCtx(function () {
            return [!item.is_address ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, [_createTextVNode(_toDisplayString(item.prefix), 1), _createElementVNode("span", _hoisted_8, _toDisplayString(item.tld), 1)]), item.suffixClass == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("query.free_suffix")), 1)) : item.suffixType == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("query.hot_suffix")), 1)) : item.suffixType == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("query.new_suffix")), 1)) : _createCommentVNode("", true)]), _ctx.displayContent(item, 1) ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_ctx.displayContent(item, 2) ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t("query.available")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t("query.unavailable")), 1))])) : (_openBlock(), _createElementBlock("div", _hoisted_15, [_ctx.displayContent(item, 3) ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t("query.has_registered")), 1)) : _ctx.displayContent(item, 4) ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t("query.grace_period")), 1)) : _ctx.displayContent(item, 5) ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t("query.unavailable")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$t("query.available")), 1))]))])) : (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _withModifiers(function ($event) {
                return $options.handleMore(item.name);
              }, ["stop"]),
              "class": "name-item fsb"
            }, [_createElementVNode("div", _hoisted_21, [_createElementVNode("span", null, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('manager.address')), 1)])], 8, _hoisted_20))];
          }),
          _: 2
        }, 1032, ["label", "value", "onClick", "disabled"]);
      }), 128))])];
    }),
    _: 1
  }, 8, ["modelValue", "remote-method", "no-data-text", "onFocus"])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_23, [!$options.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_24, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.routerList, function (item, i) {
    return _openBlock(), _createElementBlock("div", {
      key: i,
      "class": _normalizeClass(["flex-center", item.path == _ctx.$route.path ? 'header-title active' : 'header-title']),
      onClick: function onClick($event) {
        return $options.handRouter(item.path, item);
      }
    }, [item.icon ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      "class": "title-img",
      src: item.icon
    }, null, 8, _hoisted_26)) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(item.name), 1)], 10, _hoisted_25);
  }), 128))])) : _createCommentVNode("", true), !$options.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_27)) : _createCommentVNode("", true), _ctx.$store.state.account && !$options.isMobile && (_ctx.$store.state.chainid == 1 || _ctx.$store.state.chainid == 5) ? (_openBlock(), _createElementBlock("div", _hoisted_28, [_createElementVNode("img", {
    "class": "icon-gas",
    src: require('@/assets/image/commom/icon_gas_price.png')
  }, null, 8, _hoisted_29), _createElementVNode("div", null, _toDisplayString($data.gasPrice), 1)])) : _createCommentVNode("", true), _createElementVNode("div", {
    "class": "cart",
    onClick: _cache[3] || (_cache[3] = function () {
      return $options.handShowCard && $options.handShowCard.apply($options, arguments);
    })
  }, [_createElementVNode("img", {
    id: "btnCart",
    "class": "icon-cart",
    src: require('@/assets/image/commom/icon_shopping_cart.png')
  }, null, 8, _hoisted_30), $options.cartNumber ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString($options.cartNumber > 99 ? '99+' : $options.cartNumber), 1)) : _createCommentVNode("", true)]), !$options.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_32, [_createElementVNode("img", {
    "class": "icon-inter",
    src: require('@/assets/image/commom/icon_Internationalization.svg')
  }, null, 8, _hoisted_33), _createVNode(_component_el_select, {
    ref: "select-lan",
    placement: "top",
    "popper-class": "select-opt",
    "class": "header-select",
    modelValue: $data.languageType,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
      return $data.languageType = $event;
    }),
    onChange: $options.handleChangeLanguage
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.languageList, function (item) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: item.value,
          label: item.label,
          value: item.value
        }, {
          "default": _withCtx(function () {
            return [_createElementVNode("div", _hoisted_34, _toDisplayString(item.label), 1)];
          }),
          _: 2
        }, 1032, ["label", "value"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["modelValue", "onChange"])])) : _createCommentVNode("", true), $options.isMobile ? (_openBlock(), _createElementBlock("div", {
    key: 4,
    "class": "cart",
    onClick: _cache[5] || (_cache[5] = _withModifiers(function () {
      return $options.handleMore && $options.handleMore.apply($options, arguments);
    }, ["stop"]))
  }, [_createElementVNode("img", {
    "class": "icon-cart",
    src: require('@/assets/image/commom/icon_more.png')
  }, null, 8, _hoisted_35)])) : _createCommentVNode("", true), _ctx.$store.state.account && !$options.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_36, [_createElementVNode("img", {
    "class": "icon-inter icon-chain",
    src: $options.currentChainIcon
  }, null, 8, _hoisted_37), _createElementVNode("img", {
    "class": _normalizeClass($data.isSelectChainFocus ? 'chain-arrow is-focus' : 'chain-arrow'),
    src: require('@/assets/image/commom/icon_arrow_bottom_white.png')
  }, null, 10, _hoisted_38), _createVNode(_component_el_select, {
    "class": "header-select",
    ref: "select-chain",
    "popper-class": "select-opt",
    onChange: $options.handleSelectChain,
    onFocus: $options.focusBox,
    onVisibleChange: $options.handleVisibleChange
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.supportChainList(), function (item) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: item.chainId,
          label: item.key,
          value: item.chainId
        }, {
          "default": _withCtx(function () {
            return [_createElementVNode("div", _hoisted_39, [_createElementVNode("img", {
              "class": "chain-img",
              src: item.icon
            }, null, 8, _hoisted_40), _createElementVNode("div", _hoisted_41, _toDisplayString(item.key), 1), _ctx.$store.state.chainid == item.chainId ? (_openBlock(), _createElementBlock("div", _hoisted_42)) : _createCommentVNode("", true)])];
          }),
          _: 2
        }, 1032, ["label", "value"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["onChange", "onFocus", "onVisibleChange"])])) : _createCommentVNode("", true), _ctx.$store.state.account && $options.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_43, [_createVNode(_component_van_overlay, {
    show: $data.showPopover,
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return $data.showPopover = false;
    })
  }, null, 8, ["show"]), _createVNode(_component_van_popover, {
    show: $data.showPopover,
    "onUpdate:show": _cache[7] || (_cache[7] = function ($event) {
      return $data.showPopover = $event;
    }),
    placement: "bottom",
    "show-arrow": false,
    "class": "header-select header-select-chail"
  }, {
    reference: _withCtx(function () {
      return [_createElementVNode("img", {
        "class": "icon-inter icon-chain",
        src: $options.currentChainIcon
      }, null, 8, _hoisted_46), _createElementVNode("img", {
        "class": _normalizeClass($data.isSelectChainFocus ? 'chain-arrow is-focus' : 'chain-arrow'),
        src: require('@/assets/image/commom/icon_arrow_bottom_white.png')
      }, null, 10, _hoisted_47)];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.supportChainList(), function (item, i) {
        return _openBlock(), _createElementBlock("li", {
          onClick: function onClick($event) {
            return $options.handleSelectChain(item.chainId);
          },
          key: i,
          "class": _normalizeClass(["flex-start", _ctx.$store.state.chainid == item.chainId ? 'active' : ''])
        }, [_createElementVNode("img", {
          src: item.icon,
          alt: ""
        }, null, 8, _hoisted_45), _createElementVNode("span", null, _toDisplayString(item.key), 1)], 10, _hoisted_44);
      }), 128))])];
    }),
    _: 1
  }, 8, ["show"])])) : _createCommentVNode("", true), _createVNode(_component_Connect, {
    isHeader: "1",
    onHandleShowUser: $options.handleShowUser
  }, null, 8, ["onHandleShowUser"])])]), $data.userVisible || $options.isMobile ? (_openBlock(), _createBlock(_component_user_info, {
    key: 0,
    onSettingEmail: $options.settingEmail,
    drawerShow: $data.userVisible,
    onHandleChangeChainId: $options.handleChangeChainId,
    onHandHideCard: $options.handHideUserPopup
  }, null, 8, ["onSettingEmail", "drawerShow", "onHandleChangeChainId", "onHandHideCard"])) : _createCommentVNode("", true)]), _createVNode(_component_connect_wallet, {
    ref: "connect"
  }, null, 512), _createVNode(_component_service_term, {
    ref: "term",
    onAgreeService: $options.finishConnect
  }, null, 8, ["onAgreeService"]), $data.showCart ? (_openBlock(), _createBlock(_component_shopping_cart, {
    key: 0,
    onHandHideCard: $options.handHideCard,
    drawerShow: $data.shopVisible
  }, null, 8, ["onHandHideCard", "drawerShow"])) : _createCommentVNode("", true), $data.emailVisible ? (_openBlock(), _createBlock(_component_email_item, {
    key: 1,
    onHandleClose: $options.handleClose,
    isEdit: $data.emailEdit,
    emailVisible: $data.emailVisible,
    infoStatus: $data.infoStatus
  }, null, 8, ["onHandleClose", "isEdit", "emailVisible", "infoStatus"])) : _createCommentVNode("", true), _createVNode(_component_callback_pupop), _createVNode(_component_prompt, {
    ref: "chainTip",
    description: _ctx.$t("message.unsupport_chain"),
    onHandleConfirm: $options.handleSwitchChainConfirm
  }, null, 8, ["description", "onHandleConfirm"]), $data.showPopupBox ? (_openBlock(), _createBlock(_component_unsupported_chain, {
    key: 2,
    chainid: $data.chainid_sel,
    onClickSwitch: $options.handleSwitch,
    onHandleClosesBox: $options.closesBox,
    drawerShow: $data.showPopupBox
  }, null, 8, ["chainid", "onClickSwitch", "onHandleClosesBox", "drawerShow"])) : _createCommentVNode("", true), $options.isMobile ? (_openBlock(), _createBlock(_component_header_more, {
    key: 3,
    ref: "more",
    languageType: $data.languageType,
    gasPrice: $data.gasPrice
  }, null, 8, ["languageType", "gasPrice"])) : _createCommentVNode("", true), _ctx.registerShowItem ? (_openBlock(), _createBlock(_component_register, {
    key: 4,
    suffixClass: _ctx.registerDomainItem.suffixClass,
    domain: _ctx.registerDomainItem.domain,
    isTld: _ctx.registerDomainItem.isTld,
    registerDomainSuffix: _ctx.registerDomainItem.registerDomainSuffix,
    registerShow: _ctx.registerShowItem,
    onSuccessInfo: $options.successBack,
    suffixType: _ctx.registerDomainItem.suffixType,
    onHandleClosesBox: $options.handleClosesBox
  }, null, 8, ["suffixClass", "domain", "isTld", "registerDomainSuffix", "registerShow", "onSuccessInfo", "suffixType", "onHandleClosesBox"])) : _createCommentVNode("", true), _createVNode(_component_what_is_new, {
    ref: "newNotice"
  }, null, 512), _createVNode(_component_mailbox_binding, {
    onSettingEmail: $options.settingEmail,
    onCloseBox: $options.closeBox,
    showBindingBox: $data.showBindingBox
  }, null, 8, ["onSettingEmail", "onCloseBox", "showBindingBox"])]);
}