import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-39106828"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "connect-popup fs"
};
var _hoisted_2 = {
  "class": "select-chain-wallet"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 0,
  "class": "select-title"
};
var _hoisted_5 = {
  key: 1,
  "class": "flex-start"
};
var _hoisted_6 = ["src"];
var _hoisted_7 = {
  "class": "select-title"
};
var _hoisted_8 = {
  key: 2,
  "class": "connect-desc"
};
var _hoisted_9 = ["onClick"];
var _hoisted_10 = {
  "class": "flex-start"
};
var _hoisted_11 = ["src"];
var _hoisted_12 = ["onClick"];
var _hoisted_13 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_popup, {
    show: $data.showConnect,
    "onUpdate:show": _cache[2] || (_cache[2] = function ($event) {
      return $data.showConnect = $event;
    }),
    position: $options.isMobile ? 'bottom' : 'center',
    round: "",
    onClickOverlay: $options.handleClose
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        "class": "pop-close",
        src: require('@/assets/image/commom/icon_close_popup.png'),
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleClose && $options.handleClose.apply($options, arguments);
        })
      }, null, 8, _hoisted_3), $data.isSelectNet ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("connectWallet.select_network")), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
        "class": "select-back",
        src: require('@/assets/image/connect/icon_back.png'),
        onClick: _cache[1] || (_cache[1] = function () {
          return $options.handleBack && $options.handleBack.apply($options, arguments);
        })
      }, null, 8, _hoisted_6), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("connectWallet.select_wallet")), 1)])), $options.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("connectWallet.connect_desc")), 1)) : _createCommentVNode("", true), $data.isSelectNet ? (_openBlock(), _createElementBlock("div", {
        key: 3,
        "class": _normalizeClass(["select-list fsb", $options.isMobile ? 'scrollBox' : ''])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.chainList($data.chainListMore, $options.isMobile), function (item) {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          "class": _normalizeClass(["select-item fsb", !$options.supportChainid(item) ? 'no_line' : '']),
          key: item.key,
          onClick: function onClick($event) {
            return $options.handleChainSelected(item);
          }
        }, [_createElementVNode("div", _hoisted_10, [_createElementVNode("img", {
          "class": "select-item-logo",
          src: item.icon
        }, null, 8, _hoisted_11), _createElementVNode("div", null, _toDisplayString(item.title), 1)])], 10, _hoisted_9)), [[_vShow, $options.supportChainid(item)]]);
      }), 128))], 2)) : _createCommentVNode("", true), !$data.isSelectNet ? (_openBlock(), _createElementBlock("div", {
        key: 4,
        "class": _normalizeClass(["select-list", $options.isMobile ? 'scrollBox' : ''])
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.walletList($data.walletSelectedMore, $options.isMobile), function (item) {
        return _openBlock(), _createElementBlock("div", {
          "class": "select-item flex-start",
          key: item.key,
          onClick: function onClick($event) {
            return $options.handleWalletSelected(item);
          }
        }, [_createElementVNode("img", {
          "class": "select-item-logo",
          src: item.icon
        }, null, 8, _hoisted_13), _createElementVNode("div", null, _toDisplayString(item.title), 1)], 8, _hoisted_12);
      }), 128))], 2)) : _createCommentVNode("", true)])])];
    }),
    _: 1
  }, 8, ["show", "position", "onClickOverlay"])]);
}