import store from '@/store/index.js'
import moment from "moment";
import MobileDetect from 'mobile-detect'
import useClipboard from 'vue-clipboard3';
const {
    toClipboard
} = useClipboard();
import {
    ElNotification
} from "element-plus"
import Axios from 'axios'
import BigNumber from 'bignumber.js';
import i18n from '@/language/i18n'
import FavoriteApi from "@/api/modules/favorite"
import MyTld from "@/api/modules/myTld"
import {
    getChainImages
} from '@/utils/chainInfo';
import {
    walletConnect,
    checkMetamask,
    okxwalletConnect,
    chainList,
    switchChain
} from "@/utils/connect.js"
import {
    showToast,
    setToastDefaultOptions
} from 'vant';

export function toUrl(url, e) {
    if (e) {
        sessionStorage.removeItem('account')
        window.open(url, '_blank');
    } else {
        if (url.indexOf("//") != -1) {
            window.open(url, '_blank');
        } else {
            this.$router.push(url)
        }
    }

}

export function testAddress(address) {
    let str = /^0x[0-9a-fA-F]{40}$/;
    if (str.test(address)) {
        return true
    } else {
        return false
    }
}

export function getAbbreviateAddress(address, length = 6) {
    if (!address) {
        return ''
    }
    if (testAddress(address) || address.length > 13) {
        return address.substr(0, length) + "..." + address.substr(address.length - length + 2, length - 2)
    }
    return address
}

export function getAbbreviateName(name, length = 10) {
    if (name && name.length > length * 2 + 3) {
        return name.substr(0, length) + "..." + name.substr(name.length - length, length)
    }
    return name
}

export function getAbbreviateLongName(name, length = 15) {
    if (name && name.length > length) {
        return name.substr(0, length - 3) + "..."
    }
    return name
}


export function showTip(msg, type = 'success') {
    if (store.state.isMobile) {
        setToastDefaultOptions({
            duration: 3500
        });
        showToast(msg);
        // duration
    } else {
        ElNotification({
            message: msg,
            type: type,
            duration: 3500,
        })
    }

}

/**
 *
 * @param {*} chainIds
 * @param {*} gTokens
 * @param {*} domains
 * @param {*} durations
 * @param {*} invitationCode
 * @param {*} activeName
 * @returns register parmes
 */
export function registerParams(self, arr, invitationCode, activeName, is_object, token = '', isTld = false, isRenew = false, currencySelect = '') {

    const params = {
        chainId: store.state.chainid,
        gToken: token,
        inviteCode: invitationCode,
        couponIds: activeName,
    };
    if (is_object || isTld && !isRenew) {
        params.name = arr
    } else {
        params.names = arr
    }
    if (currencySelect) {
        params.cfgTokenId = currencySelect.id
    }
    return params
}

export function formatDate(unixTimeStamp, noTime) {
    let date = moment(unixTimeStamp).format('YYYY.MM.DD')
    if (!noTime) {
        date = date + ' at ' + moment(unixTimeStamp).format('HH:mm (UTCZ)')
    }
    return date
}

export function GetDevice() {

    try {
        var getBrowser = function () {
            var u = navigator.userAgent
            var bws = [{
                name: 'sgssapp',
                it: /sogousearch/i.test(u)
            }, {
                name: 'wechat',
                it: /MicroMessenger/i.test(u)
            }, {
                name: 'weibo',
                it: !!u.match(/Weibo/i)
            }, {
                name: 'uc',
                it: !!u.match(/UCBrowser/i) || u.indexOf(' UBrowser') > -1
            }, {
                name: 'sogou',
                it: u.indexOf('MetaSr') > -1 || u.indexOf('Sogou') > -1
            }, {
                name: 'xiaomi',
                it: u.indexOf('MiuiBrowser') > -1
            }, {
                name: 'baidu',
                it: u.indexOf('Baidu') > -1 || u.indexOf('BIDUBrowser') > -1
            }, {
                name: '360',
                it: u.indexOf('360EE') > -1 || u.indexOf('360SE') > -1
            }, {
                name: '2345',
                it: u.indexOf('2345Explorer') > -1
            }, {
                name: 'edge',
                it: u.indexOf('Edge') > -1
            }, {
                name: 'ie11',
                it: u.indexOf('Trident') > -1 && u.indexOf('rv:11.0') > -1
            }, {
                name: 'ie',
                it: u.indexOf('compatible') > -1 && u.indexOf('MSIE') > -1
            }, {
                name: 'firefox',
                it: u.indexOf('Firefox') > -1
            }, {
                name: 'safari',
                it: u.indexOf('Safari') > -1 && u.indexOf('Chrome') === -1
            }, {
                name: 'qqbrowser',
                it: u.indexOf('MQQBrowser') > -1 && u.indexOf(' QQ') === -1
            }, {
                name: 'qq',
                it: u.indexOf('QQ') > -1
            }, {
                name: 'chrome',
                it: u.indexOf('Chrome') > -1 || u.indexOf('CriOS') > -1
            }, {
                name: 'opera',
                it: u.indexOf('Opera') > -1 || u.indexOf('OPR') > -1
            }]
            for (var i = 0; i < bws.length; i++) {

                if (bws[i].it) {
                    return bws[i].name
                }
            }

            return 'other'
        }
        var getOS = function () {
            var u = navigator.userAgent
            if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
                return 'windows'
            } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
                return 'macOS'
            } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
                return 'ios'
            } else if (u.match(/android/i)) {
                return 'android'
            } else if (u.match(/Ubuntu/i)) {
                return 'Ubuntu'
            } else {
                return 'other'
            }
        };
        var browserRedirect = function () {
            var sUserAgent = navigator.userAgent;
            var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
            var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
            if (isMac) return "Mac";
            var isUnix = (navigator.platform == "X11") && !isWin && !isMac;
            if (isUnix) return "Unix";
            var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
            if (isLinux) return "Linux";
            if (isWin) {
                var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
                if (isWin2K) return "Win2000";
                var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
                if (isWinXP) return "WinXP";
                var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
                if (isWin2003) return "Win2003";
                var isWinVista = sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
                if (isWinVista) return "WinVista";
                var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
                if (isWin7) return "Win7";
                var isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1;
                if (isWin10) return "Win10";
            }
            return "other";
        }
        var device_type = navigator.userAgent;
        var md = new MobileDetect(device_type)
        // alert(JSON.stringify(md))
        var os = md.os();
        var browser = browserRedirect()
        var model = ""
        if (os == "iOS") {
            os = +md.version("iPhone")
            model = md.mobile()
            browser = getBrowser()
        } else if (os == "AndroidOS") {
            os = md.os() + md.version("Android")
            var sss = device_type.split(";")
            // var i = sss.contains("Build/")
            // if (i > -1) {
            //     model = sss[i].substring(0, sss[i].indexOf("Build/"))
            // }
            model = getOS()
            browser = getBrowser()
        } else {
            os = browserRedirect()
            model = getOS()
            browser = getBrowser()
        }
    } catch (error) {

    }
    let json = {
        "os": os,
        "divice": model,
        'browser': browser
    }

    return {
        "os": os,
        "divice": model,
        'browser': browser
    }
}
export async function copyText(text) {
    try {
        await toClipboard(text);
    } catch (e) {}
};

export async function getFavoriteCount() {
    try {
        let res = await FavoriteApi.getFavoriteCount()
        let res1 = await FavoriteApi.getFavoriteList({
            pageNo: 1,
            pageSize: 1,
            type: 2
        })
        if (res1) {
            res = Number(res1.total) + res
        }

        //console.log(res)
        store.commit('setFavoriteCount', res)
    } catch (error) {
        //console.log(error)
    }
}

export function floatingNumber(num) {
    if (num != undefined && num != null && num != '') {
        num = Number(num)
        return parseFloat(num.toPrecision(12))
    }
    return ''
}

export async function connectWallet() {
    try {
        let res = await okxwalletConnect(window.ethereum, 'metamask', store.state.chainid, 1)
        return true
    } catch (error) {
        return false
    }

}

export async function setPupopStatus(is_pupop, status, e) {
    if (e) {
        store.commit('setShowBindingBox', status)
    } else {
        store.commit('setWeb3Success', status)
        store.commit('setDrawerShow', is_pupop)
    }

}

export function expirationTime(that, key, time, i) {
    let loca = localStorage.getItem("language");
    if (loca == "ch") {
        loca = "zh-cn";
    } else if (loca == 'jp') {
        loca = "ja";
    } else if (loca == 'kr') {
        loca = "en";
    }
    let timestamp = moment(time).unix() - Date.parse(new Date()) / 1000;
    if (timestamp > 24 * 60 * 60 * 28) {
        let str = moment(time).locale(loca).toNow(true)
        if (str) {
            if (str.charAt(0) == 'a' && str.charAt(1) != 'n') {
                str = str.substring(1)
                str = 1 + ' ' + str
            } else if (str.charAt(0) == 'a' && str.charAt(1) == 'n') {
                str = str.substring(1)
                str = str.substring(1)
                str = 1 + ' ' + str
            }
        }

        return str;
    } else if (timestamp > 24 * 60 * 60 * 7) {
        if (timestamp >= 24 * 60 * 60 * 21 && timestamp < 24 * 60 * 60 * 28) {
            return that.$t("myDomain.week_4");
        } else if (
            timestamp > 24 * 60 * 60 * 14 &&
            timestamp < 24 * 60 * 60 * 21
        ) {
            return that.$t("myDomain.week_3");
        } else if (
            timestamp > 24 * 60 * 60 * 7 &&
            timestamp < 24 * 60 * 60 * 14
        ) {
            return that.$t("myDomain.week_2");
        }
    } else {
        if (that[key] && that[key].length > 0) {
            if (that[key][i]) {
                that[key][i]["is_red"] = true;
            }

        }
        let str = moment(time).locale(loca).toNow(true)
        if (str) {
            if (str.charAt(0) == 'a' && str.charAt(1) != 'n') {
                str = str.substring(1)
                str = 1 + ' ' + str
            } else if (str.charAt(0) == 'a' && str.charAt(1) == 'n') {
                str = str.substring(1)
                str = str.substring(1)
                str = 1 + ' ' + str
            }
        }
        return str;
    }
}


export async function setBodyStyle(e) {
    let bodyStyle = document.body.style
    let htmlStyle = document.getElementsByTagName('html')[0].style
    if (store.state.isMobile) {
        if (e) {
            bodyStyle.overflow = `hidden`
            htmlStyle.overflow = `hidden`
            bodyStyle.height = `100vh`
            htmlStyle.height = `100vh`
        } else {
            bodyStyle.overflow = `initial`
            htmlStyle.overflow = `initial`
            bodyStyle.height = `auto`
            htmlStyle.height = `auto`
        }
    }

}

export function setWindowEthereum() {
    let walletType = 99
    try {
        if (typeof window.okxwallet !== 'undefined' || typeof window.okexchain !== 'undefined') {
            walletType = 4
        } else {
            if (window.ethereum) {
                if (window.ethereum.isTokenPocket) {
                    walletType = 7
                } else if (window.ethereum.isTrustWallet) {
                    walletType = 5
                } else if (window.ethereum.providerInfo && window.ethereum.providerInfo.label == 'Gate Wallet!') {
                    walletType = 8
                } else if (window.ethereum.isCoinbaseWallet) {
                    walletType = 3
                } else if (window.ethereum.isRabby) {
                    walletType = 10
                } else if (window.ethereum.isImToken) {
                    walletType = 9
                } else if (window.ethereum.isBitKeep) {
                    walletType = 6
                } else if (window.NaboxWallet) {
                    walletType = 12
                } else if (window.ethereum.isMetaMask) {
                    walletType = 1
                } else if (window.foxwallet && window.foxwallet.aleo) {
                    walletType = 13
                }
            }
        }
    } catch (error) {
        console.log(error)
    }
    setWalletType(walletType)

}

export function setWalletType(e) {

    store.commit('setwalletTypeLogint', e)

}


export function localFormat(e) {
    let loca = localStorage.getItem("language");
    let format = 'YYYY-MM-DD HH:mm:ss'
    if (loca == "en") {
        format = 'YYYY/MM/DD HH:mm:ss'
    }
    if (e == 1) {
        format = 'YYYY/MM/DD'
    }
    return format
}

export function isUnSupport(item) {
    let json = {
        status: false,
        arr: []
    }
    if (item) {
        let registerChainIdArr = []
        if (item.indexOf(',') != -1) {
            registerChainIdArr = item.split(',')
        } else {
            registerChainIdArr = [item]
        }
        json.arr = [...registerChainIdArr]
        if (registerChainIdArr.indexOf(`${store.state.chainid}`) != -1 || !store.state.chainid) {
            json.status = false
        } else {
            json.status = true
        }
    } else {
        json.status = false
    }
    return json
}

export function isSupportChainName(item) {
    if (!item) {
        return ''
    } else {
        let registerChainIdArr = []
        if (item.indexOf(',') != -1) {
            registerChainIdArr = item.split(',')
        } else {
            registerChainIdArr = [item]
        }
        let name = []
        for (let i in registerChainIdArr) {
            if (chainList[registerChainIdArr[i]]) {
                name.push(chainList[registerChainIdArr[i]].chainName)
            }

        }
        return name
    }

}

export function isSupportChainIcon(item) {
    if (!item) {
        return ''
    } else {
        let registerChainIdArr = []
        if (item.indexOf(',') != -1) {
            registerChainIdArr = item.split(',')
        } else {
            registerChainIdArr = [item]
        }
        let icons = []
        for (let i in registerChainIdArr) {
            if (getChainImages()[registerChainIdArr[i]]) {
                let json = {
                    name: chainList[registerChainIdArr[i]].chainName,
                    icon: getChainImages()[registerChainIdArr[i]]
                }
                icons.push(json)
            }
        }
        return icons
    }

}

export function cfxToWei() {
    return new Promise(async (resolve, reject) => {
        Axios.get('https://api.confluxnetwork.org/market-stats').then(function (response) {
            if (response.data) {
                if (response.data.priceUsd) {
                    let num = new BigNumber(1 / response.data.priceUsd)
                    let big = new BigNumber(10).pow(18)
                    let bigNum = (Number(new BigNumber(num.times(big)).toString())).toFixed(0)
                    resolve(bigNum)
                }
            }

        }).catch(function (response) {
            console.log(response); //发生错误时执行的代码
            reject(0)
        });
    })

}

export async function getUsdtToWei(methods) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await MyTld[methods]('')
            if (res) {
                resolve(res)
            } else {
                reject(0)
            }
        } catch (error) {
            console.log(error)
            reject(0)
        }
    })

}


export function getTldName(name) {
    if (name.substr(0, 1).indexOf('.') != -1) {
        name = name.split('.')[1]
    }
    return name
}

export function getSubTldName(name) {
    if (name.substr(0, 1).indexOf('.') != -1) {
        name = name.substr(1)
    }
    return name
}

export function getTldDot(name) {
    if (name.substr(0, 1).indexOf('.') == -1) {
        name = `.${name}`
    }
    return name
}

export function tldToLower(name) {
    return name.toLowerCase()
}

export function tokenValue(option, e) {
    let {
        native_flag,
        cfg_token_id,
        pay_value,
        nativeToken
    } = option
    if (native_flag == 1) {
        // 原生币
        if (pay_value && pay_value > 0) {
            let big = new BigNumber(10).pow(-18);
            let x = new BigNumber(pay_value);
            if (Number(new BigNumber(x.times(big).toString())) >= 10) {
                return `${Number(new BigNumber(x.times(big).toString())).toFixed(2)} ${nativeToken}`;
            } else {
                return `${parseFloat(Number(new BigNumber(x.times(big).toString()).toFixed(8)).toPrecision(
                4
              ))} ${nativeToken}`;
            }
        } else {
            return `0.0 ${nativeToken}`;
        }
    } else {
        if (cfg_token_id) {
            let tokenJson = JSON.parse(JSON.stringify(store.state.getCfgTokenList[cfg_token_id]))
            if (e == 1) {
                tokenJson.tokenName = ''
            }
            if (pay_value && pay_value > 0) {
                let big = new BigNumber(10).pow(-Math.abs(tokenJson.tokenPrecision));
                let x = new BigNumber(pay_value);
                if (Number(new BigNumber(x.times(big).toString())) >= 10) {
                    return `${Number(new BigNumber(x.times(big).toString())).toFixed(2)} ${tokenJson.tokenName}`;
                } else {
                    return `${parseFloat(Number(new BigNumber(x.times(big).toString()).toFixed(8)).toPrecision(
                    4
                ))} ${tokenJson.tokenName}`;
                }
            } else {
                return `0.0 ${tokenJson.tokenName}`;
            }
        } else {
            return `0.0 ${nativeToken}`;
        }

    }
}