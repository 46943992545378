import {
    mapState
} from "vuex";
import {
    isSupportChain
} from "@/utils/connect";
import {
    chainIdListFilter,
    signLogin
} from "@/utils/connect";

export const mixinItem = {

    data() {
        return {
            isTldItem: false,
            setItemNum: ''
        };
    },
    watch: {
        chainid: {
            handler(to, from) {
                if (to || from) {
                    if (this.account) {

                    }
                }
            },
            deep: true,
            immediate: true,
        },
        signConnectCallBackHeader: {
            handler(to, from) {
                this.$store.commit('setSignConnectCallBackHeader', false)
                if (to) {
                    this.getConnectItem()
                }
            },
            deep: true,
            immediate: false,
        },

    },
    computed: {
        ...mapState(['chainid', 'signConnectCallBackHeader', 'registerItem']),
    },
    mounted() {

    },

    methods: {
        getConnectItem() {
            console.log(this.registerItem)
            if (this.registerItem) {
                if (this.$store.state.account) {
                    this.getOptionItem(this.registerItem, this.$store.state.getItemNum)
                }
                this.$store.commit("setConnectPosition", 0);
                this.$store.commit("setRegisterItem", '');
                this.$store.commit("setItemNum", '');
                this.resInfo()
            }
        },
        async signLoginNowItem() {
            const res = await signLogin()
            if (res) {
                this.$store.commit('setSignConnectCallBack', true)
            } else {
                this.$store.commit('setSignConnectCallBack', false)
            }
        },
        unConnectWalletItem(item) {
            this.$store.commit("setRegisterItem", '');
            this.$store.commit("setRegisterItem", item);
            let isUnSupportChainid = this.isUnSupportItem(item.registerChainIds).arr
            if (
                !this.$store.state.account ||
                this.$store.state.account == "null" ||
                this.isUnSupportItem(isUnSupportChainid).status
            ) {
                this.$store.commit("setIsSupportChainid", isUnSupportChainid);
                this.$store.commit("setConnectPosition", 15);
                this.$store.commit('setSignConnectCallBackHeader', false)
                this.$store.commit("setShowConnect", false);
                this.$store.commit("setShowConnect", true);
            } else {}
        },
        uniqueItem(arr, val) {
            const res = new Map();
            return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1))
        },
        handleRegisterItem(item) {
            let json = {
                domain: item.name,
                isTld: this.isTldItem,
                registerDomainSuffix: item.domainSuffix,
                suffixType: item.suffixType,
                suffixClass: item.suffixClass,
            }
            console.log(json)
            this.$store.commit('setRegisterDomainItem', json)
            this.$store.commit('setRegisterShowItem', true)
        },
        handleShoppingCartItem(item) {
            if (this.isEntrance) {
                return
            }
            if (this.isUnSupport(item.registerChainIds).status) {
                return;
            }
            if (item.domainStatus == 0 || item.domainStatus == 3) {
                let json = [];
                let shoppingList =
                    JSON.parse(localStorage.getItem("shoppingCartList")) || json;
                if (!item.isShoppingCart) {
                    if (shoppingList) {
                        if (shoppingList.length > 14) {
                            this.$showTip(this.$t(`ShoppingCart.card_limit`), "error");
                            return;
                        }
                    }
                }

                item.isShoppingCart = !item.isShoppingCart;

                if (shoppingList) {
                    shoppingList = shoppingList.filter((col) => col.name != item.name);
                } else {
                    shoppingList = [];
                }
                if (item.isShoppingCart) {
                    shoppingList.push(item);
                    if (!this.isSingle) {
                        this.addCard();
                    }

                }
                this.$emit("cleartLight", this.itemIndex);
                localStorage.setItem("shoppingCartList", JSON.stringify(shoppingList));
                this.$store.commit(
                    "setShoppingCartNumber",
                    JSON.stringify(shoppingList)
                );
            }
        },
        getOptionItem(item, e) {
            console.log(item, e)
            this.$store.commit("setItemNum", e);
            if (e) {
                this.isTldItem = true
            } else {
                this.isTldItem = false
            }
            if (this.$store.state.account) {
                if (
                    (item.domainStatus == 0 && !item.whitelistAddr) ||
                    item.domainStatus == 3 ||
                    (item.domainStatus == 0 &&
                        item.whitelistAddr.toLowerCase() ==
                        this.$store.state.account.toLowerCase())
                ) {
                    let isUnSupportChainid = this.isUnSupportItem(item.registerChainIds).arr

                    if (
                        (isUnSupportChainid.indexOf(`${this.chainid}`) == -1 &&
                            isUnSupportChainid.length > 0) ||
                        !isSupportChain()
                    ) {

                        this.unConnectWalletItem(item);
                    } else {
                        if (this.$store.state.account) {
                            this.handleRegisterItem(item)
                            if (!e) {
                                this.nameListItem = []
                            }
                        } else {

                            this.unConnectWalletItem(item);
                        }

                    }
                } else {
                    if (!e) {
                        if (item.domainStatus != 0 && item.domainStatus != 3) {
                            this.$router.push(`/manager?name=${item.name}`)
                            if (this.$route.path == '/manager') {
                                setTimeout(() => {
                                    location.reload()
                                })
                            }

                            this.nameListItem = []
                        }
                    }


                }
            } else {
                this.unConnectWalletItem(item);
            }

        },
        isUnSupportItem(item) {
            return this.$isUnSupport(item);
        },
        displayContent(item, e) {
            if (e == 1) { //保护域名
                return item.protectFlag && item.domainStatus == 0
            } else if (e == 2) { //白名单
                return item.domainStatus == 0 &&
                    item.whitelistAddr &&
                    item.whitelistAddr.toLowerCase() ==
                    this.$store.state.account.toLowerCase()
            } else if (e == 3) { //已经注册
                return item.domainStatus == 1
            } else if (e == 4) { //宽限期
                return item.domainStatus == 2
            } else if (e == 5) { //不可注册 后台-1标识、不是白名单
                return item.domainStatus == -1 ||
                    (item.domainStatus != 0 &&
                        !item.firstYearFree) ||
                    (
                        item.whitelistAddr &&
                        item.whitelistAddr.toLowerCase() !=
                        this.$store.state.account.toLowerCase())
            } else if (e == 6) { //拍卖期
                return item.domainStatus == 3
            } else if (e == 7) { //拍卖期
                return (item.domainStatus != -1 &&
                        (this.displayContent(item, 9))) ||
                    (item.domainStatus != -1 &&
                        item.domainStatus != 0 &&
                        item.whitelistAddr)
            } else if (e == 8) {
                return item.domainStatus == 0 || item.domainStatus == 3
            } else if (e == 9) { //tooltip提示,不支持的链
                return !item.whitelistAddr ||
                    (item.firstYearFree &&
                        item.whitelistAddr &&
                        item.whitelistAddr.toLowerCase() ==
                        this.$store.state.account.toLowerCase())
            } else if (e == 10) {
                return (item.domainStatus != -1 &&
                        (!item.whitelistAddr ||
                            (item.whitelistAddr &&
                                item.whitelistAddr.toLowerCase() ==
                                this.$store.state.account.toLowerCase()))) ||
                    (item.domainStatus != -1 &&
                        item.domainStatus != 0 &&
                        item.whitelistAddr)
            }
        },
        disabledItem(item) {
            let status = false
            if (this.is_address) { //地址搜索
                return false
            }
            if (item.protectFlag && item.domainStatus == 0) {
                if (item.domainStatus == 0 &&
                    item.whitelistAddr &&
                    item.whitelistAddr.toLowerCase() ==
                    this.$store.state.account.toLowerCase()) {

                } else {
                    status = true
                }
            } else {
                if (item.domainStatus == 1) {

                } else if (item.domainStatus == 2) {

                } else if (item.domainStatus == -1 ||
                    (item.domainStatus != 0 &&
                        !item.firstYearFree) ||
                    (
                        item.whitelistAddr &&
                        item.whitelistAddr.toLowerCase() !=
                        this.$store.state.account.toLowerCase())) {
                    status = true
                }
            }
            return status
        },
    },
};