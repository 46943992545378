import {
    API
} from '../api'
import store from '@/store/index.js'

const path = process.env.VUE_APP_BASE_API

export const SIGIN_API_URL = {
    SEND_EMAIL_VERIFY_CODE: `${path}/v1/user/sendEmailVerifyCode`,
    SET_USER_EMAIL: `${path}/v1/user/setUserEmail`,
    GET_EMAIL_REMINDER: `${path}/v1/user/getEmailReminder`,
    SET_REMINDER: `${path}/v1/user/setReminder`,
    CHECK_EMAIL_CODE: `${path}/v1/user/checkEmailCode`,
    CANCEL_REMINDER: `${path}/v1/user/cancelReminder`,

}


export default {
    async sendEmailVerifyCode(params, conf) {
        return await API.post(SIGIN_API_URL.SEND_EMAIL_VERIFY_CODE, params, conf, true)
    },

    async setUserEmail(params, conf) {
        return await API.post(SIGIN_API_URL.SET_USER_EMAIL, params, conf, true)
    },

    async getEmailReminder(params, conf) {
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(SIGIN_API_URL.GET_EMAIL_REMINDER, params, conf, false)
    },

    async setReminder(params, conf) {
        return await API.post(SIGIN_API_URL.SET_REMINDER, params, conf, true)
    },

    async checkEmailCode(params, conf) {
        return await API.post(SIGIN_API_URL.CHECK_EMAIL_CODE, params, conf, true)
    },

    async cancelReminder(params, conf) {
        return await API.post(SIGIN_API_URL.CANCEL_REMINDER, params, conf, true)
    },

}