import {
    createStore
} from 'vuex'
import {
    localStorage,
    sessionStorage
} from './storage'

const getCardNum = (state) => {
    let cardList = localStorage.getObject('shoppingCartList')
    if (cardList) {
        return cardList.length
    }
    return ''
}

export default createStore({
    state: {
        accessToken: localStorage.getItem('token') || '',
        isMobile: localStorage.getItem('isMobile'),
        deviceName: localStorage.getItem('deviceName'),
        language: localStorage.getItem('language') || 'en',
        primaryName: sessionStorage.getItem('primaryName') || '',
        provider: null,
        providerInit: null,
        account: '',
        chainid: null,
        suid: null,
        recordList: JSON.parse(localStorage.getItem('recordList')) ? JSON.parse(localStorage.getItem('recordList')) : [], //交易记录
        initWalletFlag: false,
        shoppingCartNumber: '',
        Loading: false,
        isNeedReload: false,
        walletProvider: false,
        renewalList: [],
        favoriteCount: 0,
        drawerShow: false,
        showBindingBox: false,
        web3Success: false,
        showConnect: false,
        showSwitchChainPromt: false,
        showConnectCallBack: false,
        showConnectCallBack1: false,
        showConnectIsQuery: false,
        signConnect: false,
        signConnectCallBack: false,
        signConnectCallBack1: false,
        signConnectCallBack2: false,
        signConnectCallBack3: false,
        signConnectCallBack4: false,
        signConnectCallBack5: false,
        signConnectCallBack6: false,
        signConnectCallBack7: false,
        signConnectCallBack8: false,
        signConnectCallBack9: false,
        signConnectCallBack10: false,
        signConnectCallBack11: false,
        signConnectCallBack12: false,
        signConnectCallBackHeader: false,
        footVisible: true,
        connectPosition: 0,
        positionItem: {},
        contentBox: '',
        showUnsupported: false,
        connectChain: false,
        selChainId: '',
        isSupportChainid: [],
        isSupportConnect: false,
        walletTypeLogin: localStorage.getItem('walletTypeLogin') || 99,
        getCfgTokenList: [],
        getWaletsList: '',
        getChainsList: '',
        registerItem: '',
        registerShowItem: false,
        registerDomainItem: {},
        getItemNum: ''
    },

    getters: {
        // isMobile(state){
        //   return this.state.isMobile
        // }
    },

    mutations: {
        setIsMobile(state, payload) {
            state.isMobile = payload
            localStorage.setItem('isMobile', payload)
        },

        setLanguge(state, payload) {
            state.language = payload
            localStorage.setItem('language', payload)
        },
        setProvider(state, payload) {
            state.provider = payload
        },

        setProviderInit(state, payload) {
            state.providerInit = payload
        },

        setWalletProvider(state, payload) {
            state.walletProvider = payload
        },

        setShoppingCartNumber(state, payload) {
            state.shoppingCartNumber = getCardNum(state)
        },

        setLoading(state, payload) {
            state.Loading = payload
        },

        setPrimaryName(state, payload) {
            sessionStorage.setItem('primaryName', payload)
            state.primaryName = payload
        },

        setIsNeedReload(state, payload) {
            state.isNeedReload = payload
        },

        setChainid(state, payload) {
            state.chainid = payload
        },

        setSuid(state, payload) {
            state.suid = payload
        },
        setAccount(state, payload) {

            sessionStorage.setItem('account', payload)
            state.account = payload
        },

        setAccessToken(state, payload) {
            if (payload) {
                localStorage.setItem('tokenAccount', state.account)
                localStorage.setItem('token', payload)
            }

            state.accessToken = payload
        },

        setInitWalletFlag(state, payload) {
            state.initWalletFlag = payload
        },

        setSelChainId(state, payload) {
            state.selChainId = payload
        },


        setCartNumber(state, payload) {
            localStorage.setItem('cartNumber', payload)
            state.cartNumber = payload
        },

        setPositionItem(state, payload) {
            state.positionItem = payload
        },

        setistokenList(state, payload) {
            state.unistokenList = payload
        },

        setwalletTypeLogint(state, payload) {
            localStorage.setItem('walletTypeLogin', payload)
            state.walletTypeLogin = payload
        },

        setRenewalList(state, payload) {
            state.renewalList = payload
        },

        setFavoriteCount(state, payload) {
            state.favoriteCount = payload
        },

        setDrawerShow(state, payload) {
            state.drawerShow = payload
        },

        setShowBindingBox(state, payload) {
            state.showBindingBox = payload
        },


        setWeb3Success(state, payload) {
            state.web3Success = payload
        },

        setShowConnect(state, payload) {
            state.showConnect = payload
        },

        setShowUnsupported(state, payload) {
            state.showUnsupported = payload
        },

        setConnectPosition(state, payload) {
            state.connectPosition = payload
        },

        setShowConnectCallBack(state, payload) {
            state.showConnectCallBack = payload
        },

        setShowConnectCallBack1(state, payload) {
            state.showConnectCallBack1 = payload
        },

        setContentBox(state, payload) {
            state.contentBox = payload
        },

        setConnectChain(state, payload) {
            state.connectChain = payload
        },

        setIsSupportChainid(state, payload) {
            state.isSupportChainid = payload
        },

        setIsSupportConnect(state, payload) {
            state.isSupportConnect = payload
        },
        setRegisterShowItem(state, payload) {
            state.registerShowItem = payload
        },
        setRegisterDomainItem(state, payload) {
            state.registerDomainItem = payload
        },

        setSignConnectCallBack(state, payload) {
            if (state.connectPosition == 1 || state.connectPosition == 2 || state.connectPosition == 3 || state.connectPosition == 4) {
                state.signConnectCallBack = payload
            } else if (state.connectPosition == 5) {
                state.signConnectCallBack1 = payload
            } else if (state.connectPosition == 6) {
                state.signConnectCallBack3 = payload
            } else if (state.connectPosition == 7) {
                state.signConnectCallBack4 = payload
            } else if (state.connectPosition == 8) {
                state.signConnectCallBack5 = payload
            } else if (state.connectPosition == 9) {
                state.signConnectCallBack6 = payload
            } else if (state.connectPosition == 10) {
                state.signConnectCallBack7 = payload
            } else if (state.connectPosition == 11) {
                state.signConnectCallBack8 = payload
            } else if (state.connectPosition == 12 || state.connectPosition == 13) {
                state.signConnectCallBack9 = payload
            } else if (state.connectPosition == 14) {
                state.signConnectCallBack10 = payload
            } else if (state.connectPosition == 15) {
                state.signConnectCallBackHeader = payload
            } else if (state.connectPosition == 16) {
                state.signConnectCallBack = payload
            } else if (state.connectPosition == 17) {
                state.signConnectCallBack11 = payload
            } else if (state.connectPosition == 18) {
                state.signConnectCallBack11 = payload
            } else if (state.connectPosition == 19) {
                state.signConnectCallBack12 = payload
            }


        },
        setSignConnectCallBackHeader(state, payload) {
            state.signConnectCallBackHeader = payload
        },
        setShowConnectIsQuery(state, payload) {
            state.showConnectIsQuery = payload
        },

        setSignConnect(state, payload) {
            state.signConnect = payload
        },

        setFootVisible(state, payload) {
            state.footVisible = payload
        },

        setCfgTokenList(state, payload) {
            state.getCfgTokenList = payload
        },

        setChainsList(state, payload) {
            state.getChainsList = payload
        },

        setWaletsList(state, payload) {
            state.getWaletsList = payload
        },

        setItemNum(state, payload) {
            state.getItemNum = payload
        },

        setRegisterItem(state, payload) {
            state.registerItem = payload
        },

    },

    modules: {}
})