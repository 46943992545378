import _objectSpread from "/root/.jenkins/workspace/prod-dapp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import Connect from '@/components/connect/connect';
import { mapState } from 'vuex';
export default {
  name: 'empty',
  components: {
    Connect: Connect
  },
  props: {
    emptyStr: {
      "default": ''
    },
    haveBtn: {
      "default": false
    },
    toRegister: {
      "default": false
    },
    image: {
      "default": ''
    },
    noImage: {
      "default": false
    },
    tabStatus: {
      "default": 1
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState(['account'])),
  methods: {
    handleQuery: function handleQuery() {
      var router = '/';
      if (this.tabStatus == 2) {
        router = '/?istldSearch=2';
      }
      this.$router.push(router);
    }
  }
};