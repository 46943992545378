import { defineComponent } from 'vue';
export default defineComponent({
  name: 'whatIsNew',
  data: function data() {
    return {
      isShowNew: false,
      noticeList: []
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.isShowNew = false;
    }
  }
});