import Axios from 'axios'
import store from '../store/index.js'
import {
    showTip
} from '@/utils/utils'
import i18n from '@/language/i18n'
import {
    signLogin
} from '@/utils/connect'
const qs = require('qs')

let isRelogin = false;

const Ajax = Axios.create({
    timeout: 90000,
})

Ajax.interceptors.request.use(config => {
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
Ajax.interceptors.response.use(res => {
        const code = res.data.code || 200;
        if (code != 200) {
            try {
                errorStatus(res)
            } catch (error) {
                //console.log(error)
            }
        }
        return res
    },
    error => {
        errorStatus(error)
        return Promise.reject(error)
    }
)
const errorStatus = (err) => {
    store.commit('setLoading', false)
    if (err.response && err.response.status == 401) {
        localStorage.removeItem('token');
        store.commit('setAccessToken', '')
        if (!isRelogin) {
            isRelogin = true;
            store.commit('setSignConnect', false)
            store.commit('setSignConnect', true)
            isRelogin = false;
        }

    }
    console.log(err)
    if (err.data && err.data.code && err.data.code != 4007 && err.data.code != 4406 && err.data.code != 4407) {
        if (err.data.url.indexOf('/domain/search') == -1 && err.data.url.indexOf('/invite/info') == -1 && err.data.url.indexOf('/domain/tld/search') == -1 &&
            !(err.data.code == 4103 && err.data.url.indexOf('/domain/detail') != -1)) {
            showTip(i18n.global.t(`message.err_${err.data.code}`), 'error', )
        }
    } else if (err.data.code == 4406 || err.data.code == 4407) {
        showTip(i18n.global.t(`${err.data.msg}`), 'error', )
    }
    //console.log("err:", err)
}

const API = {
    get(url, params, conf, mustToken = true) {

        return new Promise(async (resolve, reject) => {

            try {
                if (mustToken && !store.state.accessToken) {
                    await signLogin()
                }

                const config = conf || {}
                Ajax.defaults.headers['Content-Type'] = 'application/json'
                if (mustToken) {
                    Ajax.defaults.headers['Authorization'] = 'Bearer ' + store.state.accessToken
                }

                Ajax.get(url, params, config).then(res => {
                    if (res.data.code === 200) {
                        resolve(res.data.data)
                    } else {
                        reject(res)
                    }
                }).catch((err, errType) => {
                    reject(err, errType)
                })
            } catch (error) {
                reject(error)
            }

        })
    },

    post(url, params, conf, mustToken = true) {

        return new Promise(async (resolve, reject) => {

            try {
                if (mustToken && !store.state.accessToken) {
                    await signLogin()
                }
                if (conf && conf.headers && conf.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
                    params = qs.stringify(params)
                }

                if (url.indexOf('/domain/sign') != -1) {
                    Ajax.defaults.timeout = 180000
                }

                Ajax.defaults.headers['Content-Type'] = 'application/json'
                if (mustToken) {
                    Ajax.defaults.headers['Authorization'] = 'Bearer ' + store.state.accessToken
                }
                Ajax.post(url, params, conf).then(res => {
                    if (res.data.code === 200) {
                        resolve(res.data.data)
                    } else {
                        reject(res)
                    }
                }).catch((err, errType) => {
                    reject(err)
                })
            } catch (error) {
                console.log(error)
                reject(error)
            }

        })
    },

    upload(url, formDate, conf, mustToken = true) {
        return new Promise(async (resolve, reject) => {
            try {
                if (mustToken && !store.state.accessToken) {
                    await signLogin()
                }

                if (!conf) {
                    conf = {
                        'Content-type': 'multipart/form-date'
                    }
                }

                Ajax.defaults.headers['Content-Type'] = 'multipart/form-date'
                if (mustToken) {
                    Ajax.defaults.headers['Authorization'] = 'Bearer ' + store.state.accessToken
                }

                Ajax.post(url, formDate, conf).then(res => {
                    if (res.data.code === 200) {
                        resolve(res.data.data)
                    } else {
                        reject(res)
                    }
                }).catch((err, errType) => {
                    reject(err)
                })
            } catch (error) {
                reject(error)
            }
        })
    },

    loginPost(url, params, conf) {
        if (conf && conf.headers && conf.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            params = qs.stringify(params)
        }

        Ajax.defaults.headers['Content-Type'] = 'application/json'

        return new Promise((resolve, reject) => {
            Ajax.post(url, params, conf).then(res => {
                try {
                    if (res.data.code === 200) {
                        resolve(res.data.data)
                    } else {
                        reject(res)
                    }
                } catch (error) {
                    //console.log(error)
                }
            }).catch((err, errType) => {
                reject(err)
            })
        })
    },

    postCode(url, params, conf) {
        if (conf && conf.headers && conf.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            params = qs.stringify(params)
        }
        Ajax.defaults.headers['Content-Type'] = 'application/json'
        Ajax.defaults.headers['Authorization'] = 'Bearer ' + store.state.accessToken

        return new Promise((resolve, reject) => {
            Ajax.post(url, params, conf).then(res => {
                if (res.status === 200) {
                    resolve(res.data)
                } else {
                    reject(res.data.message)
                }
            }).catch((err, errType) => {
                reject(err, errType)
            })
        })
    },

    delete(url, data, conf, mustToken = true) {
        return new Promise(async (resolve, reject) => {

            try {
                if (mustToken && !store.state.accessToken) {
                    await signLogin()
                }

                Ajax.defaults.headers['Content-Type'] = 'application/json'
                if (mustToken) {
                    Ajax.defaults.headers['Authorization'] = 'Bearer ' + store.state.accessToken
                }

                Ajax.delete(url, {
                    data
                }, conf).then(res => {
                    if (res.data.code === 200) {
                        resolve(res.data.data)
                    } else {
                        reject(res)
                    }
                }).catch((err, errType) => {

                    reject(err)
                })
            } catch (error) {
                reject(error)
            }

        })
    },

}

export {
    API
}