import BigNumber from 'bignumber.js'


function big(val) {
    return new BigNumber(val);
}

var floatOPS = function () {
    function operation(a, b, digits, op) {
        let result = null
        let pow = Math.pow(10, digits + 1 || 3)
        switch (op) {
            case 'a':
                result = (parseInt(Number(big(a).plus(b).toString()) * pow) / pow).toFixed(digits || 2)
                break;
            case 's':
                result = (parseInt(Number(big(a).minus(b).toString()) * pow) / pow).toFixed(digits || 2)
                break;
            case 'm':
                result = (parseInt(Number(big(a).times(b).toString()) * pow) / pow).toFixed(digits || 2)
                break;
            case 'd':
                result = (parseInt(Number(big(a).div(b).toString()) * pow) / pow).toFixed(digits || 2)
                break;
            case 'u':
                result = (parseInt(Number(big(a).div(b).toString()) * pow) / pow).toFixed(digits || 2)
                break;
            default:
                result = (parseInt(Number(a) * pow) / pow).toFixed(digits || 2)
        }
        return parseFloat(result)
    }

    function a(a, b, digits) {
        return operation(a, b, digits, 'a')
    }

    function s(a, b, digits) {
        return operation(a, b, digits, 's')
    }

    function m(a, b, digits) {
        return operation(a, b, digits, 'm')
    }

    function d(a, b, digits) {
        return operation(a, b, digits, 'd')
    }

    function num(a, b, digits) {
        return operation(a, b, digits)
    }
    return {
        a: a,
        s: s,
        m: m,
        d: d,
        num: num
    }
}();
export default floatOPS