import _objectSpread from "/root/.jenkins/workspace/prod-dapp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import SigninApi from '@/api/modules/signin';
import { localStorage } from '@/store/storage';
import { mapState } from 'vuex';
export default {
  name: 'serviceTerms',
  data: function data() {
    return {
      isShowTerms: false,
      isAgree: false,
      loading: false,
      tosId: ''
    };
  },
  computed: _objectSpread({}, mapState(['account', 'accessToken'])),
  watch: {
    account: {
      handler: function handler(to, from) {
        if (to) {
          this.isShowTerms = false;
        }
      },
      deep: true,
      immediate: false
    },
    accessToken: {
      handler: function handler(to, from) {
        if (to) {}
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    handleCheck: function handleCheck() {
      this.isAgree = !this.isAgree;
    },
    handleCommit: function handleCommit() {
      var _this = this;
      this.loading = true;
      SigninApi.tosAgree({
        tosId: this.tosId
      }).then(function (res) {
        _this.isShowTerms = false;
        _this.loading = false;
        _this.$emit('agreeService');
      })["catch"](function (err) {
        _this.loading = false;
        //console.log('tosAgree.err====',err)
      });
    },
    handleClose: function handleClose() {
      localStorage.removeItem('token');
      sessionStorage.removeItem('account');
      this.$store.commit('setAccount', '');
      this.$store.commit('setAccessToken', '');
      this.$store.commit('setPrimaryName', '');
      this.$store.commit('setShowConnectIsQuery', false);
    }
  }
};