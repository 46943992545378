export default {
    message: {
        input_placeholder: "請輸入",
        email_placeholder: "請輸入正確的郵箱",
        title_placeholder: "標題",
        content_placeholder: "輸入內容",
        email_err: "請輸入正確的郵箱",
        select_err: '請選擇',
        input_err: '請輸入',
        address_placeholder: "請輸入地址",
        address_error: '請輸入正確的地址',
        balance_error: '您的餘額不足以支付操作費用',
        owner_error: '您未擁有該域名',
        unsupport_chain: '當前選擇的鏈不支持該操作，是否切換到兼容的鏈？',
        no_account: '當前錢包沒有賬號',
        sys_error: '系統錯誤',
        recaptcha_error: '人機驗證失敗，請稍後重試',
        reject_error: '用戶已拒絕',
        net_error: '網絡錯誤',
        switch_err: '當前錢包不支持切換網路，請升級錢包APP',
        add_chain_err: '切換失敗，請嘗試手動添加網路',
        err_1001: '您未擁有該TLD',
        err_4000: '參數不正確',
        err_4100: '域名不能注冊',
        err_4101: '無效內容，請重新輸入',
        err_4102: '輸入的域名超過長度限製，請重新輸入',
        err_4103: '未註冊的域名',
        err_4104: '域名不存在',
        err_4105: '該域名已注冊',
        err_4106: '該域名已在其他鏈注冊',
        err_4107: '網絡錯誤，請刷新重試',
        err_4108: '該域名暫未釋放，請重新輸入',
        err_4109: '內容帶有敏感詞或不合法，請重新輸入',
        err_4110: '內容帶有敏感詞或不合法，請重新輸入',
        err_4111: '您的地址違反社區規定，暫無權限註冊域名',
        err_4112: '域名處於拍賣期',
        err_4113: '域名不在拍賣期内',
        err_4114: '不支持的tld',
        err_4115: '內容帶有敏感詞或不合法，請重新輸入',
        err_4116: '首次注冊需以整年為基礎周期',
        err_4117: '超過批次注冊域名最大數量',
        err_4118: '不允許同時使用邀請碼和代金券',
        err_4119: '代金券不存在',
        err_4120: '無效的代金券',
        err_4121: '代金券已使用過',
        err_4122: '已買斷域名無需續費',
        err_4123: '域名正在遷移中，操作被拒絕',
        err_4124: '當前選擇的代金券已被鎖定，五分鐘後可使用',
        err_4125: '域名後綴暫未開放',
        err_4126: '域名他人註冊中，已被鎖定5分鐘，請稍後嘗試',
        err_4127: '該域名暫不支持在當前鏈註冊',
        err_4128: '該域名暫不支持轉移到選擇的目標鏈',
        err_4201: '地址未在本系統注冊',
        err_4202: '郵件驗證碼驗證不通過',
        err_4203: '郵件驗證碼已經過期',
        err_4204: '非法操作',
        err_4205: '邀請碼不存在',
        err_4206: '返佣配置未配置',
        err_4207: '系统配置错误:未找到email相关配置',
        err_4301: '活動不存在',
        err_5000: '未知錯誤',
    },

    header: {
        project_name: 'OpenName',
        name_fi: 'NameFi',
        register: '註冊',
        query: '查詢',
        my_domain: '我的域名',
        pricing: '定價',
        invite: '邀請獎勵',
        activities: '活動',
        collect: '收藏',
        other: '其他',
        connect: "鏈接錢包",
        not_logged_in: '請先鏈接錢包',
        show_all: '顯示所有結果 >',
        search_placeholder: '搜索域名或地址',
    },

    connectWallet: {
        connect_title: '連接您的錢包',
        connect_desc: '您可以在多條公鏈註冊並使用您的域名。',
        select_network: '選擇網絡',
        select_wallet: '連接錢包',
        connect_to_ethereum: '連接至Ethereum',
        connect_to_arbitrum: '連接到Arbitrum ',
        connect_to_optimism: '連接到OP Mainnet ',
        connect_to_polygon: '連接到Polygon',
        connect_to_bnb: '連接到BNB Chain',
        connect_to_zksync: '連接到zkSync Era',
        connect_to_base: '連接到Base Mainnet',
        connect_to_goerli: '連接到Goerli',
        connect_to_arbitrum_goerli: '連接到Arbitrum Goerli',
        connect_to_optimism_goerli: '連接到Optimism Goerli',
        connect_to_mumbai: '連接到Mumbai',
        connect_to_bnb_test: '連接到BNB Test',
        connect_to_zksync_test: '連接到zkSync Era Test',
        connect_to_base_test: '連接到 Base Testnet',
        connect_wallet: '鏈接錢包',
    },

    footer: {
        project_name: 'Project Name',
        privacy_policy: '隱私政策',
        user_agreement: '使用條款',
        copy_right: 'Copyright'
    },

    publish: {
        cancleLembrete: '取消提醒',
        cancle: "取消",
        confirm: "確定",
        submit: "提交",
        close: '關閉',
        delete: '刪除',
        set: '設置',
        expiration_time: '到期時間',
        remove_favorite: '取消收藏',
        coll_success: '收藏成功',
        copy_success: '複製成功',
        download_success: '下載成功',
        copy_fail: '複製失敗',
        copy_address: '複製',
        network_error: '暫不支持該網絡',
        prompt: '提示',
        successful: '操作成功',
        failed: '失敗！',
        save: '保存',
        copy_link: '複製鏈接',
        unsupported_chain: "當前選擇的鏈不支持該操作，是否切換到兼容的鏈？",
        switch_chain: '切換鏈',
        wallect_insufficient: '您的錢包餘額不足',
        creation: '創建時間',
        year: '/年',
        buyout: '/終生',
        show_more: ' 展開',
        show_less: ' 收起',
    },

    query: {
        query_title: '擁有您',
        query_title1: '喜歡的',
        query_title2: 'Web3',
        query_title3: '域名',
        query_title4: '和',
        query_title5: 'TLDs',
        query_placeholder: '搜索域名或地址，例如：i.love.you',
        select_cancle: '取消選擇後綴',
        query: '查詢',
        opened_suffix: '已開放後綴',
        more_opening_suffix: '更多後綴開放中',
        query_price: '查詢後綴價格表 >',
        current_progress: '當前開放進度',
        sub_progress1: '1、目前只支持純阿拉伯數字、英文字母、特殊符號”-“、emoji。',
        sub_progress2: '2、特殊符號”-“不支持用在開頭、不可單獨註冊和連續使用；',
        sub_progress3: '3、註冊域名時，必須使用TLD，例如“.abc”；多個層級請用.區分層級。',
        see_more: '查看更多字符集規則 >',
        query_error: '輸入的域名無效，請判斷輸入格式是否不帶TLD（例如.abc)的域名或使用了不支持的字符。',
        registrable: '可註冊',
        has_registered: '已註冊',
        available: '可註冊',
        unavailable: '不可用',
        grace_period: '寬限期',
        btn_register: '註冊',
        btn_view: '查看',
        search_result: '搜索結果',
        search_result1: '結果',
        registrable_only: '只顯示可註冊域名',
        hot_suffix: 'Hot suffix',
        new_suffix: 'New suffix',
        free_suffix: 'Free suffix',
        discount_suffix: ' Off',
        report: '舉報',
        favorite: '收藏',
        reporting_type: '請選擇你的舉報類型',
        report_reason1: '種族歧視',
        report_reason2: '涉及黃色、暴力內容',
        report_reason3: '涉及未成年人',
        report_reason4: '其他',
        report_supplementary: '補充說明',
        report_email: '郵箱地址(选填)',
        report_success: '舉報成功',
        submit: '確定',
        service_terms: '使用條款',
        terms_desc: '開始使用之前，請仔細閱讀平台的',
        terms_desc1: '《使用條款》',
        terms_desc2: '。',
        agree_terms: '我已閱讀並同意使用',
        funny_name: '好玩的域名',
        recently_registered: '近期註冊',
        premium: '溢價',
        year: '/年',
        buyout: '/終生',
        white_list_tips1: '至',
        white_list_tips2: '，所有收費的後綴',
        white_list_tips3: '個域名可以免首年註冊費。',
        white_list_tips4: '',
        white_list_tips5: '',
        white_list_tips6: '',
        white_list_tips7: '，您有',
        white_list_part_tips2: '，以下收費的後綴，您有',
        white_list_part_tips3: '個域名可以免首年註冊費：',
        white_list_assign_tips2: '，以下域名已為您保留，僅限您註冊:',
        white_list_title: '白名單資格',
        white_list_search: '點擊搜索',
        except_tip: '除',
        is_un_support1: '該后缀僅支持在',
        is_un_support2: '上註冊。',
        domain: '域名',
        domain_tld: 'TLDs',
        domain_tld_tips: "頂級域名是域名中最高級別的部分，位於域名的最後一個點之後。例如，在域名 'thank.you' 中，'.you' 就是 TLD。",
        domain_tld_tips1: '註冊頂級域名（TLD），當其他用戶在此TLD下註冊域名時，該TLD持有者將獲得註冊費作為收益。',
        domain_lab_tips: '年：註冊費每年繳交。',
        domain_lab_tips1: '終身：一次性付款，無需續約費用。終身持有。',
        domain_tld_pl: '搜索TLD或地址，例如：.abc',
        domain_white_tld: '，以下TLD已為您保留，僅限您註冊',
        view_all: '查看全部',
        tld_view_all: "沒有您喜歡的TLDs？",
        tld_view_all1: "🔍試試搜索吧。",
        domain_registration: '🥳 最新註冊:',
        domain_g: '1分鐘',
        domain_xsq: '小時前',
        domain_fzq: '分鐘前',
        domain_tq: '天前',

    },

    priceList: {
        price_table: '定價',
        search_placeholder: '搜索TLD',
        suffix: '後綴',
        sort: '分類',
        level_1_price: '一級域名',
        level_1_price_mobile: '一級域名',
        total_registrations: '註冊數',
        registrations: '註冊數',
        multistage_price: '多級域名',
        multistage_price_mobile: '多級域名',
        instructions: '說明',
        free: '免費',
        pre_year: '每年：$',
        buyout: '/終生',
        characters_price: '字符：$',
        tips_4_above: '4字符及以上: $',
        characters: '字符',
        year: '/年',
        header_tld: 'TLD',
        header_price: '價格',
        header_divide: '分成比例',
        header_explain: '說明',
        defult: 'Custom TLDs',
        share_ratio_tips: 'OpenName 用戶註冊自己的 TLDs 並賺取一定比例域名註冊費。'
    },

    myDomain: {
        set_primary_name: '設置主名稱',
        no_domain_set: '您尚未擁有域名',
        wallet_address: '錢包地址',
        bind_domain: '綁定域名（反向記錄）',
        bind_desc: '我們支持您跨鏈設置域名，設置後原本在你的应用中显示地址的地方会显示你的账户名。比如当你用钱包地址登录 DApp 后，你的账户名会像你的用户名一样显示出来。請注意，您只能選擇已正向解析至該地址的域名。請注意，您只能選擇已正向解析至該地址的域名。',
        select_domain_placeholder: '選擇你要解析至該地址的域名',
        none_domain: '您尚未擁有域名，或者您的域名地址記錄尚未設置成該地址。您只能選擇已經正向解析至這個地址的域名。',
        delete_tip: '當前域名已解析，確定刪除該記錄嗎？',
        my_domain: '我的域名',
        batch_renewal: '域名續費',
        expiration_reminder: '到期提醒',
        grace_period_until: '寬限期至：',
        renewal: '續費',
        cross_chain_transfer: '跨鏈轉移',
        bridging: '跨鏈中 >',
        tips: '當前域名變成免費域名，您在此期間續費只需要支付Gas。即可永久免費擁有該域名。是否立即續費？',
        transfer_desc: '您正在將的所有權或者管理權轉讓給其他人，請謹慎操作。',
        renewal_cycle: '續費週期',
        renewal_time: '續費時間',
        transfer_placeholder: '選擇可用的代金券',
        success: '續費成功',
        setting_success: '設置成功',
        setting_error: '解析失敗',
        remove_success: '刪除成功',
        remove_fail: '刪除失敗',
        bought_out_held: '終生',
        bought_out_held1: '終生',
        bought_out_held2: '',
        grace_period_to: '寬限期至',
        about_to_expire: '即將到期',
        free_suffix: '免費後綴，終身持有',
        free_suffix1: '免費後綴',
        free_suffix2: '終身持有',
        bought_out: '已買斷',
        week_4: '4週',
        week_3: '3週',
        week_2: '2週',
        owner: '持有者',
        manager: '管理員',
        all: '全部',
        permission: '權限',
        search_placeholder: '搜索域名',
        select_all: '全部選擇',
        domainAll: '查看全部域名',
        err_title: '提示',
        err_tip1: '很抱歉的通知您，',
        err_tip2: '由於您的域名',
        err_tip6: '違反',
        err_tip3: "《使用條款》",
        err_tip4: '關於敏感詞的約定，因此已被系統刪除，並且任何人都無法再次註冊。 ',
        err_tip5: '系統已向您發放代金券作為補償。如有疑問，請聯繫我們的客服團隊。',
        err_bottom: '查看代金券 > ',
        registering: '註冊中',
        his_name: '他的域名',
        no_transfer: '寬限期不支持跨鏈轉移',
        forbid_transfer: '禁止跨鏈轉移。',
        domain_loca_chain: '域名所在鏈',
        tld_loca_chain: "TLD所在鏈：",
        domain_screen: '篩選',
        domain_chain: '鏈',
        domain_jurisdiction: '類型',
        domain_reset: '重置',
    },

    setPrimaryName: {
        set_primary_name: '設置主名稱',
        set_desc: '設置主名稱，以此作為您在不同Dapps的統一身份或用戶名。設置後，您可以隨時更改。',
        current: "當前設置",
        confirm_Settings: '設置',
        release_setting: '刪除主名稱',
        release_tip: '即將刪除主名稱設置，你確定嗎？',
        set_tip1: '1. 將域名指向您的地址',
        set_tip2: '2. 完成設置主名稱',

    },

    manager: {
        edit: '編輯內容',
        set_primary: '設置為主名稱',
        set_primary_desc: '設置主名稱，以此作為您在不同Dapps的統一身份或用戶名。設置後，您可以隨時更改。',
        empty_desc: '暫無任何說明~~',
        forever: '長期有效',
        name_info: '域名信息',
        parent_name: '父域名',
        owner: '註冊人',
        manager: '管理員',
        transfer: '轉讓',
        modify: '修改',
        expiration_time: '到期時間',
        address: '地址',
        social_media: '社交媒體',
        website: '網站',
        other: '其他',
        resolver: '解析器',
        modify_manager: '修改管理員',
        modify_desc: '您正在將管理權分配給其他人（您仍然擁有持有者權利）請謹慎操作。',
        new_manager: '新管理員：',
        transfer_registrant: '轉讓註冊人',
        transfer_desc: '您正在修改域名的持有者權限，修改後您將永久失去對該賬戶的控制權。請謹慎操作。',
        transfer_tld_desc: '您正在修改TLD的持有者，修改後您將永久失去對該TLD的控制權，且註冊收入將轉移給新的持有者，請謹慎操作。',
        domain_name: '域名',
        current_address: '當前地址',
        receiver: '接收者:',
        edit_add: '+ 添加',
        edit_address_desc: '你可以將自己每條鏈的收款地址添加到你的域名數據中，當你的朋友向你轉賬時，他只需要在收款人輸入框的位置，輸入你的域名 賬戶即可。',
        edit_parser_desc: '*解析器裡存儲著您所有的記錄，如地址、文本記錄等。修改之前，您最好了解一下解析器的原理。',
        edit_success: '編輯成功',
        edit_fail: '編輯失敗',
        default_resolver: '默認解析器',
        input_parser_placeholder: '輸入邀請碼可以獲得折扣.',
        transfer_success: '轉讓成功',
        transfer_fail: '轉讓失败',
        modify_success: '修改成功',
        modify_fail: '修改失败',
        transfer_self_err: '請勿轉讓給自己',
        modify_self_err: '請勿修改給自己',
        date: '日期',
        help_renewal: '為Ta續費',
    },

    transfer: {
        transfer_domain_name: '轉移域名',
        transfer_domain_tld: '轉移TLD',
        domain: '域名',
        current_chain: '當前鏈',
        from: '原始鏈',
        bridge_to: '轉移至',
        transfer_tip1: '跨鏈轉移需要支付Gas（以鏈上實時Gas為準），當轉移開始，將無法取消。',
        transfer_tip2: '轉移過程中，您可以隨時離開該頁面，在“我的域名”頁可以重新進入該頁面查看轉移狀態。',
        transfer_tip2_tld: '轉移過程中，您可以隨時離開該頁面，在“我的TLD”頁可以重新進入該頁面查看轉移狀態。',
        chain_type: '鏈類型',
        chain_placeholder: '請選擇轉移的鏈',
        address: '地址',
        address_placeholder: '請輸入錢包地址',
        start_transfer: '開始轉移',
        finish_tip: '🎉 您的域名已轉移成功，請切換鏈和錢包查看該域名。',
        bridging: '跨鏈中',
        step_title: '轉移需要3個步驟',
        step_1: '第一步',
        step_1_desc: '在當前鏈上銷毀域名',
        step_1_desc_tld: '在當前鏈上銷毀TLD',
        step_1_finish: '到區塊鏈瀏覽器查看詳情 >',
        step_2: '第二步',
        step_2_desc: '通過LayerZero發送跨鏈轉移指令',
        step_2_tip: '轉移過程可能需要等待幾分鐘',
        step_3: '第三步',
        step_3_desc: '在您選擇轉移的鏈上鑄造您的域名',
        step_3_desc_tld: '在您選擇轉移的鏈上鑄造您的TLD',
        step_3_tip: '如長時間無法完成鑄造，',
        step_3_tip1: '請與客服聯繫',
        step_finish: '进入 LayerZero scan 查看详细信息 >',
        step_wait: '等待中',
        step_completed: '已完成',
        transfer_fail: '轉移失败',
        bridge_success: '轉移成功！',
        bridge_success_desc: '您的域名已轉移成功，請切換鏈和帳戶查看',
        bridge_success_tld: '您的TLD已轉移成功，請切換鏈和帳戶查看',
        powered_by: '由 LayerZero 提供支持'
    },

    signin: {
        enquiry: '查詢',
        registration: '註冊',
        register: '註冊',
        year: '年',
        m_year: '年',
        m_years: '年',
        tips: '請輸入邀請碼',
        method: '註冊週期',
        number_year: '年限',
        expense: '註冊費用',
        invitation_code: '邀請碼',
        coupon: '代金券',
        coupon_placeholder: '可選擇多張代金券',
        total_cost: '預估总費用',
        cost: '預估費用',
        original_price: '原價',
        buy_out: '終生',
        free: '免费',
        premium: '溢價',
        premium_main: '溢價提醒',
        premium_main_price: '输入提醒价',
        premium_tips: '溢價到達該價格時間（當地時區）',
        nowPremium: '当前溢價',
        introduce_title: '溢價曲線',
        introduce_content_tip: '為了給剛過期的名稱提供公平的註冊機會，溢價從 1 億美元開始，在 21 天內逐步降至 0 美元。',
        introduce_item_left: '當前購入價格為 ',
        introduce_item_right: '距離結束還有2天（共21天）',
        remind: '提醒',
        free_tips: '您可用的餘額不足，無法註冊',
        number: '數量',
        not_sufficient_funds: '您的可用餘額不足，無法註冊',
        is_register: '該域名正在註冊中',
        hold_for_life: '終身持有',
        target_date: '到此價格',
        target_date_me: '到此價格提醒',
        code_error: '無效',
        already_set: '已經設置',
        register1: '1、確認錢包簽名',
        register2: '2、鎖定您的域名',
        register3: '3、付款完成註冊',
        register4: '2、鎖定您的TLD',
        confirm3: '請付款完成續費',
        first_year_free: 'First year free',
        select_coupon: '選擇代金券',
        tips_rate: '當您成功註冊後並開始運營該TLDs，所獲得的域名註冊收入，將有一定的比例分給平台。',
        select_ayment: '支付方式',
    },

    email: {
        email_address: '請輸入有效的郵箱地址',
        code_add: '請輸入郵箱驗證碼',
        pop_tips: '您如果存在30天內到期的域名，我們將通過您綁定的郵箱發送提醒郵件（每週一次）。',
        get_code: '發送驗證碼',
        band_tips: '請綁定郵箱，接收提醒，',
        re_code: '重新發送',
        email_new_address: '請輸入新的有效郵箱地址',
        emailError: '郵箱格式錯誤',
        ver_code_err: '驗證碼錯誤',
        email_tips: '綁定郵箱',
        email_code_ver: '輸入綁定郵箱的驗證碼',
        emailEdit: '修改綁定郵箱',
        currentMailbox: '當前綁定郵箱地址：',
        editTips: '輸入綁定郵箱的驗證碼',
        next: '下一步',
        last_step: '返回',
        binding: '確認綁定',
        un_binding: '未綁定',
        reminder_email: '提醒郵箱',
        success: '綁定成功',
        send_success: '發送成功',
        email_error: '郵箱不能重複',
        email_system_err: '郵件發送不成功，請稍後再試'
    },

    ShoppingCart: {
        title: '批量註冊',
        close: '清空',
        year: '年',
        m_year: '年',
        m_years: '年',
        method: '註冊週期',
        buy_out: '終生',
        number_year: '年限',
        invitation_code: '邀請碼',
        code_tips: '請輸入邀請碼',
        coupon: '代金券',
        coupon_placeholder: '選擇代金券（可多选）',
        number: '數量',
        total_cost: '預估总費用',
        original_price: '原價',
        register: '註冊',
        tips: '輸入邀請碼，立減註冊費',
        empty: '無內容',
        empty1: '您還未擁有域名',
        empty2: '您還未擁有TLD',
        empty1_desc1: '現在',
        empty1_register: '註冊',
        empty1_desc2: '一個吧～',
        searh_empty: '無搜索結果',
        hold_for_life: '終身持有',
        estimated_gas: 'Gas',
        estimated_gas_fee: '預估gas費',
        discount: '立減',
        discount1: '',
        registered: '已註冊',
        unavailable: '不可用',
        unsupported_chain: '不支持的鏈',
        card_limit: '最多可添加15個域名',
        un_support_payments1: ' 該域名不支持',
        un_support_payments2: '支付，僅限'
    },

    favorite: {
        favorites: '我的收藏',
        empty_desc: '還沒有喜歡的域名呢，去看看心儀的域名吧~',
        empty_desc1: '還沒有喜歡的TLD呢，去看看心儀的TLD吧~',
        query: '查詢域名',
        query1: '查詢TLD',
        registration: '可註冊',
        registration_premium: '可通過支付溢價進行註冊',
        unregistrable: '不可註冊',
        grace_period: '寬限期到',
        view: '查看',
    },

    coupon: {
        coupon_list: '代金券',
        coupon: '代金券',
        release_time: '發放時間',
        employ: '已使用',
        unused: '未使用',
        expired: '已到期',
        empty: '沒有更多代金券',
        expiration: "過期",
        permanently_valid: "永久有效",
        available_suffixes: "可用後綴",
        universal_voucher: "通用優惠券",
        tips_role: "使用規則：您可以在註冊域名時使用代金券抵扣相應金額的註冊費。"
    },

    inviteFriends: {
        title: '邀請好友，獲得獎勵',
        tips1: '通過以下鏈接邀請您的朋友註冊域名或TLDs，當註冊成功時，他們將',
        tips2: '的折扣',
        tips3: ',  而您可以',
        tips4: '獲得最高',
        tips5: '的註冊費用作為推薦獎勵。',
        tips6: '享有',
        invite_friends: '現在邀請',
        invitation_record: '邀請記錄',
        time: '註冊時間',
        address: '被邀請人地址',
        chain: '註冊鏈',
        reward: '獎勵',
        no_data: '無記錄',
        pup_tips: '創建您的跨鏈域名',
        bonus_tips: '邀請朋友並獲得獎金:',
        apply_for: '申請以獲得更高獎勵 >',
        look_rule: '查看具體規則 >',
        apply_success: '已提交审核',
        data_overview: '邀請總覽',
        data_ranking: '邀請排行',
        data_rank: '排行',
        data_address: '地址',
        data_invite_num: '邀請註冊域名數',
        data_address_num: '邀請地址數',
        data_award_num: '獲得獎勵',
        date_7: '7日',
        date_30: '30日',
        date_all: '全部',
        rebate_tips: '返傭比例和邀請數據統計',
        role: '您的角色',
        rebate_ratio: '返傭比例',
        number_invited: '邀請註冊數量',
        invited_friend: '邀請好友數',
        your_invitation: '您的邀請',
        invitee_address: '被邀請人地址',
        invited_number: "總域名註冊數",
        invited_money: "總註冊費",
        invited_award: "您的邀請獎勵",
        invited_detail: "明細",
        invited_register_detail: "註冊明細",
        invited_register_domain: "域名",
        invited_register_chain: "註冊鏈",
        invited_register_fee: "註冊費用",
        invited_register_award: "獎勵",
        invited_register_time: "註冊時間",
        individual_user1: '個人用戶',
        individual_user2: 'KOL',
        individual_user3: '分銷商',
        individual_user4: '合作方',
        token_reward: '您的獎勵',
        token_tips: '獎勵已自動劃轉至對應鏈的錢包地址',
        rebate_ratio_tips: '申請成為KOL/分銷商/合作方可獲得25%返傭',
        invitation_link: '您的邀請鏈接',
        invitation_code: '您的邀請碼',
        registration_fee: '註冊費用',
        reward_earn: '獎勵',
        invite_account_unLogin: '連接錢包獲取我的邀請信息',
        invite_friends_discount: "好友的註冊優惠",
        domain: '個',
        domains: '個',
        screen_shot: '截屏保存邀請海報',
        tips_role: '榜單權重默認根據已獲得獎勵高的排序，若要查看其他排序，可點擊篩選按鈕，查看其他指標的排序結果。',
        no_rewards: '您暫無獎勵  ',
        invite_1: '您有申請記錄',
        invite_2: '您已經是 KOL 身分了。',
        invite_3: '抱歉，您還不符合身分要求',
        invite_4: '發起申請',
        invite_5: '申請人狀態',
        invite_6: '您的介紹',
        invite_7: '聯絡方式',
        invite_8: '社群連結',
        invite_9: '正在審核中',
        invite_10: '我們將在24小時內與您聯繫，請耐心等待',
        invite_11: '審核結果',
        invite_12: '撤銷',
        invite_13: '已批准，恭喜您獲得活動KOL身分',
        invite_14: '返回',
        invite_15: '非常抱歉您的申請未獲批准',
        invite_16: '您的資訊不符合申請要求，所以您無法通過，非常抱歉，請重新輸入正確的資訊'
    },

    userInfo: {
        my_account: '我的賬戶',
        my_domain: '我的域名',
        my_tld: '我的TLDs',
        my_collection: '我的收藏',
        my_coupon: '代金券',
        my_email: '邮箱設置',
        switch_wallets: '切換錢包',
        exit_wallet: '斷開鏈接',
        operation_record: '操作記錄',
        revise: '修改',
    },

    applyToJoin: {
        apply_to_join: '申請更高的獎勵',
        join_tip1: "👋 Hi,歡迎進入返傭計劃申請頁面！ ",
        join_tip2: "如果您認可我們，並且對域名產品推廣有極高的興趣，我們很高興邀請您加入平台的邀請召集計劃：邀請好友註冊域名，即可享受高達25%的交易返傭~",
        join_tip3: '除此之外，我們的邀請計劃會跟著業務的發展，增加多種獎勵計劃。敬請期待吧。😊',
        apply: '您將以哪種身份申請 ',
        text1: 'KOL（社交媒體KOL，加密行業意見領袖等）',
        text2: '分銷商（擁有流量或有能力獲取流量的個人/機構）',
        text3: '合作方（NFT項目方、公鏈項目方、機構等）',
        your_introduction: '您的介紹',
        please_enter: '請輸入',
        social_media: '社交媒體 ',
        link: '您的社區/官網鏈接 ',
        contact: '聯繫方式（Email/Telegram/Discord) ',
        email: '郵箱地址',
        wallet: '錢包地址（可修改) ',
        wallet_tip: '聯繫信息（Telegram/Discord/Email/Twitter）',
        submit: '提交申請',
        role: '角色',
        reward_ratio: '獎勵比例',
        kol: 'KOL',
        registrar: '分銷商',
        partner: '合作方',
        enter: '必填項，請輸入內容',
        tip1: '我在OpenName上註冊了喜歡的域名，快來試試吧吧~',
        tip2: '創建您的跨鏈域名'
    },

    renew: {
        name_renewal_fee: '域名續費',
        expiration_time: '到期時間 ',
        renewal_fee: '總費用',
        cancel: '取消',
        renewal: '續費',
        renewal_cycle: '續費週期',
        hold_for_life: '終身持有',
        reconfirm: '確認續費',
        selected: '已選域名',
        selected_tld: '已選TLD',
    },

    tldRenew: {
        name_renewal_fee: 'TLDs續費',
    },

    timeName: {
        year: '年',
        month: '月',
        week: '週',
        day: '日',
        buy_out: '終生',
        m_year: '年',
        m_month: '月',
        m_week: '週',
        m_day: '日',
        m_years: '年',
        m_months: '月',
        m_weeks: '週',
        m_days: '日',
        free: '永久免費',
        years: '年',
        months: '月',
        weeks: '週',
        days: '日',
    },

    operationRecord: {
        title: '操作記錄',
        search_tips: '搜索域名',
        table_1: '類型',
        table_2: '域名',
        table_3: '地址',
        table_4: '週期',
        table_5: '費用',
        table_6: 'Gas',
        table_7: '時間',
        type_1: '註冊',
        type_2: '續費',
        type_3: '設置主名稱',
        type_4: '跨鏈轉移',
        type_5: '修改管理者',
        type_6: '轉讓持有者',
        type_7: '接收域名',
        type_8: '跨鏈轉移接收',
        type_9: '轉移擁有者',
        type_from: '从',
        type_to: '至',
    },

    activities: {
        activities: '活動',
        underWay: '進行中',
        upcoming: '即將開始',
        ended: '已結束',
        activity_time: '活動時間',
        rewards: '獎勵',
        activity_chain: '活動鏈',
        invite_now: '去邀請',
        invite_desc: '😃我在“openName"創建了無敵的全鏈域名。現在在平台註冊終身域名最低價只要$5🥳。千萬不要錯過。👀跟我一起體驗吧：\n',
        rule: '規則說明',
        rank: '排名',
        earn_rank: '收益排名',
        invite_address_rank: '邀請地址排名',
        invite_register_rank: '邀請註冊排名',
        register_rank: '域名註冊排名',
        option_rank: '排名',
        option_address: '地址',
        option_bonus: '已獲得獎勵',
        option_invite_address: '已邀請地址數',
        option_invite_register: '已邀請註冊數',
        option_domain: '域名數',
    },

    mailboxBinding: {
        tip1: '1、綁定郵箱獲得到期提醒',
        tip2: '2、關注我們的社群媒體',
        tip3: '設定為主名稱',
        tip4: '繼續註冊',
        tip5: '關注我們的官方賬戶',
        tip6: '綁定郵箱'
    },

    nameFi: {
        name_fi_title: '註冊域名\n賺取',
        coins: 'Coins',
        name_fi_title2: '和',
        points: 'Points',
        first_namefi: '全球首個NameFi',
        coin_desc: 'Coins：可隨時兌換為USDT',
        point_desc: 'Points：一切皆有可能',
        join_tele: '加入Telegram',
        coming_soon: '✨ 敬請期待',
        intro: 'NameFi 介紹',
        intro_desc0: 'NameFi是由OpenName推出的域名價值新範式，讓域名為用戶持續創造價值。',
        intro_desc1: '以',
        intro_desc1_bold: '域名作為抵押物',
        intro_desc2: '或',
        intro_desc2_bold: '完成平台任務',
        intro_desc3: '即可獲得Coins和Points獎勵。其中Coins可兌換為USDT，該USDT來源於平台域名註冊費淨收入的40%。Points是您在OpenName的貢獻。',
        intro_desc4: '立刻註冊域名，準備參與挖礦！',
        register: '註冊',
        earn_desc1: '質押域名進行抵押挖礦，挖礦可產生Coins和Points獎勵。',
        earn_desc2: '在質押期間，您依然可以對域名進行轉移或跨鏈，但您的質押收益也會產生對應的變化。',
        earn_desc3: '不同域名後綴、長度，註冊年限擁有不同的挖礦速度。',
        weight_table: '挖礦係數表',
        suffix: '后缀',
        character1: '1字符',
        character2: '2字符',
        character3: '3字符',
        character4: '4字符及以上',
        suffix_weight: '後綴權重',
        more: '更多',
        fold_up: '收起',
        task_earn1: '所有用戶均可通過完成OpenName設置的任務賺取Coins和Points。',
        task_earn2: '用戶所獲得Coins與Points數量與任務完成的數量有關。',
        task_earn3: '任務列表及詳細規則將於活動開始時公佈，敬請關注。',
        rule_remark: '*此頁內容僅供參考，請以正式活動發佈的規則為準。\n*OpenName保留對規則修改及最終解釋的權利。',
    },

    tldDetail: {
        tld_profile: '編輯',
        copy_link: '複製鏈接',
        tld_share: '分享',
        share_tld_desc: '我在 OpenName 上註冊了一個最喜歡的域名，快來試試吧！ 🎉',
        share_tld_desc2: 'Have your favorite web3 domain',
        report: '舉報',
        owner: '持有者',
        manager: '管理員',
        expiration_time: '到期時間',
        renewal: '續費',
        more: '更多',
        name_holder: '持有人數',
        name_registration: '域名註冊數',
        registration: '註冊',
        domain_pricing: '域名定價',
        character_length: '字符長度',
        first_level_domain: '一級域名',
        multi_level_domain: '多級域名',
        first_level_desc: 'Web3 網域由兩組或兩組以上字符構成，各組字符由點號分隔開。一個網域中包含兩組字符的為一級網域。例如 love.you 是一級域名。',
        multi_level_desc: 'Web3 網域由兩組或兩組以上字符構成，各組字符由點號分隔開。一個網域中包含兩組字符的為一級網域。例如i.love.you 是多層域名。',
        character1: '1字符',
        character2: '2字符',
        character3: '3字符',
        character4: '4字符及以上',
        any_length: '任意長度',
        information: '基礎信息',
        social_media: '社交媒體',
        other: '其他',
        register_record: '註冊記錄',
        name: '域名',
        address: '地址',
        registration_cycle: '註冊週期',
        registration_fee: '註冊費用',
        registration_time: '註冊時間',
    },

    tldEdit: {
        modify_pic: 'Modify profile picture',
        add_photo: 'Add photo',
        introduction: '簡介',
        introduction_placeholder: '簡單說明',
        draft: '保存草稿',
        release: '發布',
    },

    tldData: {
        address_num: '持有人數',
        name_num: '域名註冊數',
        your_income: '您的收入',
        cumulative_income: '累計收入',
        platform_ratio: '平台分成比例',
        your_share: '您的分成比例',
        domain_records: '域名註冊記錄',
        extend_record: '域名續費記錄',
        registration: '註冊',
        extension: '續費',
        extension_period: '續費週期',
        extension_fee: '續費費用',
        share_address: '分潤地址',
        share_income: '分潤收入',
        extend_time: '續費時間',
        address_tip: '该地址是指為當前域名續費的地址。',
        agency: '分享',
        agency_title: '分享記錄',
        register_address: '註冊地址',
        agency_address: '行銷人員地址',
        agency_fee: '註冊費用',
        agency_lcome: '行銷人員分潤',
        you_lcome: '你的分潤',
        agency_time: '註冊時間'
    },

    tldEntrance: {
        tld_owners: '持有者',
        tld_on: '所在鏈',
        tld_domain: '註冊網域數',
        tld_holders: '持有人數',
        all_chain: '全鏈'
    },

    amendTld: {
        setting_price: '開放註冊',
        setting_desc: '設定過程中，如您需要協助，',
        setting_desc1: '請聯系我们。',
        price_modle: '定價模式',
        one_domain: '一級域名',
        two_domain: '多級域名',
        one_ch: '字符',
        two_ch: '字符',
        buy_out: '終生',
        event_ling: '任意長度',
        discount: '折扣',
        allow_chail: '允許註冊鏈',
        allow_cross_chail: '允許跨至目標鏈',
        pl_1: '请输入 ，100為不打折 ',
        pl_2: '請選擇',
        all_chain: '全部公鏈',
        tip_2: '您選擇公鏈後，用戶將只能在對應的公鏈上跨鏈轉移域名，不選擇的情況下默認為平台所支持的全部公鏈。',
        tip_1: '您可以選擇用戶可以在哪條鏈注册此TLD的功能變數名稱。 可單選或多選，其中“all”表示支持所有鏈。',
        tip_3: '您選擇價格模板後，可以快速上架TLD。如果要做更多的設置，可以到“我的TLD”進行更多設置。',
        grounding: '上架',
        grounding_tld: '上架 TLD',
        custom: '自定義',
        sold_out: '確認下架',
        sold_out_tips: '下架後，您的TLD將不再提供給用戶註冊。請謹慎操作。',
        sold_out_1: '我再想想',
        sold_out_confirm: '确认',
        year: '按年付費',
        liftTime: '可買斷，終生持有',
        m_year: '/年',
        m_liftTime: '/終生',
        no_limit: '不限',
        manage_secondary: '註冊商/行銷人員',
        manage_secondary_tips: '為 TLD 註冊代理人或行銷人員設立收益分享計畫。',
        address: '地址',
        rate: '分潤比例',
        agency_address_error: '無效地址',
        agency_address_error1: '地址不能為空',
        agency_address_error2: '存在重複地址',
        agency_rate_error: '代理分潤比例錯誤',
    },

    myTld: {
        successful: '提交成功',
        apply: '申請',
        my_tld: '我的TLDs',
        search_tld: '搜索TLDs',
        unOpen_tld: '未開放',
        open_tld: '已開放',
        tld_data: 'TLDs資料',
        tld_open: '開放註冊',
        tld_share: '分享鏈接',
        tld_date: '數據',
        tld_price: '定價',
        tld_register: '註冊中',
        tld_cross: '跨鏈中',
        tld_owner: '持有者',
        tld_manager: '管理员',
        tld_tips: '未開放註冊的TLDs將無法續費，建議您盡快開放註冊。',
        tld_transfer: '轉讓持有者',
        tld_change: '修改管理员',
        tld_crossing: '跨鏈轉移',
        tld_close: '關閉註冊',
        batch_renewal: 'TLD續費',
        tld_close_verify: '確認關閉註冊',
        tld_close_confirm: '確定',
        tld_close_tips: '關閉註冊後，用戶將無法註冊該TLDs的域名。請謹慎操作！',
        rate: '分成比例',
        rate_tips: '當其他用戶在此TLD下註冊域名時，TLD所有人將獲得',
        rate_tips_2: '註冊費的收益。',
        statusTextL: '狀態',
        no_description: '暫無描述',
        applyPopup: '申請註冊TLDs',
        pop_tips: '提交申請後，我們將通過郵件與您取得聯繫。請保持關注。',
        apply_text: '申請理由',
        apply_text_error: '請輸入申請理由',
        l_tip1: '每年价格必须大于等于',
        l_tip2: '买断价格必须大于等于',
        l_tip3: '的每年价格',
        l_tip4: '的买断价格',
        l_tip5: '每年价格必须小于等于',
        l_tip6: '买断价格必须小于等于',
        price_empty: 'TLD 價格不能為空',
        not_allowed: '禁止跨鏈',
        group_tip: '恭喜您擁有最佳TLD，請加入TLD業主專屬服務群',
        btn1: '完成註冊',
        btn2: '快速上架',
    }
}