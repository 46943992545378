import "core-js/modules/web.timers.js";
import { copyText } from '@/utils/utils';
import { isMobile } from 'vant/lib/utils';
export default {
  name: 'copyBtn',
  props: {
    address: String,
    callBack: Boolean
  },
  data: function data() {
    return {
      tipsText: '',
      iscopy: true,
      remove: false
    };
  },
  computed: {
    isMobile: function isMobile() {
      return this.$store.state.isMobile;
    }
  },
  mounted: function mounted() {
    if (!this.isMobile) {
      this.tipsText = this.$t('publish.copy_address');
    }
  },
  methods: {
    handleCopy: function handleCopy(e) {
      var _this = this;
      try {
        copyText(e);
        this.tipsText = this.$t('publish.copy_success');
        this.iscopy = false;
        if (this.callBack) {
          this.$emit('copySuccess');
        }
        setTimeout(function () {
          _this.remove = true;
          setTimeout(function () {
            _this.remove = false;
          });
        }, 1000);
      } catch (error) {
        this.tipsText = this.$t('publish.copy_fail');
      }
    },
    mouseLive: function mouseLive() {
      if (!this.isMobile) {
        this.tipsText = this.$t('publish.copy_address');
      }
      this.iscopy = true;
    }
  }
};