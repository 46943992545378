import i18n from '@/language/i18n'
/**
 * 封装工程中的本地存储方法，请根据业务需要将对应的数据存储在对应的存储中
 */
function storageBuilder(type) {
  const storage = window[type]
  return {
    // 以字符串类型操作缓存对象
    setString(key, str) {
      storage.setItem(key, str)
    },
    getString(key) {
      return storage.getItem(key)
    },
    setItem(key, str) {
      storage.setItem(key, str)
    },
    getItem(key) {
      return storage.getItem(key)
    },
    // 以数字类型操作缓存对象
    setNumber(key, number) {
      storage.setItem(key, ((number || '') + '').replace(/[^\d]/g, ''))
    },
    getNumber(key) {
      return Number(storage.getItem(key))
    },
    // 以Boolean类型操作缓存对象
    setBoolean(key, boolean) {
      storage.setItem(key, !!boolean)
    },
    getBoolean(key) {
      return storage.getItem(key) === 'true'
    },
    // 以Object对象类型操作缓存对象，缓存前转化为字符串，从缓存中获取时转化为对象
    setObject(key, object) {

      storage.setItem(key, JSON.stringify(object))
    },
    getObject(key) {
      let item = storage.getItem(key)
      return JSON.parse(!item || item === 'undefined' || item === 'null' ? null : item)
    },
    removeItem(key) {
      storage.removeItem(key)
    },
    clear() {
      storage.clear()
    },

    clearWalletConnectStorage() {
      for (let i = storage.length - 1; i >= 0; i--) {
        if (storage.key(i).indexOf('wc@2') != -1 ||
          storage.key(i).indexOf('WCM_VERSION') != -1 ||
          storage.key(i).indexOf('registrationCenter') != -1
        ) {
          storage.removeItem(storage.key(i))
        }
      }
    }
  }
}

export const setTimeLocalStorage = (key, object) => {
  let data = storageBuilder('localStorage').getObject(key)
  if (data) {
    const t1 = new Date().getTime();
    let status, returnStatus;
    for (let i in data) {
      if (data[i] && i != 'contains') {
        if (Array.isArray(data[i].val) && Array.isArray(object.val)) {
          status = object.val.length === data[i].val.length && object.val.filter((v, o) => JSON.stringify(v) !== JSON.stringify(data[i].val[o])).length === 0
        } else {
          if (JSON.stringify(object.val) === JSON.stringify(data[i].val)) {
            status = false
          } else {
            status = true
          }
        }
        if (status) {
          if (t1 > data[i].time) {

          } else {
            if (data[i].signature) {
              returnStatus = 1
            } else {
              returnStatus = 2
            }
          }

        }
      }
    }
    if (returnStatus) {
      if (returnStatus == 1) {
        return false
      } else {}
    }
    data = data.filter((item) => {

      let type
      if (Array.isArray(item.val) && Array.isArray(object.val)) {
        type = object.val.length === item.val.length && object.val.filter((v, o) => JSON.stringify(v) !== JSON.stringify(item.val[o])).length === 0
      } else {
        if (JSON.stringify(object.val) === JSON.stringify(item.val)) {
          type = false
        } else {
          type = true
        }
      }
      return item.time > t1 && !type
    })
    if (object.signature) {
      data.push(object)
      storageBuilder('localStorage').setObject(key, data);
    }
    return true
  } else {
    storageBuilder('localStorage').setObject(key, [object]);
    return true
  }
}

/**
 * 按时间戳存缓存（分钟）
 */
export function setWithExpiry(key, value, expirationMinutes) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + (expirationMinutes * 60 * 1000)
  };
  localStorage.setItem(key, JSON.stringify(item));
}

/**
 * 获取带有过期时间的缓存
 */
export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null; // 如果不存在该项，返回null
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key); // 如果过期，从localStorage中移除该项
    return null;
  }
  return item.value;
}

export const sessionStorage = storageBuilder('sessionStorage')
export const localStorage = storageBuilder('localStorage')