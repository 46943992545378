import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-336ce456"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "title"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");
  var _component_el_select = _resolveComponent("el-select");
  return this.payMethodList.length > 1 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": _normalizeClass(["currency-box", !$options.isMobile && $props.isCard ? 'card-box' : ''])
  }, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('signin.select_ayment')), 1), _createVNode(_component_el_select, {
    modelValue: $data.currencyValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $data.currencyValue = $event;
    }),
    "class": "select-currency",
    "popper-class": "coupon-popper-select coupon-popper-select1",
    placeholder: "Select",
    teleported: false,
    onChange: $options.inputChange
  }, {
    prefix: _withCtx(function () {
      return [$data.currencyValue ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        "class": "imgIcon",
        style: {},
        src: $options.tokenIcon($data.currencyValue),
        alt: ""
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true)];
    }),
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.payMethodList, function (item, index) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: index,
          value: item.id,
          label: item.tokenName
        }, {
          "default": _withCtx(function () {
            return [_createElementVNode("img", {
              "class": "imgIcon",
              style: {},
              src: item.tokenLogo ? item.tokenLogo : $data.defaultIcon,
              alt: ""
            }, null, 8, _hoisted_3), _createTextVNode(_toDisplayString(item.tokenName), 1)];
          }),
          _: 2
        }, 1032, ["value", "label"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["modelValue", "onChange"])], 2)) : _createCommentVNode("", true);
}