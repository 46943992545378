import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f193ede2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "shoppingCart"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_content = _resolveComponent("content");
  var _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: _ctx.registerPop,
    "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.registerPop = $event;
    }),
    position: $options.isMobile ? 'bottom' : 'right',
    round: $options.isMobile ? true : false,
    "lazy-render": false,
    "class": "shopping-cart-drawer",
    style: _normalizeStyle($options.isMobile ? '' : {
      width: '26.4rem'
    }),
    onClickCloseIcon: $options.handleClosesBox,
    onClickOverlay: $options.handleClosesBox
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_content, {
        registerPop: _ctx.registerPop,
        onHandleClosesBox: $options.handleClosesBox
      }, null, 8, ["registerPop", "onHandleClosesBox"])];
    }),
    _: 1
  }, 8, ["show", "position", "round", "style", "onClickCloseIcon", "onClickOverlay"])]);
}