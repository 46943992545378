import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-22956212"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "emailItem"
};
var _hoisted_2 = {
  "class": "header"
};
var _hoisted_3 = {
  "class": "title"
};
var _hoisted_4 = {
  key: 0,
  "class": "content"
};
var _hoisted_5 = {
  "class": "bands-tips"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  "class": "input-box"
};
var _hoisted_8 = {
  "class": "input-box code"
};
var _hoisted_9 = {
  key: 1
};
var _hoisted_10 = {
  "class": "band-tips"
};
var _hoisted_11 = {
  key: 1,
  "class": "dialog-footer-card"
};
var _hoisted_12 = {
  "class": "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_el_form = _resolveComponent("el-form");
  var _component_el_button = _resolveComponent("el-button");
  var _component_mail_editing = _resolveComponent("mail-editing");
  var _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: $data.emailPop,
    "onUpdate:show": _cache[6] || (_cache[6] = function ($event) {
      return $data.emailPop = $event;
    }),
    position: _ctx.isMobile ? 'bottom' : 'center',
    style: _normalizeStyle(_ctx.isMobile ? '' : {
      width: '23.68rem'
    }),
    round: true,
    "class": "dialog-pop-box",
    onClickCloseIcon: $options.handleClose,
    onClickOverlay: $options.handleClose
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($options.getTitle($props.isEdit, $data.userInfo.email)), 1), _createElementVNode("i", {
        "class": "close",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleClose && $options.handleClose.apply($options, arguments);
        })
      })]), !$props.isEdit ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('email.pop_tips')), 1), !$data.userInfo.email ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_el_form, {
        rules: $data.rules,
        model: $data.from,
        ref: "ruleFormRef"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_form_item, {
            label: "",
            prop: "emailAddress"
          }, {
            "default": _withCtx(function () {
              return [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_input, {
                modelValue: $data.from.emailAddress,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
                  return $data.from.emailAddress = $event;
                }),
                placeholder: _ctx.$t('email.email_address')
              }, null, 8, ["modelValue", "placeholder"])])];
            }),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "",
            prop: "emailCode"
          }, {
            "default": _withCtx(function () {
              return [_createElementVNode("div", _hoisted_8, [_createVNode(_component_el_input, {
                modelValue: $data.from.emailCode,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
                  return $data.from.emailCode = $event;
                }),
                placeholder: _ctx.$t('email.code_add')
              }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("span", {
                onClick: _cache[3] || (_cache[3] = _withModifiers(function () {
                  return $options.countDown && $options.countDown.apply($options, arguments);
                }, ["stop"])),
                "class": "code-box"
              }, _toDisplayString($data.codeText), 1)])];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["rules", "model"])])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('email.reminder_email')) + ":", 1), _createTextVNode(" " + _toDisplayString($data.userInfo.email), 1)])]))])) : _createCommentVNode("", true), !$props.isEdit ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("span", _hoisted_12, [!$data.userInfo.email || $data.userInfo.email == null ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        loading: $data.emailLoading,
        color: "#000",
        type: "primary",
        onClick: _cache[4] || (_cache[4] = function ($event) {
          return $options.handleSubmit();
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('email.binding')), 1)];
        }),
        _: 1
      }, 8, ["loading"])) : (_openBlock(), _createBlock(_component_el_button, {
        key: 1,
        color: "#000",
        type: "primary",
        loading: $data.emailLoading,
        onClick: _cache[5] || (_cache[5] = function ($event) {
          return $options.handleSetReminder();
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString($props.myDomainsReminder ? _ctx.$t('publish.cancleLembrete') : _ctx.$t('publish.submit')), 1)];
        }),
        _: 1
      }, 8, ["loading"]))])])) : _createCommentVNode("", true), $props.isEdit ? (_openBlock(), _createBlock(_component_mail_editing, {
        key: 2,
        premiumTime: $props.premiumTime,
        onEndOfEdit: $options.handleClose
      }, null, 8, ["premiumTime", "onEndOfEdit"])) : _createCommentVNode("", true)];
    }),
    _: 1
  }, 8, ["show", "position", "style", "onClickCloseIcon", "onClickOverlay"])]);
}