export function showContractErrorMessage(err, defaultMsg = this.$t(`message.sys_error`), type) {
    console.log('===', err)
    if (type == 'register') {
        if (err.code && err.code == 4001 && !window.imToken) {
            this.$showTip(this.$t(`message.balance_error`), 'error')
            return
        }
    }
    if (err.data && err.data.message) {
        if (err.data.message.indexOf('user rejected') != -1 ||
            err.data.message.indexOf('User rejected') != -1 ||
            err.data.message.indexOf('Cancelled') != -1) {
            return
        }
        if (err.data.message.indexOf('insufficient funds') != -1 && !window.imToken) {
            this.$showTip(this.$t(`message.balance_error`), 'error')
        } else {
            this.$showTip(err.data.message, 'error')
        }

    } else if (err.message) {
        if (err.message.indexOf('user rejected') != -1 ||
            err.message.indexOf('User rejected') != -1 ||
            err.message.indexOf('Cancelled') != -1) {
            return
        }
        if (err.message.indexOf('invalid token ID') != -1) {
            this.$showTip(this.$t(`message.owner_error`), 'error')
        } else if (err.message.indexOf('insufficient funds') != -1 && !window.imToken) {
            this.$showTip(this.$t(`message.balance_error`), 'error')
        } else if (err.message.indexOf('bad address checksum') != -1) {
            this.$showTip('Not a valid Ethereum address.', 'error')
        } else {
            let str = err.message.split('(')[0]
            if (str.indexOf('[') != -1) {
                this.$showTip(str.split('[')[0], "error")
            } else {
                this.$showTip(str, "error")
            }
        }
    } else if ((err.code && err.code == 4001) || err && err.indexOf('Cancelled') != -1) {
        return
    } else {
        this.$showTip(defaultMsg, 'error')
    }
}