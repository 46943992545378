import {
  createRouter,
  createWebHistory
} from 'vue-router'

const Query = () => import('@/views/query/query')

// const NameFi = () => import( '@/views/mining/nameFi.vue')
const TldRenew = () => import('@/views/tldRenew/index.vue')
const AmendTld = () => import('@/views/amendTld/index')
const MyTld = () => import('@/views/myTld/index')
const PriceList = () => import('@/views/priceList/priceList')
const MyDomain = () => import('@/views/domain/myDomain')
const Transfer = () => import('@/views/domain/transfer')
const SetPrimaryName = () => import('@/views/domain/setPrimaryName')
const Manager = () => import('@/views/domain/manager.vue')
const DomainView = () => import('@/views/domainView/myDomain')
const Favorite = () => import('@/views/favorite/favorite.vue')
const Coupon = () => import('@/views/couponList/coupon.vue')
const InviteFriends = () => import('@/views/inviteFriends/friends.vue')
const Renew = () => import('@/views/renew/renew.vue')
const OperationRecord = () => import('@/views/operationRecord/index.vue')
const Activities = () => import('@/views/activity/activities.vue')
const ActivityDetail = () => import('@/views/activity/activityDetail.vue')
const Docs = () => import('@/views/docs/docs.vue')
const TldEdit = () => import('@/views/tld/tldEdit.vue')
const TldData = () => import('@/views/tld/tldData.vue')
const TldEntrance = () => import('@/views/tld/tldEntrance.vue')
const TldDetail = () => import('@/views/tld/tldDetail.vue')
const NotFound = () => import('@/views/404')


const routes = [{
    path: '/',
    name: 'Query',
    component: Query
  },
  // {
  //   path: '/namefi',
  //   name: 'NameFi',
  //   component: NameFi
  // },
  {
    path: '/pricing',
    name: 'Pricing',
    component: PriceList
  },
  {
    path: '/myName',
    name: 'MyName',
    component: MyDomain
  }, {
    path: '/nameView',
    name: 'nameView',
    component: DomainView
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer
  },
  {
    path: '/setPrimaryName',
    name: 'SetPrimaryName',
    component: SetPrimaryName
  },
  {
    path: '/amendTld',
    name: 'AmendTld',
    component: AmendTld
  },
  {
    path: '/myTld',
    name: 'MyTld',
    component: MyTld
  },
  {
    path: '/manager',
    name: 'Manager',
    component: Manager
  },
  {
    path: '/renew',
    name: 'Renew',
    component: Renew
  },
  {
    path: '/tldRenew',
    name: 'TldRenew',
    component: TldRenew
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: Favorite
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: Coupon
  },
  {
    path: '/invite',
    name: 'InviteFriends',
    component: InviteFriends
  },
  {
    path: '/docs',
    name: 'Docs',
    component: Docs
  },
  {
    path: '/operationRecord',
    name: 'OperationRecord',
    component: OperationRecord
  },
  {
    path: '/activities',
    name: 'Activities',
    component: Activities
  },
  {
    path: '/activityDetail',
    name: 'ActivityDetail',
    component: ActivityDetail
  },
  {
    path: '/tldEdit',
    name: 'TldEdit',
    component: TldEdit
  },
  {
    path: '/tldData',
    name: 'TldData',
    component: TldData
  },
  {
    path: '/tldDetail/:tld',
    name: 'TldDetail',
    component: TldDetail
  },
  {
    path: '/tld/:tld',
    name: 'TldEntrance',
    component: TldEntrance
  },
  {
    path: '/404',
    name: '404',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // 每次切换路由的时候滚动到页面顶部
  scrollBehavior() {
    // vue2.0  x  y  控制
    // vue3.0  left  top 控制
    return {
      left: 0,
      top: 0
    }
  }
})

export default router