import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import { Picker } from 'vant';
export default {
  name: 'headerMore',
  components: {
    'van-picker': Picker
  },
  props: {
    gasPrice: 0
  },
  computed: {
    routerList: function routerList() {
      return [
      // {
      //   path: "/activities",
      //   name: this.$t(`header.activities`),
      //   icon: require('@/assets/image/header/header_activities.png')
      // },
      {
        path: '/invite',
        name: this.$t("header.invite"),
        icon: require('@/assets/image/header/header_invite.png')
      }, {
        path: '/pricing',
        name: this.$t("header.pricing"),
        icon: require('@/assets/image/header/header_pricing.png')
      }, {
        path: '',
        name: 'FAQ',
        url: 'https://docs.open.name/faq',
        icon: require('@/assets/image/header/header_faq.png')
      }];
    },
    language: function language() {
      var _this = this;
      var lan = '';
      var that = this;
      this.languageList.forEach(function (item) {
        if (item.value == _this.languageType) {
          lan = item.label;
          that.$i18n.locale = item.value;
          localStorage.setItem('language', item.value);
          _this.$store.commit('setLanguge', item.value);
        }
      });
      return lan;
    }
  },
  data: function data() {
    return {
      show: false,
      showPicker: false,
      languageType: localStorage.getItem('language') || 'en',
      socailList: [{
        icon: require('@/assets/image/footer/icon_foot1.svg'),
        url: 'https://twitter.com/OpenNameProject'
      }, {
        icon: require('@/assets/image/footer/icon_foot2.svg'),
        url: 'https://discord.com/invite/G83NXk7YHp'
      },
      // {icon: require('@/assets/image/header/icon_foot3.svg'),url:""},
      {
        icon: require('@/assets/image/footer/icon_foot5.svg'),
        url: 'https://medium.com/@openname'
      }, {
        icon: require('@/assets/image/footer/icon_foot4.svg'),
        url: 'https://docs.open.name'
      }, {
        icon: require('@/assets/image/footer/icon_opensea.svg'),
        url: 'https://opensea.io/OpenNameProject/created'
      }, {
        icon: require('@/assets/image/footer/icon_telegram.svg'),
        url: 'https://t.me/opennameproject'
      }],
      languageList: [{
        value: 'en',
        label: 'English'
      }, {
        value: 'ch',
        label: '繁體中文'
      }, {
        value: 'kr',
        label: '한국어'
      }, {
        value: 'jp',
        label: 'にほんご'
      }]
    };
  },
  methods: {
    handleClick: function handleClick(item) {
      if (item.path) {
        this.show = false;
        this.$router.push(item.path);
      } else {
        window.open(item.url);
        this.show = false;
      }
    },
    onConfirm: function onConfirm(_ref) {
      var selectedValues = _ref.selectedValues;
      this.languageType = selectedValues.join(',');
      this.showPicker = false;
      this.show = false;
    },
    handleSwitch: function handleSwitch() {
      this.showPicker = true;
      // if(this.languageType == 'en'){
      //   this.languageType = 'ch'
      // }else {
      //   this.languageType = 'en'
      // }
    }
  }
};