import {
    API
} from '../api'
import store from '@/store/index.js'

const path = process.env.VUE_APP_BASE_API

export const COMMOM_API_URL = {
    NOTICES: `${path}/v1/user/notices`,
    WALLETS: `${path}/v1/public/wallets`,
    CHAINS: `${path}/v1/public/chains`,
}


export default {
    async getNotice(params, conf) {
        return await API.get(COMMOM_API_URL.NOTICES, params, conf, false)
    },

    async getWalets(params, conf) {
        return await API.get(COMMOM_API_URL.WALLETS, params, conf, false)
    },

    async getChains(params, conf) {
        return await API.get(COMMOM_API_URL.CHAINS, params, conf, false)
    },

}