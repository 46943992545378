import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.index-of.js";
import { getChainList } from '@/utils/chainInfo';
export default {
  name: 'numberSkeleton',
  props: {
    usdPrice: {
      modelValue: String
    },
    price: {
      modelValue: String
    },
    height: {
      modelValue: String
    },
    isDollar: {
      modelValue: Boolean,
      "default": true
    },
    isSymbol: {
      modelValue: Boolean,
      "default": false
    }
  },
  watch: {},
  data: function data() {
    return {
      theUsdPrice: ''
    };
  },
  computed: {
    chainid: function chainid() {
      return this.$store.state.chainid;
    },
    symbol: function symbol() {
      var chainInfo = getChainList();
      if (chainInfo[this.chainid]) {
        return chainInfo[this.chainid].nativeCurrency.symbol;
      } else {
        return '';
      }
    },
    isLoading: function isLoading(usdPrice) {
      return function (usdPrice) {
        if (!usdPrice) {
          return true;
        }
        usdPrice = usdPrice.toString();
        if (usdPrice) {
          if (usdPrice.indexOf('NaN') != -1) {
            return true;
          }
          return usdPrice ? false : true;
        }
        return true;
      };
    }
  },
  mounted: function mounted() {},
  methods: {
    getDisabled: function getDisabled() {
      var usdPrice = this.usdPrice;
      if (this.usdPrice) {
        usdPrice = usdPrice.toString();
        return usdPrice.indexOf('≈') == -1;
      } else {
        return false;
      }
    }
  }
};