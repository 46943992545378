import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6eb25d0f"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["src"];
var _hoisted_2 = ["src"];
var _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["loading-box flex-start", $props.type == 'card' ? 'card-box' : 'single'])
  }, [_createElementVNode("div", {
    "class": _normalizeClass(["load-item mlauto text-box", {
      active: _ctx.accessToken
    }])
  }, [_createVNode(_component_el_icon, {
    "class": _normalizeClass({
      'is-loading': !_ctx.accessToken
    })
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("img", {
        "class": _normalizeClass({
          is_select: _ctx.accessToken
        }),
        src: !_ctx.accessToken ? require('@/assets/image/signin/loading.svg') : require('@/assets/image/signin/icon_single_option.svg'),
        alt: ""
      }, null, 10, _hoisted_1)];
    }),
    _: 1
  }, 8, ["class"]), _createElementVNode("span", {
    "class": _normalizeClass(["load-text", {
      'is-loading': !_ctx.accessToken
    }])
  }, _toDisplayString(_ctx.$t('signin.register1')), 3)], 2), _createElementVNode("div", {
    "class": _normalizeClass(["load-item mrauto", {
      active: _ctx.accessToken && $props.lockingStatus,
      unAccessToken: !_ctx.accessToken,
      'text-box': _ctx.accessToken
    }])
  }, [_ctx.accessToken ? (_openBlock(), _createBlock(_component_el_icon, {
    key: 0,
    "class": _normalizeClass({
      'is-loading': _ctx.accessToken && $props.lockingStatus
    })
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("img", {
        src: $props.lockingStatus ? require('@/assets/image/signin/loading.svg') : require('@/assets/image/signin/icon_single_option.svg'),
        alt: ""
      }, null, 8, _hoisted_2)];
    }),
    _: 1
  }, 8, ["class"])) : _createCommentVNode("", true), _createElementVNode("span", {
    "class": _normalizeClass(["load-text", {
      'is-loading': _ctx.accessToken && $props.lockingStatus
    }])
  }, _toDisplayString($props.isTld ? _ctx.$t('signin.register4') : _ctx.$t('signin.register2')), 3)], 2), _createElementVNode("div", {
    "class": _normalizeClass(["load-item mrauto", {
      active: _ctx.accessToken && $props.lockingStatus && $props.registerStatus,
      unAccessToken: !$props.registerStatus,
      submitNow: _ctx.accessToken && !$props.lockingStatus && !$props.registerStatus,
      'text-box': _ctx.accessToken && !$props.lockingStatus
    }])
  }, [_ctx.accessToken && !$props.lockingStatus ? (_openBlock(), _createBlock(_component_el_icon, {
    key: 0,
    "class": _normalizeClass({
      'is-loading': _ctx.accessToken && !$props.lockingStatus && $props.registerStatus
    })
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("img", {
        src: $props.registerStatus ? require('@/assets/image/signin/loading.svg') : require('@/assets/image/signin/icon_single_option.svg'),
        alt: ""
      }, null, 8, _hoisted_3)];
    }),
    _: 1
  }, 8, ["class"])) : _createCommentVNode("", true), _createElementVNode("span", {
    "class": _normalizeClass(["load-text", {
      'is-loading': _ctx.accessToken && !$props.lockingStatus && $props.registerStatus
    }])
  }, _toDisplayString(_ctx.$t('signin.register3')), 3)], 2)], 2);
}