import store from '@/store/index.js'
import CommomApi from "@/api/modules/commom";

export const chainInfoList =

    process.env.VUE_APP_RUNTIME_ENV === 'production' ? [{
            chainName: 'Ethereum',
            abbrName: 'Ethereum',
            openseaChainName: 'ethereum',
            chainId: 1,
            layerZeroChainId: 101,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://rpc.payload.de",
            icon: require('@/assets/image/connect/icon_eth.png'),
            blockExplorerUrls: ['https://etherscan.io'],
        },
        {
            chainName: 'Arbitrum',
            abbrName: 'Arbitrum',
            openseaChainName: 'arbitrum',
            chainId: 42161,
            layerZeroChainId: 110,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://1rpc.io/arb",
            icon: require('@/assets/image/connect/icon_arb.png'),
            blockExplorerUrls: ['https://arbiscan.io', 'https://explorer.arbitrum.io']
        },
        {
            chainName: 'OP Mainnet',
            abbrName: 'OP Mainnet',
            openseaChainName: 'OP Mainnet',
            chainId: 10,
            layerZeroChainId: 111,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://1rpc.io/op",
            icon: require('@/assets/image/connect/icon_op.svg'),
            blockExplorerUrls: ['https://optimistic.etherscan.io', 'https://explorer.arbitrum.io']
        },
        {
            chainName: 'Polygon',
            abbrName: 'Polygon',
            openseaChainName: 'matic',
            chainId: 137,
            layerZeroChainId: 109,
            symbol: 'MATIC',
            decimals: 18,
            rpcUrl: "https://polygon.llamarpc.com",
            icon: require('@/assets/image/connect/icon_polygon.png'),
            blockExplorerUrls: ['https://polygonscan.com/']
        },
        {
            chainName: 'BNB Chain',
            abbrName: 'BNB Chain',
            openseaChainName: 'bsc',
            chainId: 56,
            layerZeroChainId: 102,
            symbol: 'BNB',
            decimals: 18,
            rpcUrl: "https://bsc-dataseed4.ninicoin.io",
            icon: require('@/assets/image/connect/icon_bnb.svg'),
            blockExplorerUrls: ['https://bscscan.com'],
        },
        {
            chainName: 'zkSync Era',
            abbrName: 'zkSync',
            openseaChainName: '',
            chainId: 324,
            layerZeroChainId: 165,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://mainnet.era.zksync.io",
            icon: require('@/assets/image/connect/icon_zksync.svg'),
            blockExplorerUrls: ['https://explorer.zksync.io']
        },
        {
            chainName: 'Base Mainnet',
            abbrName: 'Base',
            openseaChainName: 'base',
            chainId: 8453,
            layerZeroChainId: 184,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://rpc.ankr.com/base",
            icon: require('@/assets/image/connect/icon_base.png'),
            blockExplorerUrls: ['https://basescan.org']
        },
        {
            chainName: 'Linea',
            abbrName: 'Linea',
            openseaChainName: '',
            chainId: 59144,
            layerZeroChainId: 183,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://rpc.linea.build",
            icon: require('@/assets/image/connect/icon_linea.png'),
            blockExplorerUrls: ['https://lineascan.build']
        },
        {
            chainName: 'Polygon zkEVM',
            abbrName: 'Polygon zkEVM',
            openseaChainName: '',
            chainId: 1101,
            layerZeroChainId: 158,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://zkevm-rpc.com",
            icon: require('@/assets/image/connect/icon_polygon_zkEVM.png'),
            blockExplorerUrls: ['https://zkevm.polygonscan.com']
        },
        {
            chainName: 'opBNB',
            abbrName: 'opBNB',
            openseaChainName: '',
            chainId: 204,
            layerZeroChainId: 202,
            symbol: 'BNB',
            decimals: 18,
            rpcUrl: "https://opbnb-mainnet-rpc.bnbchain.org",
            icon: require('@/assets/image/connect/icon_opbnb.svg'),
            blockExplorerUrls: ['https://mainnet.opbnbscan.com/']
        },
        {
            chainName: 'Avalanche',
            abbrName: 'Avalanche',
            openseaChainName: 'avalanche',
            chainId: 43114,
            layerZeroChainId: 106,
            symbol: 'AVAX',
            decimals: 18,
            rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
            icon: require('@/assets/image/connect/icon_avalanche.png'),
            blockExplorerUrls: ['https://snowtrace.io']
        },
        {
            chainName: 'Fantom',
            abbrName: 'Fantom',
            openseaChainName: '',
            chainId: 250,
            layerZeroChainId: 112,
            symbol: 'FTM',
            decimals: 18,
            rpcUrl: "https://rpcapi.fantom.network",
            icon: require('@/assets/image/connect/icon_fantom.png'),
            blockExplorerUrls: ['https://ftmscan.com']
        },
        {
            chainName: 'Scroll',
            abbrName: 'Scroll',
            openseaChainName: '',
            chainId: 534352,
            layerZeroChainId: 214,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: `https://rpc.scroll.io`,
            icon: require('@/assets/image/connect/icon_scroll.png'),
            blockExplorerUrls: ['https://scrollscan.com/']
        },
        {
            chainName: 'Conflux eSpace',
            abbrName: 'Conflux',
            openseaChainName: '',
            chainId: 1030,
            layerZeroChainId: 212,
            symbol: 'CFX',
            decimals: 18,
            rpcUrl: "https://evm.confluxrpc.com",
            icon: require('@/assets/image/connect/icon_conflux.png'),
            blockExplorerUrls: ['https://evm.confluxscan.net']
        },
        {
            chainName: 'Mantle',
            abbrName: 'Mantle',
            openseaChainName: '',
            chainId: 5000,
            layerZeroChainId: 181,
            symbol: 'MNT',
            decimals: 18,
            rpcUrl: "https://rpc.mantle.xyz",
            icon: require('@/assets/image/connect/icon_mantle.png'),
            blockExplorerUrls: ['https://explorer.mantle.xyz']
        },
        {
            chainName: 'Fuse',
            abbrName: 'Fuse',
            openseaChainName: '',
            chainId: 122,
            layerZeroChainId: 138,
            symbol: 'FUSE',
            decimals: 18,
            rpcUrl: "https://rpc.fuse.io",
            icon: require('@/assets/image/connect/icon_fuse.png'),
            blockExplorerUrls: ['https://explorer.fuse.io']
        },
        {
            chainName: 'Metis',
            abbrName: 'Metis',
            openseaChainName: '',
            chainId: 1088,
            layerZeroChainId: 151,
            symbol: 'Metis',
            decimals: 18,
            rpcUrl: "https://andromeda.metis.io/?owner=1088",
            icon: require('@/assets/image/connect/icon_metis.png'),
            blockExplorerUrls: ['https://andromeda-explorer.metis.io']
        },
        {
            chainName: 'Manta NetWork',
            abbrName: 'Manta',
            openseaChainName: '',
            chainId: 169,
            layerZeroChainId: 217,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://pacific-rpc.manta.network/http",
            icon: require('@/assets/image/connect/icon_manta.png'),
            blockExplorerUrls: ['https://pacific-explorer.manta.network/']
        },
        {
            chainName: 'Celo',
            abbrName: 'Celo',
            openseaChainName: '',
            chainId: 42220,
            layerZeroChainId: 125,
            symbol: 'CELO',
            decimals: 18,
            rpcUrl: "https://forno.celo.org",
            icon: require('@/assets/image/connect/icon_celo.png'),
            blockExplorerUrls: ['https://explorer.celo.org']
        },
        {
            chainName: 'Blast',
            abbrName: 'Blast',
            openseaChainName: '',
            chainId: 81457,
            layerZeroChainId: '243',
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: " https://rpc.blast.io",
            icon: require('@/assets/image/connect/icon_blast.png'),
            blockExplorerUrls: ['https://blastscan.io/']
        },
        {
            chainName: 'Mode',
            abbrName: 'Mode',
            openseaChainName: '',
            chainId: 34443,
            layerZeroChainId: 260,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: 'https://mainnet.mode.network',
            icon: require('@/assets/image/connect/icon_mode.png'),
            blockExplorerUrls: ['https://explorer.mode.network'],
        },
        {
            chainName: 'Zeta',
            abbrName: 'Zeta',
            openseaChainName: '',
            chainId: 7000,
            layerZeroChainId: '',
            symbol: 'ZETA',
            decimals: 18,
            rpcUrl: 'https://zetachain-evm.blockpi.network/v1/rpc/public',
            icon: require('@/assets/image/connect/icon_zeta.png'),
            blockExplorerUrls: ['https://explorer.mainnet.zetachain.com'],
        },
        {
            chainName: 'Degen',
            abbrName: 'Degen',
            openseaChainName: '',
            chainId: 666666666,
            layerZeroChainId: '',
            symbol: 'DEGEN',
            decimals: 18,
            rpcUrl: 'https://rpc.degen.tips',
            icon: require('@/assets/image/connect/icon_degen.png'),
            blockExplorerUrls: ['https://degen.tips'],
        },
        {
            chainName: 'Zora',
            abbrName: 'Zora',
            openseaChainName: '',
            chainId: 7777777,
            layerZeroChainId: '195',
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: 'https://rpc.zora.energy',
            icon: require('@/assets/image/connect/icon_zora.png'),
            blockExplorerUrls: ['https://explorer.zora.energy'],
        },
        {
            chainName: 'X Layer Mainnet',
            abbrName: 'X Layer',
            openseaChainName: '',
            chainId: 196,
            layerZeroChainId: '',
            symbol: 'OKB',
            decimals: 18,
            rpcUrl: 'https://rpc.xlayer.tech',
            icon: require('@/assets/image/connect/icon_xlayer.png'),
            blockExplorerUrls: ['https://www.oklink.com/zh-hans/xlayer'],
        },
    ] : process.env.VUE_APP_RUNTIME_ENV === 'beta' ? [{
            chainName: 'Ethereum Sepolia',
            abbrName: 'Ethereum',
            openseaChainName: 'goerli',
            chainId: 11155111,
            layerZeroChainId: 10121,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://eth-sepolia.public.blastapi.io",
            icon: require('@/assets/image/connect/icon_eth.png'),
            blockExplorerUrls: ['https://explorer.bitquery.io/goerli']
        },
        {
            chainName: 'Blast Testnet',
            abbrName: 'Blast',
            openseaChainName: '',
            chainId: 168587773,
            layerZeroChainId: '',
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://sepolia.blast.io",
            icon: require('@/assets/image/connect/icon_blast.png'),
            blockExplorerUrls: ['https://testnet.blastscan.io/']
        },
        {
            chainName: 'Mint Testnet',
            abbrName: 'Mint',
            openseaChainName: '',
            chainId: 1687,
            layerZeroChainId: '',
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://testnet-rpc.mintchain.io",
            icon: require('@/assets/image/connect/icon_mint.png'),
            blockExplorerUrls: ['https://testnet-explorer.mintchain.io/']
        },
        {
            chainName: 'Bera Testnet',
            abbrName: 'Bera',
            openseaChainName: '',
            chainId: 80085,
            layerZeroChainId: '',
            symbol: 'BERA',
            decimals: 18,
            rpcUrl: 'https://artio.rpc.berachain.com',
            icon: require('@/assets/image/connect/icon_bera.png'),
            blockExplorerUrls: ['https://artio.beratrail.io'],
        },
    ] : [{
            chainName: 'Ethereum Sepolia',
            abbrName: 'Ethereum',
            openseaChainName: 'goerli',
            chainId: 11155111,
            layerZeroChainId: 10121,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://eth-sepolia.public.blastapi.io",
            icon: require('@/assets/image/connect/icon_eth.png'),
            blockExplorerUrls: ['https://explorer.bitquery.io/goerli']
        },
        {
            chainName: 'Arbitrum Sepolia',
            abbrName: 'Arbitrum',
            openseaChainName: 'arbitrum-goerli',
            chainId: 421614,
            layerZeroChainId: 10231,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://arbitrum-sepolia.blockpi.network/v1/rpc/public",
            icon: require('@/assets/image/connect/icon_arb.png'),
            blockExplorerUrls: ['https://testnet.arbiscan.io/']
        },
        {
            chainName: 'Optimism Sepolia',
            abbrName: 'OP Mainnet',
            openseaChainName: 'optimism-goerli',
            chainId: 11155420,
            layerZeroChainId: 10232,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://optimism-sepolia.blockpi.network/v1/rpc/public",
            icon: require('@/assets/image/connect/icon_op.svg'),
            blockExplorerUrls: ['https://goerli-optimism.etherscan.io/']
        },
        {
            chainName: 'Polygon Mumbai',
            abbrName: 'Polygon',
            openseaChainName: 'mumbai',
            chainId: 80001,
            layerZeroChainId: 10109,
            symbol: 'MATIC',
            decimals: 18,
            rpcUrl: "https://polygon-mumbai-bor.publicnode.com",
            icon: require('@/assets/image/connect/icon_polygon.png'),
            blockExplorerUrls: ['https://mumbai.polygonscan.com/']
        },
        {
            chainName: 'BNB Testnet',
            abbrName: 'BNB Chain',
            openseaChainName: 'bsctestnet',
            chainId: 97,
            layerZeroChainId: 10102,
            symbol: 'BNB',
            decimals: 18,
            rpcUrl: "https://bsc-testnet.publicnode.com",
            icon: require('@/assets/image/connect/icon_bnb.svg'),
            blockExplorerUrls: ['https://testnet.bscscan.com/']
        },
        {
            chainName: 'zkSync Era Testnet',
            abbrName: 'zkSync',
            openseaChainName: '',
            chainId: 280,
            layerZeroChainId: 10165,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://testnet.era.zksync.dev",
            icon: require('@/assets/image/connect/icon_zksync.svg'),
            blockExplorerUrls: ['https://goerli.explorer.zksync.io']
        },
        {
            chainName: 'Base Sepolia',
            abbrName: 'Base',
            openseaChainName: 'base-goerli',
            chainId: 84532,
            layerZeroChainId: 10245,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://rpc.notadegen.com/base/sepolia",
            icon: require('@/assets/image/connect/icon_base.png'),
            blockExplorerUrls: ['https://goerli.basescan.org']
        },
        {
            chainName: 'Linea Goerli',
            abbrName: 'Linea',
            openseaChainName: '',
            chainId: 59140,
            layerZeroChainId: 10157,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://rpc.goerli.linea.build",
            icon: require('@/assets/image/connect/icon_linea.png'),
            blockExplorerUrls: ['https://explorer.goerli.linea.build']
        },
        {
            chainName: 'Polygon zkEVM Testnet',
            abbrName: 'Polygon zkEVM',
            openseaChainName: '',
            chainId: 1442,
            layerZeroChainId: 10158,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://rpc.public.zkevm-test.net",
            icon: require('@/assets/image/connect/icon_polygon_zkEVM.png'),
            blockExplorerUrls: ['https://explorer.public.zkevm-test.net']
        },
        {
            chainName: 'opBNB Testnet',
            abbrName: 'opBNB',
            openseaChainName: '',
            chainId: 5611,
            layerZeroChainId: 10202,
            symbol: 'tBNB',
            decimals: 18,
            rpcUrl: "https://opbnb-testnet-rpc.bnbchain.org",
            icon: require('@/assets/image/connect/icon_opbnb.svg'),
            blockExplorerUrls: ['https://opbnb-testnet.bscscan.com']
        },
        {
            chainName: 'Avalanche Testnet',
            abbrName: 'Avalanche',
            openseaChainName: 'avalanche-fuji',
            chainId: 43113,
            layerZeroChainId: 10106,
            symbol: 'AVAX',
            decimals: 18,
            rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
            icon: require('@/assets/image/connect/icon_avalanche.png'),
            blockExplorerUrls: ['https://testnet.snowtrace.io']
        },
        {
            chainName: 'Fantom Testnet',
            abbrName: 'Fantom',
            openseaChainName: '',
            chainId: 4002,
            layerZeroChainId: 10112,
            symbol: 'FTM',
            decimals: 18,
            rpcUrl: "https://rpc.testnet.fantom.network",
            icon: require('@/assets/image/connect/icon_fantom.png'),
            blockExplorerUrls: ['https://testnet.ftmscan.com']
        },
        {
            chainName: 'Scroll Testnet',
            abbrName: 'Scroll',
            openseaChainName: '',
            chainId: 534351,
            layerZeroChainId: 10214,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: `https://sepolia-rpc.scroll.io`,
            icon: require('@/assets/image/connect/icon_scroll.png'),
            blockExplorerUrls: ['https://blockscout.scroll.io/']
        },
        {
            chainName: 'Conflux eSpace Testnet',
            abbrName: 'Conflux',
            openseaChainName: '',
            chainId: 71,
            layerZeroChainId: 10211,
            symbol: 'CFX',
            decimals: 18,
            rpcUrl: "https://evmtestnet.confluxrpc.com",
            icon: require('@/assets/image/connect/icon_conflux.png'),
            blockExplorerUrls: ['https://evmtestnet.confluxscan.net']
        },
        {
            chainName: 'Mantle Sepolia',
            abbrName: 'Mantle',
            openseaChainName: '',
            chainId: 5003,
            layerZeroChainId: 10246,
            symbol: 'MNT',
            decimals: 18,
            rpcUrl: "https://rpc.sepolia.mantle.xyz",
            icon: require('@/assets/image/connect/icon_mantle.png'),
            blockExplorerUrls: ['https://explorer.testnet.mantle.xyz']
        },

        // {
        //     chainName: 'tMetis',
        //     abbrName: 'tMetis',
        //     openseaChainName: '',
        //     chainId:  59901 ,
        //     layerZeroChainId: 10151,
        //     symbol: 'Metis',
        //     decimals: 18,
        //     rpcUrl: "https://sepolia.rpc.metisdevops.link/",
        //     icon: require('@/assets/image/connect/icon_metis.png'),
        //     blockExplorerUrls: ['https://goerli.explorer.metisdevops.link']
        // },
        {
            chainName: 'Manta Network Sepolia',
            abbrName: 'Manta',
            openseaChainName: '',
            chainId: 3441006,
            layerZeroChainId: 10221,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://pacific-rpc.sepolia-testnet.manta.network/http",
            icon: require('@/assets/image/connect/icon_manta.png'),
            blockExplorerUrls: ['https://pacific-explorer.testnet.manta.network']
        },
        {
            chainName: 'Celo Testnet',
            abbrName: 'Celo',
            openseaChainName: '',
            chainId: 44787,
            layerZeroChainId: 10125,
            symbol: 'CELO',
            decimals: 18,
            rpcUrl: "https://alfajores-forno.celo-testnet.org",
            icon: require('@/assets/image/connect/icon_celo.png'),
            blockExplorerUrls: ['https://alfajores.celoscan.io']
        },
        {
            chainName: 'Blast Testnet',
            abbrName: 'Blast',
            openseaChainName: '',
            chainId: 168587773,
            layerZeroChainId: '',
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://sepolia.blast.io",
            icon: require('@/assets/image/connect/icon_blast.png'),
            blockExplorerUrls: ['https://testnet.blastscan.io/']
        },
        {
            chainName: 'Mint Testnet',
            abbrName: 'Mint',
            openseaChainName: '',
            chainId: 1687,
            layerZeroChainId: '',
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: "https://testnet-rpc.mintchain.io",
            icon: require('@/assets/image/connect/icon_mint.png'),
            blockExplorerUrls: ['https://testnet-explorer.mintchain.io/']
        },
        {
            chainName: 'Mode Testnet',
            abbrName: 'Mode',
            openseaChainName: '',
            chainId: 919,
            layerZeroChainId: 10260,
            symbol: 'ETH',
            decimals: 18,
            rpcUrl: 'https://sepolia.mode.network',
            icon: require('@/assets/image/connect/icon_mode.png'),
            blockExplorerUrls: ['https://sepolia.explorer.mode.network'],
        },
        {
            chainName: 'X Layer Testnet',
            abbrName: 'X Layer',
            openseaChainName: '',
            chainId: 195,
            layerZeroChainId: '',
            symbol: 'OKB',
            decimals: 18,
            rpcUrl: 'https://xlayertestrpc.okx.com',
            icon: require('@/assets/image/connect/icon_xlayer.png'),
            blockExplorerUrls: ['https://www.okx.com/zh-hans/explorer/xlayer-test'],
        },
        {
            chainName: 'Bera Testnet',
            abbrName: 'Bera',
            openseaChainName: '',
            chainId: 80085,
            layerZeroChainId: '',
            symbol: 'BERA',
            decimals: 18,
            rpcUrl: 'https://artio.rpc.berachain.com',
            icon: require('@/assets/image/connect/icon_bera.png'),
            blockExplorerUrls: ['https://artio.beratrail.io'],
        },
    ]

export const coinType = {
    '0': 'Btc',
    '2': 'Ltc',
    '3': 'Doge',
    '4': 'Rdd',
    '5': 'Dash',
    '6': 'Ppc',
    '14': 'Via',
    '17': 'Grs',
    '20': 'Dgb',
    '42': 'Dcr',
    '43': 'Xem',
    '57': 'Sys',
    '60': 'Eth',
    '74': 'Icx',
    '77': 'Xvg',
    '105': 'Strat',
    '111': 'Ark',
    '118': 'Atom',
    '121': 'Zen',
    '128': 'Xmr',
    '133': 'Zec',
    '134': 'Lsk',
    '135': 'Steem',
    '141': 'Kmd',
    '144': 'Xrp',
    '145': 'Bch',
    '148': 'Xlm',
    '156': 'Btg',
    '175': 'Rvn',
    '178': 'Poa',
    '194': 'Eos',
    '195': 'Trx',
    '204': 'Bcn',
    '246': 'Ewt',
    '256': 'Nano',
    '270': 'One',
    '283': 'Algo',
    '291': 'Iost',
    '301': 'Divi',
    '304': 'Iotx',
    '308': 'Bts',
    '309': 'Ckb',
    '313': 'Zil',
    '330': 'Luna',
    '354': 'Dot',
    '360': 'Vsys',
    '367': 'Abbc',
    '394': 'Cro',
    '397': 'Near',
    '415': 'Etn',
    '425': 'Aion',
    '434': 'Ksm',
    '457': 'Ae',
    '459': 'Kava',
    '461': 'Fil',
    '472': 'Ar',
    '500': 'Theta',
    '501': 'Sol',
    '508': 'Egld',
    '535': 'Xhv',
    '539': 'Flow',
    '566': 'Iris',
    '573': 'Srm',
    '589': 'Tfuel',
    '592': 'Grin',
    '714': 'Bnb',
    '804': 'Zks',
    '818': 'Vet',
    '820': 'Clo',
    '888': 'Neo',
    '889': 'Tomo',
    '904': 'Hnt',
    '931': 'Rune',
    '966': 'Matic',
    '999': 'Bcd',
    '1007': 'Ftm',
    '1024': 'Ont',
    '1729': 'Xtz',
    '1815': 'Ada',
    '2301': 'Qtum',
    '2305': 'Ela',
    '2718': 'Nas',
    '3030': 'Hbar',
    '4218': 'Iota',
    '5757': 'Stx',
    '6060': 'Go',
    '8964': 'Nuls',
    '9000': 'Avax',
    '9797': 'Nrg',
    '16754': 'Ardr',
    '52752': 'Celo',
    '5655640': 'Vlx',
    '5718350': 'Wan',
    '5741564': 'Waves',
    '8453': 'Base',
    '614': 'Opt',
    '9001': 'Arb',
    '59144': 'Linea',
    '1101': 'Polygon_zk',
    '20400': 'Opbnb',
    '534352': 'Scroll',
    '1030': 'Cfx',
    '5000': 'Mantle',
    '122': 'FUSE',
    '1088': 'Metis',
    '169': 'MANTA',
    '42220': 'CELO',
    '81457': 'Blast',
    '1687': 'Mint',
    '34443': 'Mode',
    '919': 'Mode',
    '666666666': 'Degen',
    '7777777': 'Zora',
    '196': 'OKB',
    '80085': 'BERA',
}


export const chainCoinType = {
    1: 60,
    10: 614,
    42161: 9001,
    137: 966,
    56: 714,
    324: 804,
    8453: 8453,
    59144: 59144,
    11155111: 60,
    97: 714,
    11155420: 614,
    80001: 966,
    421614: 9001,
    280: 804,
    84532: 8453,
    59140: 59144,
    71: 1030,
    5000: 5000,
    122: 122,
    1088: 1088,
    81457: 81457,
    1687: 1687,
    196: 196,
}

export function getCoinTypes() {
    let coinTypeList = []
    for (let key in coinType) {
        let item = {
            value: parseInt(key),
            label: coinType[key].toUpperCase()
        }
        coinTypeList.push(item)
    }
    return coinTypeList
}

//chainid array. [1,10...]
export function getChainIds() {
    let chainIds = [];
    chainInfoList.forEach(item => {
        chainIds.push(item.chainId)
    })
    return chainIds
}

export function getChainDict() {
    let resultRpcDict = {};
    chainInfoList.forEach(item => {
        resultRpcDict[item.chainId] = item;
    })
    return resultRpcDict
}

//chain and image dictionary. {1:image}
export function getChainImages() {
    let resultDict = {};
    chainInfoList.forEach(item => {
        resultDict[item.chainId] = item.icon;
    })
    return resultDict
}

//chain and rpcUrl dictionary. {1:'https://...'}
export async function getRpcUrlList() {
    let resultRpcDict = {};
    let rpc = store.state.getChainsList
    if (!rpc) {
        if (localStorage.getItem('setChainsList')) {
            rpc = JSON.parse(localStorage.getItem('setChainsList'))
        }
    }

    try {
        if (!rpc) {
            let res = await CommomApi.getChains()
            let json = {}
            res.forEach((item) => {
                json[item.chainId] = item
            })
            rpc = json
            store.commit("setChainsList", json)
            localStorage.setItem('setChainsList', JSON.stringify(json))
        }
    } catch (error) {

    }
    chainInfoList.forEach(item => {
        if (rpc[item.chainId]) {
            resultRpcDict[item.chainId] = rpc[item.chainId].rpcUrls;
            resultRpcDict.chainName = rpc[item.chainId].chainName
        } else {
            resultRpcDict[item.chainId] = item.rpcUrl;
        }

    })
    return resultRpcDict
}

export function getOpenseaChainName() {
    let resultRpcDict = {};
    chainInfoList.forEach(item => {
        resultRpcDict[item.chainId] = item.openseaChainName;
    })
    return resultRpcDict
}

export async function getChainList() {
    let resultRpcDict = {};
    let rpc = store.state.getChainsList
    if (!rpc) {
        if (localStorage.getItem('setChainsList')) {
            rpc = JSON.parse(localStorage.getItem('setChainsList'))
        }
    }
    try {
        if (!rpc) {
            let res = await CommomApi.getChains()
            let json = {}
            res.forEach((item) => {
                json[item.chainId] = item
            })
            rpc = json
            store.commit("setChainsList", json)
            localStorage.setItem('setChainsList', JSON.stringify(json))
        }
    } catch (error) {

    }
    chainInfoList.forEach(item => {
        let rpcList = [item.rpcUrl]

        if (rpc) {
            if (rpc[item.chainId]) {
                rpcList = rpc[item.chainId].rpcUrls
                if (rpcList.indexOf(',') != -1) {
                    rpcList = rpcList.split(',')
                } else {
                    rpcList = [rpcList]
                }
            }
        }
        console.log(resultRpcDict[item.chainId])
        resultRpcDict[item.chainId] = {
            chainName: rpc[item.chainId] ? rpc[item.chainId].chainName : item.chainName,
            abbrName: item.abbrName,
            rpcUrls: rpcList,
            nativeCurrency: {
                name: item.symbol,
                symbol: item.symbol,
                decimals: item.decimals
            },
            blockExplorerUrls: item.blockExplorerUrls
        }
    })
    return resultRpcDict
}


//Used of switch chain
export function switchChainIdList() {
    let connectChainArr = [];
    chainInfoList.forEach(item => {
        let chain = {
            icon: item.icon,
            text: item.chainName,
            key: item.chainName,
            name: item.abbrName,
            chainId: item.chainId,
            symbol: item.symbol
        }
        connectChainArr.push(chain)
    })
    return connectChainArr
}

//Used of select to connect chain
export function getConnectChainList() {
    let connectChainArr = [];
    chainInfoList.forEach(item => {
        let chain = {
            icon: item.icon,
            title: item.chainName,
            key: item.chainName,
            chainId: item.chainId,
        }
        connectChainArr.push(chain)
    })
    return connectChainArr
}

export function getConnectChainListFilter(arr) {
    let rpc = store.state.getChainsList
    if (!rpc) {
        if (localStorage.getItem('setChainsList')) {
            rpc = JSON.parse(localStorage.getItem('setChainsList'))
        }
    }
    let json = rpc
    let connectChainArr = [];
    chainInfoList.forEach(item => {

        let chain = {
            icon: item.icon,
            title: rpc[item.chainId] ? rpc[item.chainId].chainName : item.chainName,
            key: rpc[item.chainId] ? rpc[item.chainId].chainName : item.chainName,
            chainId: item.chainId,
            abbrName: item.abbrName,
        }
        if (json[item.chainId] && json[item.chainId].showFlag == 1) {
            chain.sort = json[item.chainId].sort
            connectChainArr.push(chain)
        } else {
            if (!json) {
                connectChainArr.push(chain)
            }

        }

    })
    connectChainArr = connectChainArr.sort((a, b) => Number(b.sort) - Number(a.sort));
    return connectChainArr

}


//Used of select to bridge chain
export function getBridgeChainList() {
    let rpc = store.state.getChainsList
    if (!rpc) {
        if (localStorage.getItem('setChainsList')) {
            rpc = JSON.parse(localStorage.getItem('setChainsList'))
        }
    }
    let json = rpc
    let crossChainArr = [];
    chainInfoList.forEach(item => {
        let chain = {
            icon: item.icon,
            chainName: rpc[item.chainId] ? rpc[item.chainId].chainName : item.chainName,
            dstChainId: item.layerZeroChainId,
            chainid: item.chainId,
        }
        if (json[item.chainId] && json[item.chainId].showFlag == 1) {
            chain.sort = json[item.chainId].sort
            crossChainArr.push(chain)
        } else {
            if (!json) {
                crossChainArr.push(chain)
            }
        }
    })
    crossChainArr = crossChainArr.sort((a, b) => Number(b.sort) - Number(a.sort));
    return crossChainArr

}