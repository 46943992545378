import {
    API
} from '../api'
import store from '@/store/index.js'

const path = process.env.VUE_APP_BASE_API

export const TLD_API_URL = {
    TLD_LIST: `${path}/v1/domain/tld/list`,
    PRICE_TEMPLATES: `${path}/v1/domain/price/templates`,
    TLD_EDIT: `${path}/v1/domain/tld/editPrice`,
    TLD_RENEW_LIST: `${path}/v1/domain/tld/renew/list`,
    TLD_ODDLINE: `${path}/v1/domain/tld/offline`,
    TLD_APPLY: `${path}/v1/domain/tld/apply`,
    PRICE_MNT: `${path}/v1/public/price/mnt`,
    PRICE_FUSE: `${path}/v1/public/price/fuse`,
    PRICE_DEGEN: `${path}/v1/public/price/degen`,
    PRICE_BRETT: `${path}/v1/public/price/brett`,
    PRICE_XLAYER: `${path}/v1/public/price/xlayer`,
    PRICE_BERA: `${path}/v1/public/price/bera`,
}

export default {
    async getTldList(params, conf) {
        console.log(store.state.account)
        if (!params.walletAddress) {
            params.walletAddress = store.state.account
        }
        return await API.post(TLD_API_URL.TLD_LIST, params, conf, false)
    },

    async getPriceTemplates(params, conf) {
        return await API.get(TLD_API_URL.PRICE_TEMPLATES, '', conf, false)
    },

    async setTldEdit(params, conf) {
        return await API.post(TLD_API_URL.TLD_EDIT, params, conf, true)
    },

    async getTldRenewList(params, conf) {
        params.walletAddress = store.state.account
        return await API.post(TLD_API_URL.TLD_RENEW_LIST, params, conf, true)
    },

    async setTldOffline(params, conf) {
        return await API.post(TLD_API_URL.TLD_ODDLINE, params, conf, true)
    },

    async tldApply(params, conf) {
        return await API.post(TLD_API_URL.TLD_APPLY, params, conf, true)
    },

    async priceMnt(params, conf) {
        return await API.get(TLD_API_URL.PRICE_MNT, '', conf, false)
    },

    async priceFuse(params, conf) {
        return await API.get(TLD_API_URL.PRICE_FUSE, '', conf, false)
    },

    async priceDegen(params, conf) {
        return await API.get(TLD_API_URL.PRICE_DEGEN, '', conf, false)
    },

    async priceBrett(params, conf) {
        return await API.get(TLD_API_URL.PRICE_BRETT, '', conf, false)
    },

    async priceXlayer(params, conf) {
        return await API.get(TLD_API_URL.PRICE_XLAYER, '', conf, false)
    },

    async priceBera(params, conf) {
        return await API.get(TLD_API_URL.PRICE_BERA, '', conf, false)
    },

}