import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-08c5dbde"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "copyBtn"
};
var _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$data.remove ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_tooltip, {
    "class": "box-item",
    effect: "dark",
    content: $data.tipsText,
    placement: "top"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $options.handleCopy($props.address);
        }),
        onMouseover: _cache[1] || (_cache[1] = function () {
          return $options.mouseLive && $options.mouseLive.apply($options, arguments);
        })
      }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 32)];
    }),
    _: 3
  }, 8, ["content"])])) : _createCommentVNode("", true)]);
}