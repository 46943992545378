import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3ab0c154"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "price-box"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  key: 2,
  style: {
    "color": "#666",
    "padding-left": "0.12rem"
  }
};
var _hoisted_5 = {
  key: 0
};
var _hoisted_6 = {
  key: 1
};
var _hoisted_7 = {
  key: 2,
  style: {
    "color": "#666",
    "padding-left": "0.12rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_skeleton_item = _resolveComponent("el-skeleton-item");
  var _component_el_skeleton = _resolveComponent("el-skeleton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_skeleton, {
    "class": "ske-box",
    loading: $options.isLoading($props.usdPrice),
    animated: "",
    style: _normalizeStyle($props.height ? 'height:' + $props.height : '')
  }, {
    template: _withCtx(function () {
      return [_createVNode(_component_el_skeleton_item, {
        variant: "text",
        "class": "text",
        style: _normalizeStyle($props.height ? 'height:' + $props.height : '')
      }, {
        "default": _withCtx(function () {
          return [$options.getDisabled() ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(($props.isDollar ? '≈$' : '') + $props.usdPrice), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(($props.isDollar ? '$' : '') + $props.usdPrice), 1)), $props.isSymbol ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($options.symbol), 1)) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["style"])];
    }),
    "default": _withCtx(function () {
      return [$options.getDisabled() ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(($props.isDollar ? '≈$' : '') + $props.usdPrice), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(($props.isDollar ? '$' : '') + $props.usdPrice), 1)), $props.isSymbol ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($options.symbol), 1)) : _createCommentVNode("", true)];
    }),
    _: 1
  }, 8, ["loading", "style"])]);
}