import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-25040610"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "renewPopup"
};
var _hoisted_2 = {
  "class": "header"
};
var _hoisted_3 = {
  "class": "title"
};
var _hoisted_4 = {
  "class": "tips"
};
var _hoisted_5 = {
  "class": "el-dialog__footer"
};
var _hoisted_6 = {
  "class": "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");
  var _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: $data.popBoxShow,
    "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
      return $data.popBoxShow = $event;
    }),
    position: $options.isMobile ? 'bottom' : 'center',
    "class": "dialog-pop-box",
    onClickCloseIcon: $options.handleClosesBox,
    onClickOverlay: $options.handleClosesBox,
    "show-close": false,
    round: true
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('publish.prompt')), 1), _createElementVNode("i", {
        "class": "close-box",
        onClick: _cache[0] || (_cache[0] = function () {
          return $options.handleClosesBox && $options.handleClosesBox.apply($options, arguments);
        })
      })]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('publish.unsupported_chain')), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, [_createVNode(_component_el_button, {
        type: "primary",
        color: "#000",
        onClick: $options.handleSubmit
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('publish.switch_chain')), 1)];
        }),
        _: 1
      }, 8, ["onClick"])])])];
    }),
    _: 1
  }, 8, ["show", "position", "onClickCloseIcon", "onClickOverlay"])]);
}