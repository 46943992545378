export default {
    message: {
        input_placeholder: "入力してください",
        email_placeholder: "メールアドレスを入力してください",
        title_placeholder: "Enter title",
        content_placeholder: "Enter content",
        email_err: "正しいメールアドレスを入力してください",
        select_err: '選択してください',
        input_err: '入力してください',
        address_placeholder: "住所を入力してください",
        address_error: '正しい住所を入力してください',
        balance_error: 'お客様の残高は手数料を支払うには不足しています',
        owner_error: 'このドメイン名は所有していません',
        unsupport_chain: 'サポートされていないチェーンです。互換性のあるチェーンに切り替えますか？',
        no_account: 'ウォレットにアカウントがありません',
        sys_error: 'システムエラー',
        recaptcha_error: 'Google reCAPTCHAエラー、後でもう一度試してください',
        reject_error: 'User rejected',
        net_error: 'ネットワークエラー',
        switch_err: '現在のウォレットはネットワークの切り替えに対応していません。ウォレットアプリをアップグレードしてください。',
        add_chain_err: '切り替えに失敗しました。手動でネットワークを追加してください。',
        err_1001: 'You do not own this TLD',
        err_4000: 'パラメータが正しくありません',
        err_4100: 'ドメイン名は登録できません',
        err_4101: '無効な内容です。再入力してください。',
        err_4102: '入力したドメイン名が長さ制限を超えています。再入力してください',
        err_4103: '未登録のドメイン名',
        err_4104: 'ドメイン名が存在しません',
        err_4105: 'このドメイン名は既に登録されています',
        err_4106: 'ドメイン名は既に別のチェーンで登録されています',
        err_4107: 'ネットワークエラーです。リフレッシュしてもう一度試してください。',
        err_4108: 'ドメイン名はまだリリースされていません。再入力してください',
        err_4109: '入力したドメイン名は現在のところ登録をサポートしていません。再入力してください',
        err_4110: '入力したドメイン名は現在のところ登録をサポートしていません。再入力してください',
        err_4111: 'あなたのアドレスはコミュニティの規則に違反しており、ドメイン名を登録する権限がありません',
        err_4112: 'ドメイン名はオークション期間中です',
        err_4113: 'ドメイン名はオークション期間中ではありません',
        err_4114: 'サポートされていないTLDです',
        err_4115: '入力したドメイン名は現在のところ登録をサポートしていません。再入力してください',
        err_4116: '最初の登録は1年間のサイクルに基づいて行う必要があります',
        err_4117: '一括で登録できるドメイン名の最大数を超えました',
        err_4118: '招待コードとギフト券の同時使用は許可されていません',
        err_4119: 'ギフト券が存在しません',
        err_4120: '無効なギフト券です',
        err_4121: 'ギフト券は既に使用されています',
        err_4122: 'ドメイン名は既に購入されており、更新は必要ありません',
        err_4123: 'ドメイン名が移行中であり、操作が拒否されました',
        err_4124: '現在選択されているギフト券はロックされており、5分後に使用できます',
        err_4125: 'ドメイン名のサフィックスはまだ開放されていません',
        err_4126: 'ドメインは現在他の人によって登録されています。 5分間ロックされます。後でもう一度試してください。',
        err_4127: 'このドメイン名は現在のチェーンでの登録にはサポートされていません。',
        err_4128: 'このドメイン名は、選択したターゲットチェーンへの転送に現在サポートされていません。',
        err_4201: 'このシステムにはアドレスが登録されていません',
        err_4202: 'メールの確認コードの検証に失敗しました',
        err_4203: 'メールの確認コードの有効期限が切れています',
        err_4204: '不正な操作です',
        err_4205: '招待コードが存在しません',
        err_4206: 'リベートの設定がされていません',
        err_4207: 'システムの設定エラー：メール関連の設定が見つかりません',
        err_4301: 'イベントは存在しません',
        err_5000: '不明なエラー',
    },

    header: {
        project_name: 'OpenName',
        name_fi: 'NameFi',
        register: 'Register',
        query: 'クエリ',
        my_domain: 'マイネーム',
        pricing: '価格設定',
        invite: '招待',
        activities: '活動',
        collect: 'お気に入り',
        other: 'その他',
        connect: "接続",
        not_logged_in: 'まずウォレットをリンクしてください',
        show_all: 'すべての結果を表示 >',
        search_placeholder: 'ドメイン名またはアドレスを検索',
    },

    connectWallet: {
        connect_title: 'ウォレットを接続する',
        connect_desc: '複数のパブリックブロックチェーン上でドメイン名を登録して使用することができます。',
        select_network: 'ネットワークを選択',
        select_wallet: '接続されたウォレット',
        connect_to_ethereum: 'Ethereumに接続',
        connect_to_arbitrum: 'Arbitrumに接続',
        connect_to_optimism: 'OP Mainnetに接続',
        connect_to_polygon: 'Polygonに接続',
        connect_to_bnb: 'BNB Chainに接続',
        connect_to_zksync: 'zkSync Eraに接続',
        connect_to_base: 'Base Mainnetに接続',
        connect_to_goerli: 'Goerliに接続',
        connect_to_arbitrum_goerli: 'Arbitrum Goerliに接続',
        connect_to_optimism_goerli: 'Optimism Goerliに接続',
        connect_to_mumbai: 'Mumbaiに接続',
        connect_to_bnb_test: 'BNB Testに接続',
        connect_to_zksync_test: 'zkSync Era Testに接続',
        connect_to_base_test: 'Base Testnetに接続',
        connect_wallet: '接続されたウォレット',
    },

    footer: {
        project_name: 'プロジェクト名',
        privacy_policy: 'プライバシーポリシー',
        user_agreement: '利用規約',
        copy_right: 'OpenName'
    },

    publish: {
        cancleLembrete: 'リマインダーをキャンセルする',
        cancle: 'キャンセル',
        confirm: '確認',
        close: '閉じる',
        submit: '送信',
        delete: '削除',
        set: '設定',
        expiration_time: '有効期限',
        remove_favorite: 'お気に入りから削除',
        coll_success: 'お気に入りに追加しました',
        copy_success: 'コピーしました！',
        copy_fail: 'コピーに失敗しました',
        copy_address: 'コピー',
        download_success: 'ダウンロードに成功しました',
        network_error: 'このネットワークはサポートされていません',
        prompt: 'プロンプト',
        successful: '成功しました！',
        failed: '失敗しました！',
        save: '保存',
        copy_link: 'コピーする',
        unsupported_chain: 'サポートされていないチェーンです。互換性のあるチェーンに切り替えますか？',
        switch_chain: '切り替える',
        wallect_insufficient: '残高が不十分です',
        creation: '作成',
        year: '/年',
        buyout: '/終生',
        show_more: ' 開く',
        show_less: ' 閉じる',
    },

    query: {
        query_title: '自分のもの',
        query_title1: 'お気に入り',
        query_title2: ' Web3 ',
        query_title3: 'ドメイン名',
        query_title4: 'または',
        query_title5: 'TLDs',
        query_placeholder: '名前または住所、例: i.love.you',
        select_cancle: 'サフィックスの選択を解除',
        query: 'クエリ',
        opened_suffix: 'オープンサフィックス',
        more_opening_suffix: 'さらに多くのサフィックスが間もなく公開されます',
        query_price: 'サフィックス価格リストをクエリ >',
        current_progress: '現在のオープニングの進行状況',
        sub_progress1: '1. 現在、純粋なアラビア数字、英語文字、特殊記号「-」、絵文字のみがサポートされています。',
        sub_progress2: '2. 特殊記号「-」は最初は使用できず、個別に登録して継続的に使用することはできません;',
        sub_progress3: '3. ドメイン名を登録するときは、.abc などの TLD を使用します。 使ってください"。" レイヤーを区別するため。',
        see_more: '文字セット ルールをもっと見る &gt >',
        query_error: '入力されたドメイン名は無効です。 入力したドメイン名に TLD (例:.eth) が含まれていないこと、またはサポートされていない文字が含まれていないことを確認してください。',
        registrable: '登録可能',
        has_registered: '登録済み',
        available: '利用可能',
        unavailable: '利用不可',
        grace_period: '猶予期間',
        btn_register: 'Register',
        btn_view: 'View',
        search_result: '検索結果',
        search_result1: '結果',
        registrable_only: '登録可能なドメイン名のみ表示',
        hot_suffix: 'Hot suffix',
        new_suffix: 'New suffix',
        free_suffix: 'Free suffix',
        discount_suffix: ' Off',
        report: '通報する',
        favorite: 'お気に入り',
        reporting_type: '作成したいレポートの種類を選択してください',
        report_reason1: '人種差別',
        report_reason2: 'ポルノおよび暴力的なコンテンツを含む',
        report_reason3: '未成年者の関与',
        report_reason4: 'その他',
        report_supplementary: '補足説明',
        report_email: 'メールアドレス（任意）',
        report_success: '成功報告されました',
        submit: '同意する',
        service_terms: '利用規約',
        terms_desc: 'ご利用開始前に、プラットフォームの',
        terms_desc1: '利用規約',
        terms_desc2: 'をよくお読みください。',
        agree_terms: '読んだ上で使用に同意します',
        fun_name: '面白い名前',
        Recently_registered: '最近登録されました',
        premium: 'プレミアム',
        year: '/年',
        buyout: '/終生',
        white_list_tips1: 'から',
        white_list_tips2: 'まで、有料のサフィックス',
        white_list_tips3: 'つ登録できます。',
        white_list_tips4: '',
        white_list_tips5: '',
        white_list_tips6: '',
        white_list_tips7: 'を持つすべてのドメイン名について、初年度の登録料が無料で',
        white_list_part_tips2: 'まで、以下の有料のサフィックスを持つドメイン名について、初年度の登録料が無料で',
        white_list_part_tips3: 'つ登録できます：',
        white_list_assign_tips2: 'まで、以下のドメイン名は、あなたのために独占的に登録用に予約されています：',
        white_list_title: 'ホワイトリストの資格',
        white_list_search: 'クリックして検索',
        except_tip: '除了',
        is_un_support1: 'このサフィックスは',
        is_un_support2: 'での登録のみサポートされています。',
        domain: 'ドメイン名',
        domain_tld: 'TLDs',
        domain_tld_tips: `TLD（トップレベルドメイン）はドメイン名の最上位レベルであり、ドメイン名の最後のドットの後に続きます。たとえば、ドメイン名'thank.you'では、'.you'がTLDです。`,
        domain_tld_tips1: 'TLDを登録し、プロモーションし、登録料金を稼ぎましょう。',
        domain_lab_tips: '年: 登録料は毎年支払われます。',
        domain_lab_tips1: '生涯: 更新手数料なしの 1 回限りの支払い。生涯保持します。',
        domain_tld_pl: 'TLD またはアドレスを検索します (例: .abc)',
        domain_white_tld: '次の TLD はあなたのために予約されており、あなたの登録に限定されています',
        view_all: 'すべて表示',
        tld_view_all: "必要な TLD が見つかりませんでしたか?",
        tld_view_all1: "🔍 検索してみてください。",
        domain_registration: '🥳 最新の登録:',
        domain_g: '1分',
        domain_xsq: '時間前',
        domain_fzq: '数分前',
        domain_tq: '数日前',

    },

    priceList: {
        search_placeholder: 'TLDの検索',
        price_table: '価格設定',
        suffix: 'サフィックス',
        sort: 'クラス',
        level_1_price: '一次ドメイン',
        level_1_price_mobile: '一次ドメイン',
        total_registrations: '総登録数',
        registrations: '総登録数',
        multistage_price: '一次ドメイン',
        multistage_price_mobile: 'マルチレベルドメイン',
        instructions: '指示',
        free: '無料',
        pre_year: 'にねん: $',
        buyout: '/終生',
        characters_price: '文字: $',
        tips_4_above: '4文字以上: $',
        characters: '文字数',
        year: '/year',
        header_tld: 'TLD',
        header_price: '販売価格',
        header_divide: 'シェア比率',
        header_explain: '説明する',
        defult: 'Custom TLDs',
        share_ratio_tips: 'OpenName ユーザーは独自の TLD を登録して、ドメイン名登録料の一部を獲得できます'
    },

    myDomain: {
        set_primary_name: 'プライマリ名の設定',
        no_domain_set: 'まだドメイン名を所有していません',
        wallet_address: 'ウォレットアドレス',
        bind_domain: 'ドメインのバインド（逆引きレコード）',
        bind_desc: 'リンク全体でドメイン名を設定できるようにしています。これにより、アプリ内のアドレスと同じ場所にアカウント名が表示されます。たとえば、ウォレットアドレスでDAppにログインすると、アカウント名がユーザー名として表示されます。ただし、このアドレスに転送されたドメイン名のみを選択できます。',
        select_domain_placeholder: '変更されたアドレスに解決されたドメイン名を選択してください',
        none_domain: 'まだドメイン名を所有していないか、ドメイン名のアドレスレコードがそのアドレスに設定されていません。このアドレスにすでに転送されたドメイン名のみを選択できます。',
        delete_tip: '現在のドメイン名は解決されています。このレコードを削除してもよろしいですか？',
        my_domain: 'マイネーム',
        batch_renewal: '延長',
        expiration_reminder: 'リマインダー',
        grace_period_until: '猶予期間：',
        renewal: '延長',
        cross_chain_transfer: 'ブリッジ',
        bridging: 'ブリッジング >',
        tips: '現在のドメイン名は無料のドメイン名になり、この期間中は更新のためにガス料金のみ支払う必要があります。永久に無料でドメイン名を所有できます。すぐに更新しますか？',
        transfer_desc: '所有権または管理権を他の人に移転していますので、注意して手続きを行ってください。',
        renewal_cycle: '延長期間',
        renewal_time: '延長時間',
        transfer_placeholder: '利用可能なギフト券を選択してください',
        success: '更新に成功しました',
        setting_success: '設定に成功しました',
        setting_error: '解析に失敗しました',
        remove_success: '削除に成功しました',
        remove_fail: '削除に失敗しました',
        bought_out_held: '終生',
        bought_out_held1: '終生',
        bought_out_held2: '',
        grace_period_to: '猶予期間まで',
        about_to_expire: 'まもなく期限切れ',
        free_suffix: '無料、終身保有',
        free_suffix1: '無料',
        free_suffix2: '終身保有',
        bought_out: '買い切り',
        week_4: '4週間',
        week_3: '3週間',
        week_2: '2週間',
        owner: '所有者',
        manager: '管理者',
        all: 'すべて',
        permission: '権限',
        search_placeholder: 'ドメインを検索',
        select_all: 'すべて選択',
        domainAll: 'すべてのドメインを表示',
        err_title: 'プロンプト',
        err_tip1: 'お知らせしますが、ドメイン名 ',
        err_tip2: '',
        err_tip3: "利用規約",
        err_tip4: ' ',
        err_tip6: ' は、',
        err_tip5: 'に関する規制事項に違反したため、システムによって削除され、誰にも再登録することはできません。お詫びとして、システムからギフト券が発行されました。ご質問がある場合は、弊社のカスタマーサービスにお問い合わせください。',
        err_bottom: 'ギフト券を表示 > ',
        registering: '登録中',
        his_name: '彼らのネーム',
        no_transfer: '猶予期間中はクロスチェーンの転送はサポートされていません。',
        forbid_transfer: 'クロスチェーン転送は禁止されています。',
        domain_loca_chain: "ドメインの位置するチェーン",
        tld_loca_chain: "TLD が配置されているチェーン：",
        domain_screen: "フィルター",
        domain_chain: "チェーン",
        domain_jurisdiction: "タイプ",
        domain_reset: "リセット"
    },

    setPrimaryName: {
        set_primary_name: 'プライマリ名の設定',
        set_desc: '統一されたアイデンティティまたはユーザー名として機能するプライマリ名を設定します。設定後はいつでも変更できます。',
        current: "現在の",
        confirm_Settings: '設定を確認',
        release_setting: 'プライマリ名の設定を解除',
        release_tip: 'プライマリ名の設定を解除しようとしています。よろしいですか？',
        set_tip1: '1. ドメイン名をアドレスにポイントしてください。',
        set_tip2: '2. プライマリ名の設定を完了してください。',
    },

    manager: {
        edit: '編集',
        set_primary: 'プライマリ名に設定',
        set_primary_desc: '統一されたアイデンティティまたはユーザー名として機能するプライマリ名を設定します。設定後はいつでも変更できます。',
        empty_desc: 'まだ記入されていません',
        forever: '永久',
        name_info: '権限',
        parent_name: '親ドメイン',
        owner: '所有者',
        manager: '管理者',
        transfer: '移転',
        modify: '変更',
        expiration_time: '有効期限',
        address: 'アドレス',
        social_media: 'ソーシャルメディア',
        website: 'ウェブサイト',
        other: 'その他',
        resolver: 'リゾルバ',
        modify_manager: 'マネージャーの変更',
        modify_desc: '他の人に管理権を割り当てています（所有権はまだあります）。注意して操作してください。',
        new_manager: '新しいマネージャー：',
        transfer_registrant: '移転',
        transfer_desc: 'ドメイン名の所有権を変更しています。変更後、アカウントの制御権を永久に失います。この操作を行う際は注意してください。',
        transfer_tld_desc: 'TLDの所有者情報を変更しています。変更後はそのTLDの永続的な制御権を失い、登録収入が新しい所有者に移管されますので、操作には注意してください。',
        domain_name: 'ドメイン',
        current_address: '現在の住所',
        receiver: '受信者：',
        edit_add: '+ 追加',
        edit_address_desc: '各チェーンの受信アドレスをドメインデータに追加することができます。友達が送金する際には、受信者の入力ボックスにドメインアカウントを入力するだけで済みます。',
        edit_parser_desc: '* パーサーは、アドレス、テキストレコードなどのすべてのレコードを保存します。変更を行う前に、パーサーの動作をよく理解しておくことをお勧めします。',
        edit_success: '編集に成功しました',
        edit_fail: '編集に失敗しました',
        default_resolver: 'デフォルトのリゾルバ',
        input_parser_placeholder: '割引コードを入力してください',
        transfer_success: '移転に成功しました',
        transfer_fail: '移転に失敗しました',
        modify_success: '変更に成功しました',
        modify_fail: '変更に失敗しました',
        transfer_self_err: '自分自身には移転しないでください',
        modify_self_err: '自分自身には変更しないでください',
        date: '日付',
        help_renewal: '延長のヘルプ',
    },

    transfer: {
        transfer_domain_name: 'ブリッジドメイン',
        transfer_domain_tld: 'ブリッジ TLD',
        domain: 'ドメイン名',
        current_chain: '現在のチェーン',
        from: '元のチェーン ',
        bridge_to: 'ブリッジ先',
        transfer_tip1: 'クロスチェーンの転送にはガス料金が必要です（チェーン上のリアルタイムガス価格に基づく）。',
        transfer_tip2: '一度開始されるとキャンセルすることはできません。転送プロセス中にページを離れ、"マイネーム"ページで転送状況を確認できます。',
        transfer_tip2_tld: '一度開始されるとキャンセルすることはできません。転送プロセス中にページを離れ、"私のTLD"ページで転送状況を確認できます。',
        chain_type: 'チェーンタイプ',
        chain_placeholder: 'チェーンを選択してください',
        address: 'アドレス',
        address_placeholder: 'アドレスを入力してください',
        start_transfer: 'ブリッジを開始',
        bridging: 'ブリッジ中',
        finish_tip: '🎉 ドメイン名が正常に転送されました。チェーンとウォレットを切り替えてドメイン名を表示してください。',
        step_title: '3つのステップ',
        step_1: 'ステップ1',
        step_1_desc: '現在のチェーン上でドメイン名を破棄します。',
        step_1_desc_tld: '現在のチェーンでTLDを破棄してください。',
        step_1_finish: 'ブロックチェーンブラウザで詳細を表示します。 >',
        step_2: 'ステップ2',
        step_2_desc: 'LayerZeroを介してクロスチェーン情報を送信します。',
        step_2_tip: '転送プロセスには数分かかる場合があります。',
        step_3: 'ステップ3',
        step_3_desc: '選択した新しいチェーン上でドメイン名を作成します。',
        step_3_desc_tld: '新しいチェーンでTLDを発行してください。',
        step_3_tip: 'キャストプロセスが長時間完了しない場合は、',
        step_3_tip1: 'カスタマーサービスにお問い合わせください。',
        step_finish: '詳細はLayerZeroをチェックしてください >',
        step_wait: '保留中',
        step_completed: '完了',
        transfer_fail: '転送に失敗しました',
        bridge_success: '成功',
        bridge_success_desc: 'ドメイン名が正常にブリッジされました。チェーンとアカウントを切り替えてドメインを表示してください。',
        bridge_success_tld: 'あなたのTLDは正常に転送されました。表示するには、チェーンとアカウントを切り替えてください。',
        powered_by: 'LayerZero を搭載'
    },

    signin: {
        enquiry: '問い合わせ',
        registration: '登録',
        register: 'Register',
        year: '年',
        m_year: '年',
        m_years: '年',
        tips: '招待コードを入力してください',
        method: '登録期間',
        number_year: '年数',
        expense: '登録料金',
        invitation_code: '招待',
        coupon: 'ギフト券',
        coupon_placeholder: '利用可能なギフト券を選択してください',
        total_cost: '見積もり合計',
        cost: '見積もり金額',
        original_price: '元の価格',
        buy_out: '終生',
        free: '無料',
        premium: 'プレミアム',
        premium_main: 'プレミアムアラート',
        premium_main_price: 'リマインダー価格入力',
        premium_tips: 'プレミアムはこの価格で到着します（現地のタイムゾーン）',
        nowPremium: '現在のプレミアム',
        introduce_title: 'プレミアムカーブ',
        introduce_content_tip: '期限切れの名前に対して公正な登録機会を提供するため、プレミアムは1億ドルから21日間で0ドルまで徐々に減少します。',
        introduce_item_left: '現在の購入価格は ',
        introduce_item_right: ' 日後（合計21日）',
        remind: 'リマインド ',
        free_tips: 'このドメイン名は永久に無料で登録できます。登録時にガス料金のみ支払う必要があります。',
        number: '数量',
        not_sufficient_funds: '利用可能な残高が登録には不十分です',
        is_register: 'ドメイン名が登録中です',
        hold_for_life: '永久保持',
        target_date: 'この価格まで',
        target_date_me: 'この価格のお知らせへ',
        code_error: '無効',
        already_set: '既に設定済み',
        register1: '1. ウォレット署名',
        register2: '2. ドメイン名をロックする',
        register3: '3. 登録するには支払いをしてください',
        register4: '2. TLDをロックする',
        confirm3: '支払いを行って延長する',
        first_year_free: 'First year free',
        select_coupon: 'バウチャーを選択',
        tips_rate: 'TLD の登録が成功し、運用が開始されると、ドメイン名登録収益の一定割合がプラットフォームに分配されます。',
        select_ayment: '支払方法',
    },

    email: {
        emailError: 'メール形式エラー',
        email_tips: 'メールをバインドする',
        email_code_ver: 'バインドされたメールのコードを入力してください',
        get_code: 'コードを送信する',
        band_tips: 'アラートを受け取るためにメールをバインドしてください',
        email_address: 'メールアドレスを入力してください',
        email_new_address: '有効な新しいメールアドレスを入力してください',
        code_add: '検証コードを入力してください',
        ver_code_err: '検証コードエラー',
        pop_tips: 'ドメイン名の有効期限の30日前に、週に1回リマインダーメールをメールアドレスに送信します',
        re_code: '再送信',
        emailEdit: 'バインドされたメールを変更する',
        currentMailbox: '現在のバインドメールアドレス：',
        editTips: 'バインドされたメールのコードを入力してください',
        next: '次へ',
        last_step: '戻る',
        binding: 'バインド',
        un_binding: 'バインド解除',
        reminder_email: 'リマインダーメール',
        success: 'バインド成功',
        send_success: '送信成功',
        email_error: 'メールは繰り返し使用できません',
        email_system_err: 'メールの送信に失敗しました。しばらくしてからもう一度お試しください。'
    },

    ShoppingCart: {
        title: '一括登録',
        close: '空にする',
        year: '年',
        m_year: '年',
        m_years: '年',
        method: '期間',
        buy_out: '終生',
        number_year: '年数',
        invitation_code: '招待コード',
        code_tips: '招待コードを入力してください',
        coupon: 'ギフト券',
        coupon_placeholder: '複数のギフト券を選択できます',
        number: '数量',
        total_cost: '見積もり合計',
        original_price: '元の価格',
        register: 'Register',
        tips: '招待コードを入力して、登録料を割引します',
        empty: 'コンテンツがありません',
        empty1: 'まだTLDを所有していませんん',
        empty2: 'まだTLDを所有していません',
        searh_empty: '検索結果がありません',
        empty1_desc1: '行って、 ',
        empty1_register: '\u00A0登録する\u00A0',
        empty1_desc2: ' 今一つ',
        hold_for_life: '永久保有',
        estimated_gas: 'ガス',
        estimated_gas_fee: '推定ガス量',
        discount: '',
        discount1: 'オフ',
        registered: '登録済み',
        unavailable: '利用不可',
        unsupported_chain: '非対応チェーン',
        card_limit: '最大15個のドメインを追加できます',
        un_support_payments1: ' このドメイン名はサポートされていません',
        un_support_payments2: 'お支払いのみ'
    },

    favorite: {
        favorites: 'お気に入り',
        empty_desc: 'お気に入りのドメイン名はまだありません。お気に入りを検索して見つけてください',
        query: '名前を検索',
        empty_desc1: 'まだ気に入ったTLDがないので、気に入ったTLDをチェックしてみてください~',
        query1: 'TLD のクエリ',
        registration: '登録',
        registration_premium: 'プレミアムを支払って登録できます',
        unregistrable: '登録不可',
        grace_period: '期限までの猶予期間',
        view: '表示',
    },

    coupon: {
        coupon_list: 'ギフト券',
        coupon: 'ギフト券',
        release_time: '配布',
        expired: '期限切れ',
        employ: '使用済み',
        unused: '利用可能',
        empty: 'ギフト券はありません',
        expiration: "有効期限",
        permanently_valid: "永久に有効",
        available_suffixes: "利用可能なサフィックス",
        universal_voucher: "ユニバーサルバウチャー",
        tips_role: '使用方法：ドメイン名を登録する際に、ギフト券を使用して登録料金を割引することができます',
    },

    inviteFriends: {
        title: '友達を招待して報酬を獲得する',
        tips1: '次のリンクからドメイン名または TLD を登録するように友達を招待します。登録が成功すると、',
        tips2: '割引',
        tips3: 'を受けることができ、あなたは',
        tips4: '登録料の最大',
        tips5: 'を紹介ボーナスとして受け取ることができます。',
        tips6: '彼らは',
        invite_friends: '今すぐ招待',
        invitation_record: '招待記録',
        time: '登録時間',
        address: '招待者のアドレス',
        chain: '登録チェーン',
        reward: '報酬',
        no_data: 'まだ記録がありません',
        pup_tips: 'クロスチェーンドメイン名を作成する',
        bonus_tips: '友達を招待してボーナスを獲得する：',
        apply_for: 'より高い報酬を申請する >',
        look_rule: '具体的なルールを見る >',
        apply_success: '審査に提出されました',
        data_overview: '招待の概要',
        data_ranking: 'ランキング一覧',
        data_rank: 'ランキング',
        data_address: 'アドレス',
        data_invite_num: '招待登録数',
        data_address_num: '招待講演',
        data_award_num: '受賞しました',
        date_7: '7日間',
        date_30: '30日間',
        date_all: 'すべて',
        rebate_tips: 'リベート率と招待',
        role: 'あなたの役割',
        rebate_ratio: 'リベート率',
        number_invited: '招待された登録',
        individual_user1: '個人ユーザー',
        individual_user2: 'KOL',
        individual_user3: 'ディストリビューター',
        individual_user4: 'パートナー',
        token_reward: 'あなたの報酬',
        token_tips: '報酬は自動的に対応するブロックチェーン上のアドレスに送金されました。',
        rebate_ratio_tips: 'KOL/ディストリビューター/パートナーとしての申請には25％のリベートがあります',
        invitation_link: 'あなたの招待リンク',
        invitation_code: 'あなたの招待コード',
        registration_fee: '登録料',
        reward_earn: '獲得した報酬',
        invite_account_unLogin: '招待状を受け取るためにウォレットに接続しました',
        invite_friends_discount: "友達の登録割引",
        domain: 'ドメイン',
        domains: 'ドメイン',
        screen_shot: '招待ポスターを保存するためにスクリーンショットを撮る',
        tips_role: 'リストのデフォルトのランキング重みは、獲得した報酬に基づいています。他のランキングを表示するには、フィルターボタンをクリックして他の指標の結果を表示できます。',
        invited_friend: "招待された友達数",
        your_invitation: "あなたの招待状",
        invitee_address: "招待された人のアドレス",
        invited_number: "総登録ドメイン数",
        invited_money: "総登録料金",
        invited_award: "あなたの招待獲得",
        invited_detail: "詳細",
        invited_register_detail: "登録詳細",
        invited_register_domain: "ドメイン名",
        invited_register_chain: "登録チェーン",
        invited_register_fee: "登録料金",
        invited_register_award: "報酬",
        invited_register_time: "登録時間",
        no_rewards: 'ご褒美はありません。',
        invite_1: '申請レコードがあります',
        invite_2: 'あなたはすでに KOL ステータスです。',
        invite_3: '申し訳ありませんが、身分証明書の要件をまだ満たしていません',
        invite_4: 'アプリケーションを開始',
        invite_5: '申請者のステータス',
        invite_6: 'あなたの紹介',
        invite_7: '連絡先情報',
        invite_8: 'コミュニティ リンク',
        invite_9: '検討中',
        invite_10: '24 時間以内にご連絡いたしますので、しばらくお待ちください',
        invite_11: '監査結果',
        invite_12: '元に戻す',
        invite_13: '承認されました。アクティビティの KOL ステータスおめでとうございます',
        invite_14: '戻る',
        invite_15: '申請が承認されなかったことを非常に残念に思います',
        invite_16: 'あなたの情報は申請の要件を満たしていないため、合格できません。大変申し訳ありませんが、正しい情報を再入力してください。'
    },

    userInfo: {
        my_account: 'マイアカウント',
        my_domain: 'マイドメイン',
        my_tld: '私のTLD',
        my_collection: 'お気に入り',
        my_coupon: 'ギフト券',
        my_email: 'メール',
        switch_wallets: '切り替え',
        exit_wallet: '切断する',
        operation_record: '操作記録',
        revise: '修正する',
    },

    applyToJoin: {
        apply_to_join: '高い報酬を申請する',
        join_tip1: "👋 こんにちは、Rebate Schemeの申請ページへようこそ！",
        join_tip2: "私たちと同意し、ドメイン名製品のプロモーションに高い関心を持っている場合、プラットフォームの招待と呼び出しプログラムに参加していただければ幸いです：友達を招待してドメイン名を登録すると、最大25％の取引リベートをお楽しみいただけます〜",
        join_tip3: 'さらに、私たちの招待プログラムは事業の発展とともに拡大していきます。お楽しみに。 😊',
        apply: 'どの立場で申請しますか',
        text1: 'KOL（ソーシャルメディアのKOL、暗号通貨業界の意見リーダーなど）',
        text2: '流通業者(トラフィックを保有または獲得できる個人/組織)',
        text3: 'パートナー（NFTプロジェクト側、パブリックチェーンプロジェクト側、機関など）',
        your_introduction: '自己紹介',
        please_enter: '入力してください',
        social_media: 'ソーシャルメディア',
        link: 'コミュニティ/公式ウェブサイトのリンク',
        contact: '連絡先情報（メール/Telegram/Discord）',
        email: 'メールアドレス',
        wallet: 'ウォレットアドレス（変更可能）',
        wallet_tip: '連絡先情報（Telegram/Discord/メール/Twitter）',
        submit: '申請を送信する',
        role: '役割',
        reward_ratio: '報酬比率',
        kol: 'KOL',
        registrar: '登録業者',
        partner: 'パートナー',
        enter: '必須項目です。内容を入力してください。',
        tip1: 'OpenNameでお気に入りのドメイン名を登録しました。ぜひ試してみてください！',
        tip2: 'クロスチェーンドメイン名を作成する'
    },

    renew: {
        name_renewal_fee: '名前の延長',
        expiration_time: '有効期限',
        renewal_fee: '更新料金',
        cancel: 'キャンセル',
        renewal: '延長',
        renewal_cycle: '延長期間',
        hold_for_life: '永久保持',
        reconfirm: '延長の確認',
        selected: '選択済み',
        selected_tld: '選択済み',
    },

    tldRenew: {
        name_renewal_fee: 'TLDの更新',
    },

    timeName: {
        year: '年',
        month: '月',
        week: '週',
        day: '日',
        buy_out: '終生',
        m_year: '年',
        m_month: '月',
        m_week: '週',
        m_day: '日',
        m_years: '年',
        m_months: '月',
        m_weeks: '週',
        m_days: '日',
        free: '永久無料',
        years: '年',
        months: '月',
        weeks: '週',
        days: '日',
    },

    operationRecord: {
        title: '操作記録',
        search_tips: 'ドメイン名を検索',
        table_1: 'タイプ',
        table_2: '名前',
        table_3: '住所',
        table_4: '期間',
        table_5: '実際のコスト',
        table_6: 'ガス',
        table_7: '時間',
        type_1: '登録',
        type_2: '更新',
        type_3: 'プライマリ名を設定',
        type_4: 'クロスチェーン転送',
        type_5: 'マネージャーの変更',
        type_6: '所有者の転送',
        type_7: '受信ドメイン名',
        type_8: 'クロスチェーン転送受信',
        type_9: '所有者の譲渡',
        type_from: 'From',
        type_to: 'To',
    },

    activities: {
        activities: '活動',
        underWay: '進行中',
        upcoming: 'まもなく開始 ',
        ended: '終了',
        activity_time: 'イベント時間',
        rewards: '報酬',
        invite_now: '招待する',
        invite_desc: '😃 "OpenName"で無敵のオムニチェーンドメイン名を作成しました。今、プラットフォームで終身ドメイン登録は最低$5から可能です 🥳。絶対に見逃さないでください！ 👀 一緒に探機してみてください~\n',
        rule: '説明',
        activity_chain: 'チェーン',
        rank: 'ランキング',
        earn_rank: '収益',
        invite_address_rank: '招待アドレス数',
        invite_register_rank: '招待登録数',
        register_rank: '登録数',
        option_rank: 'ランキング',
        option_address: 'アドレス',
        option_bonus: '収益',
        option_invite_address: '招待アドレス数',
        option_invite_register: '招待登録数',
        option_domain: 'ドメイン名',
    },

    mailboxBinding: {
        tip1: '1、メールアドレスをバインド',
        tip2: '2、コミュニティメディアをフォロー',
        tip3: 'メイン名を設定',
        tip4: '登録を続ける',
        tip5: '公式アカウントをフォロー',
        tip6: 'メールアドレスをバインド'
    },

    nameFi: {
        name_fi_title: 'Register Names To\nEarn ',
        coins: 'Coins ',
        name_fi_title2: 'and',
        points: ' Points',
        first_namefi: 'The First NameFi of The World',
        coin_desc: 'Coins: Can be Swapped into USDT',
        point_desc: 'Points: Probably nothing',
        join_tele: 'Join Telegram',
        coming_soon: '✨ Coming soon!',
        intro: 'NameFi Introduction',
        intro_desc0: 'NameFi is a new domain name value paradigm proposed and built by OpenName, allowing domain names to continuously generate  value for users.',
        intro_desc1: 'By ',
        intro_desc1_bold: 'Staking Names',
        intro_desc2: ' or ',
        intro_desc2_bold: ' Completing Tasks',
        intro_desc3: ', you can earn Coins and Points. Coins can be swapped into USDT from the reward pool at any time,reward pool is 40% of the platform\'s net registration fee revenue. Points represents your contributions on OpenName.',
        intro_desc4: 'Register your domain name now and get ready to participate in staking!',
        register: 'Register Names',
        earn_desc1: 'Stake domains, Staking rewards include Coins and Points.',
        earn_desc2: 'During the staking period, you can still transfer or bridge your domain names, but your staking earnings will also undergo corresponding changes.',
        earn_desc3: 'Different domain suffixes, lengths, and registration periods have different rewarding speeds.',
        weight_table: 'Staking weight table',
        suffix: 'Suffix',
        character1: '1-character',
        character2: '2-character',
        character3: '3-character',
        character4: '4 characters or more',
        suffix_weight: 'Suffix weight',
        more: 'More',
        fold_up: 'Fold up',
        task_earn1: 'All users can earn Coins and Points by completing easy tasks.',
        task_earn2: 'The amount of Coins and Points that the user receives is determined by the quantity of completed tasks.',
        task_earn3: 'The task list and detailed rules will be announced at the beginning of the event, stay tuned.',
        rule_remark: 'The content is for reference only. Please refer to the official rules provided by OpenName. OpenName has the right to modify and interpret the rules.',
    },

    tldDetail: {
        tld_profile: '編集',
        copy_link: 'コピーする',
        tld_share: '共有',
        share_tld_desc: 'I registered a favorite domain name on OpenName, Come and give it a try! 🎉',
        share_tld_desc2: 'Have your favorite web3 domain',
        report: '通報する',
        owner: '所有者',
        manager: '管理者',
        expiration_time: '有効期限',
        renewal: '延長',
        more: 'もっと',
        name_holder: '所有者',
        name_registration: '登録数',
        registration: '登録',
        domain_pricing: 'ドメイン価格',
        character_length: '文字の長さ',
        first_level_domain: '一次ドメイン',
        multi_level_domain: 'マルチレベルドメイン',
        first_level_desc: 'Web3のドメインは、2つまたはそれ以上の文字グループから構成され、各グループの文字はドットで区切られます。2つの文字グループが含まれるドメインは一次ドメインです。例えば、love.you は一次ドメインであり。',
        multi_level_desc: 'Web3のドメインは、2つまたはそれ以上の文字グループから構成され、各グループの文字はドットで区切られます。2つの文字グループが含まれるドメインは一次ドメインです。例えば、i.love.you は多次ドメインです。',
        character1: '1文字',
        character2: '2文字',
        character3: '3文字',
        character4: '4文字以上',
        any_length: '任意の長さ',
        information: '基本情報',
        social_media: 'ソーシャルメディア',
        other: 'その他',
        register_record: '登録記録',
        name: 'ドメイン名 ',
        address: '住所',
        registration_cycle: '登録期間',
        registration_fee: '登録料金',
        registration_time: '登録時間',
    },

    tldEdit: {
        modify_pic: 'Modify profile picture',
        add_photo: 'Add photo',
        introduction: 'プロフィール',
        introduction_placeholder: '簡単な説明',
        draft: '下書き保存',
        release: '公開',
    },

    tldData: {
        address_num: '所有者',
        name_num: '登録数',
        your_income: 'あなたの収入',
        cumulative_income: '累計収入',
        platform_ratio: 'プラットフォーム手数料率',
        your_share: 'あなたの手数料率',
        domain_records: '登録記録',
        extend_record: 'ドメイン更新記録',
        registration: '登録',
        extension: '延長',
        extension_period: '更新周期',
        extension_fee: '更新費用',
        share_address: '収益分配アドレス',
        share_income: '収益分配収入',
        extend_time: '更新時間',
        address_tip: 'そのアドレスは、現在のドメイン名の更新用アドレスを指します。',
        agency: '共有',
        agency_title: '共有記録',
        register_address: '登録住所',
        agency_address: 'マーケティング担当者の住所',
        agency_fee: '登録料',
        agency_lcome: 'マーケター向けの利益分配',
        you_lcome: 'あなたの収入',
        agency_time: '時間'
    },

    tldEntrance: {
        tld_owners: '所有者',
        tld_on: 'の上',
        tld_domain: 'ドメイン',
        tld_holders: 'ホルダー',
        all_chain: 'チェーン全体'
    },

    amendTld: {
        setting_price: 'オープン登録',
        setting_desc: 'TLDの設定プロセスでお手伝いが必要な場合は、',
        setting_desc1: 'お問い合わせください。',
        price_modle: '価格モデル',
        one_domain: '第一レベルのドメイン名',
        two_domain: 'マルチレベルドメイン名',
        one_ch: 'キャラクター',
        two_ch: 'キャラクター',
        buy_out: '終生',
        event_ling: '任意の長さ',
        discount: '割引',
        allow_chail: '登録チェーンを許可する',
        allow_cross_chail: 'ターゲット チェーンへの交差を許可する',
        pl_1: '入力してください。100 は割引なしを意味します ',
        pl_2: '選んでください',
        all_chain: 'すべてのパブリックチェーン',
        tip_2: 'パブリック チェーンを選択すると、ユーザーは対応するパブリック チェーン上のチェーン間でのみドメイン名を転送できるようになります。選択しない場合は、プラットフォームでサポートされているすべてのパブリック チェーンがデフォルトで使用されます。',
        tip_1: '登録チェーンを選択すると、ユーザーは対応するパブリック チェーンにのみドメイン名を登録できるようになります。選択しない場合は、プラットフォームでサポートされているすべてのパブリック チェーンがデフォルトで使用されます。',
        tip_3: '価格テンプレートを選択したら、TLD をすぐにリストできます。さらに設定を行いたい場合は、「My TLD」に移動してさらに設定を行うことができます。',
        grounding: '棚の上',
        grounding_tld: 'TLD をリストする',
        custom: 'カスタマイズ',
        sold_out: '削除の確認',
        sold_out_tips: '上場廃止後は、TLD をユーザー登録できなくなります。慎重に進めてください。',
        sold_out_1: 'もう一度考えさせてください',
        sold_out_confirm: '確認する',
        year: '年間サブスクリプション',
        liftTime: '一括購入、一生持有',
        m_year: '/年',
        m_liftTime: '/終生',
        no_limit: '無制限',
        manage_secondary: 'Registrar/Marketer',
        manage_secondary_tips: 'Set up that revenue sharing scheme to registrar/marketer.',
        address: 'Address',
        rate: 'Rate',
        agency_address_error: '無効なアドレス',
        agency_address_error1: 'アドレスを空にすることはできません',
        agency_address_error2: '重複したアドレスが存在します',
        agency_rate_error: 'エージェントの利益分配率が間違っている',
    },

    myTld: {
        successful: '正常に送信されました',
        apply: '適用する',
        my_tld: '私のTLD',
        search_tld: 'TLD を検索',
        unOpen_tld: '開いていません',
        open_tld: 'オープン済み',
        tld_data: 'TLD データ',
        tld_open: '登録を開く',
        tld_share: '登録をオープン',
        tld_date: 'データ',
        tld_price: '価格',
        tld_register: '登録中',
        tld_cross: 'クロスチェーン',
        tld_owner: '所有者',
        tld_manager: '管理者',
        tld_tips: '登録が開始されていない TLD は更新されません。できるだけ早く登録を開始することをお勧めします。 ',
        tld_transfer: '転送ホルダー',
        tld_change: '管理者の変更',
        tld_crossing: 'クロスチェーン転送',
        tld_close: '登録を終了',
        batch_renewal: 'TLDの更新',
        tld_close_verify: '登録を終了することを確認',
        tld_close_confirm: '確認',
        tld_close_tips: '登録を終了すると、ユーザーはこの TLD のドメイン名を登録できなくなります。慎重に進んでください。 ',
        rate: 'シェア比率',
        rate_tips: '他のユーザーがこのTLDでドメインを登録すると、登録料金の',
        rate_tips_2: 'を受け取ることができます。',
        statusTextL: '州',
        no_description: 'まだ説明がありません',
        applyPopup: 'TLDの登録を申請する',
        pop_tips: 'お申込み後、メールにてご連絡させていただきます。ご期待ください。',
        apply_text: '応募理由',
        apply_text_error: '応募理由をご入力ください',
        l_tip1: '年間価格は以上である必要があります',
        l_tip2: '買取価格は以上である必要があります',
        l_tip3: '年間価格',
        l_tip4: '購入価格',
        l_tip5: '年間価格は以下である必要があります',
        l_tip6: '買収価格は以下である必要があります',
        price_empty: 'TLD 価格を空にすることはできません',
        not_allowed: 'クロスチェーンの禁止',
        group_tip: '最高の TLD を取得できたことを祝福します。TLD オーナー限定サービス グループに参加してください。',
        btn1: '追加登録',
        btn2: '登録リスト',
    }
}