export function AUTION() {
    const auction = {
        VUE_APP_PERIOD_OF_GRACE: 10,
        VUE_APP_AUTION_PERIOD: 20,
        VUE_APP_DURATION: 31536000,
        HOUR: 60,
        DAY: 60 * 60,
        MOMENT_DAYS: 'hour', //'days',
        MOMENT_HOUR: 'minutes', //'hour',
        MOMENT_MINUTES: 'second', //'minutes',
        MOMENT_SECOND: 'second'
    }
    return auction
}