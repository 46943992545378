import _toConsumableArray from "/root/.jenkins/workspace/prod-dapp/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/root/.jenkins/workspace/prod-dapp/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.filter.js";
import { switchChainIdList } from '@/utils/chainInfo';
import { mapState } from 'vuex';
// icon_token
export default {
  name: 'currencySelect',
  props: {
    currencySelect: {
      "default": ''
    },
    registerPayMethod: {
      "default": []
    },
    isCard: true
  },
  data: function data() {
    return {
      currencyList: [{
        icon: require('@/assets/image/renew/renew_mydomain.png'),
        value: '1',
        label: 'USDT'
      }, {
        icon: require('@/assets/image/renew/renew_mydomain.png'),
        value: '2',
        label: 'USDC'
      }],
      defaultIcon: require('@/assets/image/commom/icon_token.png'),
      currencyValue: this.currencySelect,
      getCfgTokenList: this.$store.state.getCfgTokenList,
      payMethodList: []
    };
  },
  computed: _objectSpread({
    isMobile: function isMobile() {
      return this.$store.state.isMobile;
    }
  }, mapState(['chainid'])),
  mounted: function mounted() {
    this.filterPayMethod();
  },
  methods: {
    inputChange: function inputChange(e) {
      this.currencyValue = String(this.currencyValue);
      this.$emit('selectChange', this.currencyValue, this.getCfgTokenList[this.currencyValue]);
    },
    tokenIcon: function tokenIcon(currencyValue) {
      var icon = '';
      for (var i in this.payMethodList) {
        if (currencyValue == this.payMethodList[i].id) {
          icon = this.payMethodList[i].tokenLogo || this.defaultIcon;
        }
      }
      return icon;
    },
    filterPayMethod: function filterPayMethod() {
      var _this = this;
      var arr = this.registerPayMethod;
      var json = {};
      json["".concat(this.chainid)] = [];
      arr.forEach(function (items) {
        items.forEach(function (item) {
          if (item.chainId == _this.chainid) {
            json[item.chainId] = json[item.chainId].concat(item.tokens);
            json[item.chainId] = _toConsumableArray(json[item.chainId]);
            json[item.chainId] = _toConsumableArray(new Set(json[item.chainId]));
          }
        });
      });
      for (var i in json["".concat(this.chainid)]) {
        if (this.getCfgTokenList[json["".concat(this.chainid)][i]]) {
          this.payMethodList.push(this.getCfgTokenList[json["".concat(this.chainid)][i]]);
        }
      }
      if (this.payMethodList.length > 0) {
        this.payMethodList = this.payMethodList.sort(function (a, b) {
          return Number(b.sort) - Number(a.sort);
        });
      }
      if (this.getDefaultToken()) {
        this.payMethodList.unshift(this.getDefaultToken());
      }
    },
    getDefaultToken: function getDefaultToken() {
      var _this2 = this;
      if (this.chainid) {
        var arr = switchChainIdList();
        arr = arr.filter(function (item) {
          return item.chainId == _this2.chainid;
        });
        this.currencyValue = "".concat(arr[0].chainId);
        return {
          chainId: arr[0].chainId,
          tokenLogo: arr[0].icon,
          tokenName: arr[0].symbol,
          tokenPrecision: 18,
          id: "".concat(arr[0].chainId)
        };
      } else {
        return '';
      }
    }
  }
};